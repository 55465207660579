import { CustomFieldStructure, FieldStructure } from '@mercell/form-react';
import { FormDefinitionItem } from '../../types/generated/formDefinitionItem';
import { ChangeValueComponent } from '../../components/ChangeValueComponent';

export const mapChangeField = (
    formDefinition: FormDefinitionItem,
    genericFieldStructure: FieldStructure,
    locale?: Locale
): CustomFieldStructure => ({
    ...genericFieldStructure,
    type: 'custom',
    render: ChangeValueComponent,
    label: formDefinition.label,
    labelProperties: formDefinition.labelStyle,
    locale,
});
