import { FormRuleItem } from '../types/generated/formDefinitionItem';
import { TFunction } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { DateFns } from './dateFnsFormats';

export const getRulesSetup = (
    yupSchema: any,
    rules: FormRuleItem[],
    t: TFunction<string, unknown>,
    locale?: Locale,
    isWithTime?: boolean,
    dateFormat?: DateFns
) => {
    let copyOfYupSchema = yupSchema;

    rules.forEach((rule: FormRuleItem) => {
        switch (rule.function) {
            case 'minLength':
                copyOfYupSchema = getYupSchema(
                    copyOfYupSchema,
                    'min',
                    rule.numberValue,
                    'form-content:ERROR_MINIMUM_LENGTH',
                    t,
                    rule.message
                );
                break;
            case 'maxLength':
                copyOfYupSchema = getYupSchema(
                    copyOfYupSchema,
                    'max',
                    rule.numberValue,
                    'form-content:ERROR_MAXIMUM_LENGTH',
                    t,
                    rule.message
                );
                break;
            case 'minRange':
                copyOfYupSchema = getYupSchema(
                    copyOfYupSchema,
                    'min',
                    rule.numberValue,
                    'form-content:ERROR_MINIMUM_VALUE',
                    t,
                    rule.message
                );
                break;
            case 'maxRange':
                copyOfYupSchema = getYupSchema(
                    copyOfYupSchema,
                    'max',
                    rule.numberValue,
                    'form-content:ERROR_MAXIMUM_VALUE',
                    t,
                    rule.message
                );
                break;
            case 'minDate':
                copyOfYupSchema = getYupSchema(
                    copyOfYupSchema,
                    'min',
                    rule.stringValue,
                    'form-content:ERROR_MINIMUM_DATE',
                    t,
                    rule.message,
                    getFormattedDate(rule, locale, isWithTime, dateFormat)
                );
                break;
            case 'maxDate':
                copyOfYupSchema = getYupSchema(
                    copyOfYupSchema,
                    'max',
                    rule.stringValue,
                    'form-content:ERROR_MAXIMUM_DATE',
                    t,
                    rule.message,
                    getFormattedDate(rule, locale, isWithTime, dateFormat)
                );
                break;
            case 'moreThan':
                copyOfYupSchema = getYupSchema(
                    copyOfYupSchema,
                    'moreThan',
                    rule.numberValue,
                    'form-content:ERROR_VALUE_IS_MORE_THAN',
                    t,
                    rule.message
                );
                break;
            case 'lessThan':
                copyOfYupSchema = getYupSchema(
                    copyOfYupSchema,
                    'lessThan',
                    rule.numberValue,
                    'form-content:ERROR_VALUE_IS_LESS_THAN',
                    t,
                    rule.message
                );
                break;
            case 'moreThanDate':
                copyOfYupSchema = getYupSchema(
                    copyOfYupSchema,
                    'moreThan',
                    rule.stringValue,
                    'form-content:ERROR_DATE_IS_MORE_THAN',
                    t,
                    rule.message,
                    getFormattedDate(rule, locale, isWithTime, dateFormat)
                );
                break;
            case 'lessThanDate':
                copyOfYupSchema = getYupSchema(
                    copyOfYupSchema,
                    'lessThan',
                    rule.stringValue,
                    'form-content:ERROR_DATE_IS_LESS_THAN',
                    t,
                    rule.message,
                    getFormattedDate(rule, locale, isWithTime, dateFormat)
                );
                break;
            case 'matches':
                copyOfYupSchema = getYupSchema(
                    copyOfYupSchema,
                    'matches',
                    new RegExp(rule.stringValue!),
                    'form-content:ERROR_VALUE_DOES_NOT_MATCH_PATTERN',
                    t,
                    rule.message
                );
                break;
            case 'required':
                if (copyOfYupSchema.type === 'array') {
                    copyOfYupSchema = getYupSchema(
                        copyOfYupSchema,
                        'min',
                        1,
                        'form-content:ERROR_VALUE_IS_REQUIRED',
                        t,
                        rule.message
                    );
                } else {
                    copyOfYupSchema = copyOfYupSchema[rule.function](
                        rule.message
                            ? t(`${rule.message}`)
                            : t(`${'form-content:ERROR_VALUE_IS_REQUIRED'}`)
                    );
                }
                break;
            default:
                if (rule.function != null)
                    copyOfYupSchema = copyOfYupSchema[rule.function](
                        t(`${rule.message ?? 'form-content:RequirementError'}`)
                    );
                break;
        }
    });

    return copyOfYupSchema;
};

const getYupSchema = (
    copyOfYupSchema: any,
    yupFunction: string,
    ruleValue: any,
    defaultMessage: string,
    t: TFunction<string, unknown>,
    message?: string,
    formattedValue?: string
) =>
    copyOfYupSchema[yupFunction](
        ruleValue,
        t(`${message ?? defaultMessage}`, { 1: formattedValue ?? ruleValue })
    );

const getFormattedDate = (
    rule: FormRuleItem,
    locale?: Locale,
    isWithTime?: boolean,
    dateFormat?: DateFns
): string | undefined => {
    if (!rule.stringValue) return undefined;

    if (isWithTime && dateFormat)
        return format(parseISO(rule.stringValue), dateFormat.DateTime, {
            locale,
        });

    return format(
        parseISO(rule.stringValue),
        dateFormat ? dateFormat?.Date : 'P',
        { locale }
    );
};
