import {
    LDMultiKindContext,
    LDSingleKindContext,
} from 'launchdarkly-js-sdk-common';
import { useStoreContext } from '../state';
import { getDeviceInfo } from './getDeviceInfo';
import { SessionInfoResult } from '../types/generated/sessionInfoResult';

interface Context {
    multiContext: LDMultiKindContext;
    isValid: boolean;
}

const useSetupContext = (
    sessionInfo?: SessionInfoResult & {
        platformName?: string;
    }
): Context => {
    const storeContext = useStoreContext();

    const {
        userId: sessionUserId,
        role: sessionRole,
        platformName: sessionPlatformName,
    } = sessionInfo || storeContext.state;

    const isValid = !!sessionUserId;

    const deviceContext: LDSingleKindContext = {
        kind: 'device',
        key: 'device',
        ...getDeviceInfo(),
    };
    const userContext: LDSingleKindContext = {
        kind: 'user',
        key: sessionUserId,
        name: sessionUserId,
        role: sessionRole,
    };
    const platformContext: LDSingleKindContext = {
        key: 'platform',
        kind: 'platform',
        name: sessionPlatformName || sessionInfo?.platform,
    };

    const formContext: LDSingleKindContext = {
        key: 'form',
        kind: 'form',
        formId: sessionInfo?.formId,
        tenderId: sessionInfo?.tenderId,
    };
    const multiContext: LDMultiKindContext = {
        kind: 'multi',
        user: userContext,
        platform: platformContext,
        device: deviceContext,
        form: formContext,
    };

    return { multiContext, isValid };
};

export default useSetupContext;
