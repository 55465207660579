import React, {
    MouseEvent,
    Dispatch,
    FC,
    SetStateAction,
    useEffect,
    useRef,
    memo,
} from 'react';
import Button from '@mercell/button-react';
import FieldError from '@mercell/field-error-react';
import cx from 'classnames';
import { Add32, Close24 } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { nl2br } from '../shared/utilities/nl2br';
import { error } from 'console';

interface Props {
    selectedCPVs: string[];
    setCPVPickerVisibility: Dispatch<SetStateAction<boolean>>;
    setSelectedCPVs: Dispatch<SetStateAction<string[]>>;
    isDisabled: boolean;
    showErrors: boolean;
    errors: string;
    onChangeFieldValue: (event: any) => void;
    name: string;
    allowToSelectOnlyOne?: boolean;
}

// interface SelectedCPVsDataInterface {
//     selectedCodesList: string[];
//     mainCPVCode?: string;
// }

// export const CPVFieldComponent: FC<Props> = memo(
//     ({
//         selectedCPVs,
//         setCPVPickerVisibility,
//         setSelectedCPVs,
//         onChangeFieldValue,
//         showErrors,
//         errors,
//         isDisabled,
//         initialFieldValue = { selectedCodesList: [], mainCPVCode: '' },
//         name,
//         allowToSelectOnlyOne,
//         disabledCPVs,
//     }) => {
//         const { t } = useTranslation('list.cpv');
//         const selectedCPVsRef = useRef<string[]>(selectedCPVs);
//         const initialFieldValueRef =
//             useRef<SelectedCPVsDataInterface>(initialFieldValue);
//         const { selectedCodesList, mainCPVCode } = initialFieldValue;
//         const setMainCPVChange = (event: ChangeEvent<HTMLInputElement>) => {
//             const { value } = event.target;
//             onChangeFieldValue({
//                 ...initialFieldValue,
//                 mainCPVCode: value,
//             });
//         };
//         const deleteCPVFromList = (event: MouseEvent<HTMLButtonElement>) => {
//             const { cpv } = event.currentTarget.dataset;
//             setSelectedCPVs((currentState) =>
//                 currentState.filter((value) => value !== cpv)
//             );
//         };
//         const openCPVPicker = () => setCPVPickerVisibility(true);

//         useEffect(() => {
//             setSelectedCPVs(initialFieldValueRef.current?.selectedCodesList);
//         }, [setSelectedCPVs]);

//         useEffect(() => {
//             console.log(disabledCPVs);
//             // setDisabledCPVs(disabledCPVs);
//         }, [disabledCPVs]);

//         useEffect(() => {
//             if (
//                 JSON.stringify(selectedCPVs) !==
//                     JSON.stringify(selectedCPVsRef.current) ||
//                 selectedCPVs.length === 0
//             ) {
//                 onChangeFieldValue({
//                     mainCPVCode: selectedCPVs.includes(
//                         initialFieldValue.mainCPVCode ?? ''
//                     )
//                         ? initialFieldValue.mainCPVCode
//                         : '',
//                     selectedCodesList: selectedCPVs,
//                 });
//             }
//         }, [initialFieldValue, onChangeFieldValue, selectedCPVs]);

//         return (
//             <>
//                 {selectedCodesList.length > 0 && (
//                     <div className="mb-4 border-solid border border-alto rounded-default">
//                         {/* add proper translation here */}
//                         {/* {isDisabled ? null : <div>Select Main </div>} */}
//                         {selectedCodesList.map((code: string) => (
//                             <div
//                                 className="flex place-content-between px-4 py-2 hover:bg-alto"
//                                 key={code}
//                             >
//                                 <div className="overflow-hidden">
//                                     {allowToSelectOnlyOne === undefined && (
//                                         <RadioButton
//                                             id={code}
//                                             name={`cpv-radio-main-selector-${name}`}
//                                             value={code}
//                                             disabled={isDisabled}
//                                             checked={mainCPVCode === code}
//                                             onChange={
//                                                 !isDisabled
//                                                     ? setMainCPVChange
//                                                     : undefined
//                                             }
//                                         />
//                                     )}
//                                     <label
//                                         htmlFor={code}
//                                         className={cx(
//                                             'truncate cursor-pointer',
//                                             isDisabled && 'cursor-not-allowed'
//                                         )}
//                                         data-cpv={code}
//                                     >
//                                         {`${code} ${t(code)}`}
//                                     </label>
//                                 </div>
//                                 {!isDisabled && (
//                                     <button
//                                         aria-label={`Delete ${code}: ${t(
//                                             code
//                                         )}`}
//                                         type="button"
//                                         data-cpv={code}
//                                         onClick={deleteCPVFromList}
//                                     >
//                                         <Close24 data-cpv={code} />
//                                     </button>
//                                 )}
//                             </div>
//                         ))}
//                     </div>
//                 )}
//                 {!isDisabled && (
//                     <Button
//                         scheme="secondary"
//                         onClick={openCPVPicker}
//                         disabled={isDisabled}
//                         iconSettings={{ Icon: Add32 }}
//                     >
//                         {t('form-content:AddCPV')}
//                     </Button>
//                 )}
//                 {showErrors && <FieldError>{errors}</FieldError>}
//             </>
//         );
//     },
//     (prevProps, nextProps) => {
//         try {
//             return JSON.stringify(prevProps) === JSON.stringify(nextProps);
//         } catch (e) {
//             return false;
//         }
//     }
// );

type SelectedCPVsDataType = string[];

export const CPVFieldComponent: FC<Props> = memo(
    ({
        selectedCPVs,
        setCPVPickerVisibility,
        setSelectedCPVs,
        onChangeFieldValue,
        showErrors,
        errors,
        isDisabled,
        name,
    }) => {
        const { t } = useTranslation('list.cpv');
        const selectedCPVsRef = useRef<string[]>(selectedCPVs);

        const deleteCPVFromList = (event: MouseEvent<HTMLButtonElement>) => {
            const { cpv } = event.currentTarget.dataset;
            setSelectedCPVs((currentState) =>
                currentState.filter((value) => value !== cpv)
            );
        };
        const openCPVPicker = () => setCPVPickerVisibility(true);

        useEffect(() => {
            if (
                JSON.stringify(selectedCPVs) !==
                    JSON.stringify(selectedCPVsRef.current) ||
                selectedCPVs.length === 0
            ) {
                onChangeFieldValue(selectedCPVs);
            }
            const field = document.getElementById(`${name}businessTerm`);
            if (selectedCPVs.length > 0) {
                field?.classList.remove('invisible');
            } else {
                field?.classList.add('invisible');
            }
        }, [onChangeFieldValue, selectedCPVs, name]);

        return (
            <>
                {selectedCPVs && selectedCPVs.length > 0 && (
                    <div className="mb-4 border-solid border border-alto rounded-default">
                        {/* add proper translation here */}
                        {/* {isDisabled ? null : <div>Select Main </div>} */}
                        {selectedCPVs.map((code: string) => (
                            <div
                                className="flex place-content-between px-4 py-2 hover:bg-alto"
                                key={code}
                            >
                                <div className="overflow-hidden">
                                    <label
                                        htmlFor={code}
                                        className={cx(
                                            'truncate cursor-pointer',
                                            isDisabled && 'cursor-not-allowed'
                                        )}
                                        data-cpv={code}
                                    >
                                        {`${code} ${t(code)}`}
                                    </label>
                                </div>
                                {!isDisabled && (
                                    <button
                                        aria-label={`Delete ${code}: ${t(
                                            code
                                        )}`}
                                        type="button"
                                        data-cpv={code}
                                        onClick={deleteCPVFromList}
                                    >
                                        <Close24 data-cpv={code} />
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                {!isDisabled && (
                    <Button
                        scheme="secondary"
                        onClick={openCPVPicker}
                        disabled={isDisabled}
                        iconSettings={{ Icon: Add32 }}
                    >
                        {t('form-content:AddCPV')}
                    </Button>
                )}
                {showErrors && <FieldError>{nl2br(errors)}</FieldError>}
            </>
        );
    },
    (prevProps, nextProps) => {
        try {
            return JSON.stringify(prevProps) === JSON.stringify(nextProps);
        } catch (e) {
            return false;
        }
    }
);
