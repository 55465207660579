import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { ConfiguratorCodelist } from '../../../types/generated/configuratorCodelist';
import SingleCodeList from './SingleCodeList';
import { useFlags } from 'launchdarkly-react-client-sdk';

const TabCodelists: FC<{
    codelists?: ConfiguratorCodelist[];
    t: TFunction;
}> = ({ codelists, t }) => {
    const { formconfiguratorChangesSprint4 } = useFlags();
    if (!codelists) return <div>No codelists found</div>;
    return formconfiguratorChangesSprint4 ? (
        <div>
            <div className="flex text-dove text-caption p-5 pl-0 justify-between border-b border-alto">
                <p className="w-9/12 pl-2">
                    {t('form-configurator:BusinessTerm').toUpperCase()}
                </p>
                <p className="w-4/12 flex justify-center">
                    {t('form-configurator:Status').toUpperCase()}
                </p>
                <p className="w-4/12 flex justify-center">
                    {t('form-configurator:LastChanged').toUpperCase()}
                </p>
                <p className="w-5/12 pl-2 flex justify-center">
                    {t('form-configurator:User').toUpperCase()}
                </p>
            </div>
            <div className="overflow-auto" style={{ height: '40vh' }}>
                {codelists.map((codelist) => (
                    <SingleCodeList
                        key={codelist.name}
                        codelist={codelist}
                        t={t}
                    />
                ))}
            </div>
        </div>
    ) : (
        <div>
            <div className="flex text-dove text-caption p-5 pl-0 justify-between border-b border-alto">
                <p className="w-10/12 flex">
                    {t('form-configurator:BusinessTerm').toUpperCase()}
                </p>
                <p className="w-2/12 flex justify-center">
                    {t('form-configurator:Status').toUpperCase()}
                </p>
            </div>
            <div className="overflow-auto" style={{ height: '40vh' }}>
                {codelists.map((codelist) => (
                    <SingleCodeList
                        key={codelist.name}
                        codelist={codelist}
                        t={t}
                    />
                ))}
            </div>
        </div>
    );
};

export default TabCodelists;
