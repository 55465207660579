import React, { ChangeEvent, useEffect, useState } from 'react';
import { SelectOption } from '../../types/generated/selectOption';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@mercell/dropdown-react';
import Button from '@mercell/button-react';
import { Link, useNavigate } from 'react-router-dom';
import useFetchCountries from '../../hooks/configuratorHooks/useFetchCountries';
import Breadcrumbs from '@mercell/breadcrumbs-react';
import OktaAuthWrapper from '../../authorization/OktaAuthWrapper';
import useFetchVersions from '../../hooks/configuratorHooks/useFetchVersions';
import { Input } from '@mercell/input-react';
import useFetchBaseVersions from '../../hooks/configuratorHooks/useFetchBaseVersions';
import saveVersion from '../../apiCalls/configurator/saveVersion';
import { ConfiguratorVersion } from '../../types/generated/configuratorVersion';
import NotFoundPage from '../NotFound/NotFoundPage';
import LoadingSpinner from '@mercell/loading-spinner-react';
import { useStoreContext } from '../../state';
import refreshAllConfigurations from '../../apiCalls/configurator/refreshAllConfigurations';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppSelector } from '../../redux/hooks';

const FormConfiguratorMainPage = () => {
    const [country, setCountry] = useState<SelectOption>();
    const [version, setVersion] = useState<SelectOption>();
    const [versionName, setVersionName] = useState<string>();
    const [baseVersion, setBaseVersion] = useState<SelectOption>();
    const [isVersionChanged, setIsVersionChanged] = useState<boolean>(false);

    const { formConfiguratorLogoMef1798, useStoreRedux1860 } = useFlags();

    const navigate = useNavigate();
    const { countries, isErrorFetchingCountries } = useFetchCountries();
    const { versions, setStateVersions } = useFetchVersions(country?.value);
    const { baseVersions, setStateBaseVersions } = useFetchBaseVersions(
        country?.value
    );
    const { t } = useTranslation([
        'form-configurator',
        'list.country',
        'list.legal-basis',
        'list.form-type',
        'list.notice-type',
        'toast-content',
    ]);

    const {
        state: { UserRights },
    } = useStoreContext();

    const reduxUserRights = useAppSelector((state) => state.store.userRights);

    const onChangeCountry = async (newValue: SelectOption) => {
        setCountry(newValue);
        setVersion(undefined);
        setBaseVersion(undefined);
        setVersionName(undefined);
    };

    const onChangeVersion = (newValue: SelectOption) => {
        setVersion(newValue);
        setBaseVersion(undefined);
        const name = newValue?.label
            ?.replace(` (${t('form-configurator:Activated')})`, '')
            .replace(` (${t('form-configurator:InProgress')})`, '');
        setVersionName(newValue?.value ? name : undefined);
        if (newValue?.value) setDefaultBaseVersion(newValue.value);
    };

    const setDefaultBaseVersion = (versionId: string) => {
        if (country) {
            const currentVersion = versions?.find((v) => v.id === versionId);
            const currentBaseVersion = baseVersions?.find(
                (bs) => bs.id === currentVersion?.baseVersionId
            );
            setBaseVersion({
                label: currentBaseVersion?.name,
                value: currentBaseVersion?.id,
            });
        }
    };

    const onChangeVersionName = (e: ChangeEvent<HTMLInputElement>) => {
        const name = e.currentTarget.value;
        setVersionName(name);

        if (name !== version?.label) {
            setIsVersionChanged(true);
        }
    };

    const onChangeBaseVersion = (newValue: SelectOption) => {
        setBaseVersion(newValue);
        setIsVersionChanged(true);
    };

    const onGoNext = async () => {
        const payload: ConfiguratorVersion = {
            id: version?.value,
            name: versionName ?? 'untitled',
            country: country?.value,
            baseVersionId: baseVersion?.value,
            isFinalized: false,
        };

        const versionId = isVersionChanged
            ? await saveVersion(payload)
            : payload.id;

        navigate(
            `/configurator/${versionName}/${versionId}/notices${
                country ? `/${country.value}` : ''
            }`
        );
    };

    const onRefreshAll = async () => {
        await refreshAllConfigurations({
            onPendingText: t(
                'toast-content:ToastRefreshingConfigurationsPending'
            ),
            onSuccessText: t(
                'toast-content:ToastRefreshingConfigurationsSuccess'
            ),
        });
    };

    useEffect(
        () => () => {
            setStateVersions(undefined);
            setStateBaseVersions(undefined);
        },
        [setStateVersions, setStateBaseVersions, country?.value]
    );

    if (isErrorFetchingCountries)
        return (
            <NotFoundPage
                wrapperClass="col-span-full"
                errorCode={isErrorFetchingCountries.status}
            />
        );

    return (
        <OktaAuthWrapper>
            {!countries ? (
                <div className="col-span-full h-full flex items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className="col-span-full justify-self-center md:min-w-[600px] max-w-[600px] whitespace-nowrap flex flex-col xl:items-center">
                    {!formConfiguratorLogoMef1798 && (
                        <div className="mb-10">
                            <Breadcrumbs>
                                <Link to={window.location.pathname}>
                                    {t('form-configurator:FormConfigurator')}
                                </Link>
                            </Breadcrumbs>
                        </div>
                    )}
                    {(useStoreRedux1860
                        ? reduxUserRights?.importVersionEnabled === true
                        : UserRights?.importVersionEnabled === true) && (
                        <div>
                            <h2 className="mb-10">
                                {t('form-configurator:AdministrativeActivity')}
                            </h2>
                            <div className="mb-8 md:w-6/12 w-full">
                                <div className="flex">
                                    <Button
                                        className="mr-8"
                                        scheme="secondary"
                                        onClick={onRefreshAll}
                                    >
                                        {t(
                                            'form-configurator:RefreshEverything'
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                    <h2 className="mb-10">
                        {t('form-configurator:ChooseCountryAndVersion')}
                    </h2>
                    <div className="mb-8 w-full">
                        <div className="mb-8">
                            <p>{t('list.country:Country')}</p>
                            <Dropdown
                                options={countries.map((c) => ({
                                    value: c.code,
                                    label: t(c.key ?? ''),
                                }))}
                                isClearable
                                onChange={(newValue: unknown) =>
                                    onChangeCountry(newValue as SelectOption)
                                }
                            />
                        </div>
                        <div className="mb-8">
                            <p>{t('form-configurator:Version')}</p>
                            {versions ? (
                                <Dropdown
                                    options={[
                                        ...versions.map((v) => ({
                                            value: v.id,
                                            label: `${
                                                !v.id
                                                    ? t('New')
                                                    : v.name +
                                                      (v.isFinalized
                                                          ? ` (${t(
                                                                'form-configurator:Activated'
                                                            )})`
                                                          : '')
                                            }`,
                                        })),
                                    ]}
                                    isClearable
                                    value={version ?? ''}
                                    onChange={(newValue: unknown) =>
                                        onChangeVersion(
                                            newValue as SelectOption
                                        )
                                    }
                                />
                            ) : (
                                <div>
                                    {t('form-configurator:NoVersionsFound')}
                                </div>
                            )}
                        </div>
                        {version && (
                            <div className="mb-8">
                                <p>{t('form-configurator:VersionName')}</p>
                                <Input
                                    onChange={onChangeVersionName}
                                    value={versionName ?? ''}
                                />
                            </div>
                        )}
                        {versionName && country && (
                            <div className="mb-8">
                                <p>{t('form-configurator:BaseVersion')}</p>
                                <Dropdown
                                    options={baseVersions?.map((bv) => ({
                                        value: bv.id,
                                        label: bv.name,
                                    }))}
                                    isClearable
                                    value={baseVersion ?? ''}
                                    onChange={(newValue: unknown) =>
                                        onChangeBaseVersion(
                                            newValue as SelectOption
                                        )
                                    }
                                />
                            </div>
                        )}
                    </div>
                    {versionName && (
                        <div className="flex self-end">
                            <Button scheme="primary" onClick={onGoNext}>
                                {!version?.value
                                    ? t('form-configurator:CreateNewVersion')
                                    : t('form-configurator:EditVersion')}
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </OktaAuthWrapper>
    );
};

FormConfiguratorMainPage.whyDidYouRender = true;
export default FormConfiguratorMainPage;
