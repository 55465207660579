import React, { ChangeEvent, FC, useState } from 'react';
import { Input, InputContainer } from '@mercell/input-react';
import { AlarmSubtract32 } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import OktaAuthWrapper from '../../../authorization/OktaAuthWrapper';
import LoadingSpinner from '@mercell/loading-spinner-react';
import useFetchFeatureFlags from '../../../hooks/administrativeHooks/useFetchFeatureFlags';
import { Ribbon } from '@mercell/ribbon-react';
import { format, parseISO } from 'date-fns';
import { useAppSelector } from '../../../redux/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useStoreContext } from '../../../state';

const FeatureFlagsListPage: FC = () => {
    const { features, isLoading } = useFetchFeatureFlags();

    const {
        state: { UserRights },
    } = useStoreContext();

    const { t } = useTranslation(['form-content']);

    const [searchString, setSearchString] = useState<string>();

    const { useStoreRedux1860 } = useFlags();
    const reduxUserRights = useAppSelector((state) => state.store.userRights);
    const reduxDateFormat = useAppSelector((state) => state.store.dateFormat);
    const reduxLocale = useAppSelector((state) => state.store.locale);

    return (
        <OktaAuthWrapper>
            {!features || isLoading ? (
                <div className="col-span-full h-full flex items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className="col-span-full xl:justify-self-center xl:min-w-[1600px] max-w-[1600px] whitespace-normal">
                    {(useStoreRedux1860
                        ? reduxUserRights?.featuresReviewEnabled === true
                        : UserRights?.featuresReviewEnabled === true) && (
                        <div>
                            <h2 className="mb-10">Feature flags</h2>
                            <div className="flex justify-between">
                                <div className="mb-10">
                                    <InputContainer>
                                        <Input
                                            placeholder={t(
                                                'form-configurator:Search'
                                            )}
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>
                                            ) =>
                                                setSearchString(
                                                    e.currentTarget.value.toLocaleLowerCase()
                                                )
                                            }
                                        />
                                    </InputContainer>
                                </div>
                            </div>

                            <div className="flex text-dove text-caption p-5 justify-between border-b border-alto">
                                <p className="w-5/12">
                                    <strong>FEATURE</strong>
                                </p>
                                <p className="w-2/12">
                                    <strong>DEV</strong>
                                </p>
                                <p className="w-2/12">
                                    <strong>STAGE</strong>
                                </p>
                                <p className="w-2/12">
                                    <strong>PROD</strong>
                                </p>
                                <p className="w-1/12">
                                    <strong>...</strong>
                                </p>
                            </div>
                            <div
                                className="overflow-auto"
                                style={{ height: '45vh' }}
                            >
                                {features
                                    ?.filter((feature) => {
                                        if (
                                            searchString?.length === 0 ||
                                            (feature?.key ?? '')
                                                .toLocaleLowerCase()
                                                .includes(searchString ?? '') ||
                                            (feature?.name ?? '')
                                                .toLocaleLowerCase()
                                                .includes(searchString ?? '') ||
                                            (feature?.description ?? '')
                                                .toLocaleLowerCase()
                                                .includes(searchString ?? '')
                                        ) {
                                            return true;
                                        }
                                        return false;
                                    })
                                    // eslint-disable-next-line arrow-body-style
                                    ?.map((feature) => {
                                        return (
                                            <div className="p-5 flex">
                                                <div className="flex justify-between w-full break-words">
                                                    <div className="w-5/12">
                                                        <p>
                                                            <strong>
                                                                {feature?.name}
                                                            </strong>
                                                        </p>
                                                        <p className="mb-5">
                                                            <i>
                                                                {feature?.key}
                                                            </i>
                                                        </p>
                                                        <p>
                                                            {
                                                                feature?.description
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="w-2/12">
                                                        <Ribbon
                                                            scheme={
                                                                feature?.values
                                                                    .development
                                                                    ?.isEnabled
                                                                    ? 'success'
                                                                    : 'error'
                                                            }
                                                        >
                                                            {format(
                                                                parseISO(
                                                                    feature?.values.development?.modifiedDate.toString()
                                                                ),
                                                                reduxDateFormat.DateTimeWithoutZone,
                                                                {
                                                                    locale: reduxLocale,
                                                                }
                                                            )}
                                                        </Ribbon>
                                                    </div>
                                                    <div className="w-2/12">
                                                        <Ribbon
                                                            scheme={
                                                                feature?.values
                                                                    .test
                                                                    ?.isEnabled
                                                                    ? 'success'
                                                                    : 'error'
                                                            }
                                                        >
                                                            {format(
                                                                parseISO(
                                                                    feature?.values.test?.modifiedDate.toString()
                                                                ),
                                                                reduxDateFormat.DateTimeWithoutZone,
                                                                {
                                                                    locale: reduxLocale,
                                                                }
                                                            )}
                                                        </Ribbon>
                                                    </div>
                                                    <div className="w-2/12">
                                                        <Ribbon
                                                            scheme={
                                                                feature?.values
                                                                    .production
                                                                    ?.isEnabled
                                                                    ? 'success'
                                                                    : 'error'
                                                            }
                                                        >
                                                            {format(
                                                                parseISO(
                                                                    feature?.values.production?.modifiedDate.toString()
                                                                ),
                                                                reduxDateFormat.DateTimeWithoutZone,
                                                                {
                                                                    locale: reduxLocale,
                                                                }
                                                            )}
                                                        </Ribbon>
                                                    </div>
                                                    <div className="w-1/12">
                                                        {feature?.deleteCandidate ===
                                                        true ? (
                                                            <AlarmSubtract32 title="Enabled in Production for at least 20 days" />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </OktaAuthWrapper>
    );
};

export default FeatureFlagsListPage;
