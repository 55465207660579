import Cookies from 'js-cookie';
import { getCurrentFormId } from './sessionService';

export const getAdminToken = () => Cookies.get('authTokenAdm') ?? '';

export const setAdminToken = (token: string) => {
    Cookies.set('authTokenAdm', token, {
        secure: true,
        path: 'auth',
        expires: 1,
    });
};

export const removeAdminToken = () => Cookies.remove('authTokenAdm');

export const getOktaToken = () => {
    const oktaObject = Cookies.get('okta-token-storage_accessToken');
    if (oktaObject) {
        return JSON.parse(oktaObject).accessToken;
    }
    return '';
};

export const removeOktaTokens = () => {
    Cookies.remove('okta-token-storage_idToken');
    Cookies.remove('okta-token-storage_accessToken');
};

export const getRepToken = () => Cookies.get(getCurrentFormId()) ?? '';

export const setRepToken = (
    token: string,
    otp: string,
    basePath?: string
): string => {
    Cookies.set(getCurrentFormId(), token, {
        secure: true,
        path: `/otp/${otp}`,
        expires: 1,
    });
    const url = `${basePath}/${getCurrentFormId()}`;
    Cookies.set(getCurrentFormId(), token, {
        secure: true,
        path: url,
        expires: 1,
    });
    return url;
};

export const removeRepToken = () => Cookies.remove(getCurrentFormId());
