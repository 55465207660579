import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import {
    Outlet,
    useNavigate,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreContext } from '../../state';
import cx from 'classnames';
import CustomContentMenu from '../../components/CustomContentMenu';
import { ChevronLeft24 } from '@carbon/icons-react';
import { Button } from '@mercell/button-react';
import { OutletFormNavigationContextInterface } from '../../types/outletInterface';
import BackNavigation from '../../types/backNavigation';
import { Portal } from '@mercell/portal-react';
import { Drawer } from '@mercell/drawer-react';
import { ValidationDrawerContent } from './ValidationDrawerContent/ValidationDrawerContent';

const FormNavigation: FC = memo(() => {
    const {
        formId,
        parentSectionId,
        parentArrayPath,
        sectionId,
        arrayPath,
        arrayElementId,
        parentArrayElementId,
    } = useParams<{
        formId: string;
        parentSectionId?: string;
        parentArrayPath?: string;
        sectionId: string;
        arrayPath?: string;
        parentArrayElementId?: string;
        arrayElementId?: string;
    }>();
    const {
        setBackendValidationErrors,
        backendValidationErrors,
        sections,
        isFormPreview,
        setHotReloadOfMenu,
        parentElementTitleKey,
        navigationTitle,
        validateMethod,
        isLoadingFetchingValidations,
        isErrorFetchingSections,
        currentFormStatus,
        isErrorFetchingValidations,
        setShouldFetchValidation,
        shouldFetchValidation,
    } = useOutletContext<OutletFormNavigationContextInterface>();

    const navigate = useNavigate();
    const [, setShowNavigation] = useState<boolean>(false);
    const { t } = useTranslation(['form-content', 'toast-content']);

    const navWrapper = useRef<HTMLDivElement>(null);
    const currentSelectedSection = sections
        ? sections.find((section) => section.sectionName === sectionId)
        : undefined;
    useEffect(() => {
        if (sections) {
            if (!sectionId && sections)
                navigate(
                    `${
                        !parentSectionId
                            ? `/form/${formId}`
                            : `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}/${parentArrayElementId}`
                    }/${sections[0].sectionName}`,
                    { replace: true }
                );
        }
    }, [
        sections,
        sectionId,
        formId,
        navigate,
        arrayPath,
        parentSectionId,
        parentArrayPath,
        parentArrayElementId,
    ]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!navWrapper.current?.contains(event.target as Element)) {
                setShowNavigation(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () =>
            document.removeEventListener('mousedown', handleClickOutside);
    }, [navWrapper]);

    const baseUrl = !parentSectionId
        ? `/form/${formId}`
        : `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}/${parentArrayElementId}`;

    const changeSectionIndex = (value: string) => {
        if (value === sectionId && !arrayPath) return;

        const menuItem = sections?.filter(
            (section) => section.sectionName === value
        )[0];

        navigate(
            `${baseUrl}${value ? `/${value}` : ''}${
                menuItem?.isDetailedArray === true ? '?pageNo=1' : ''
            }`
        );
    };

    const setupBackNavigation = () => {
        let backNavigation: BackNavigation = { path: '', label: '' };
        if (parentSectionId && !arrayElementId && parentElementTitleKey) {
            backNavigation = {
                path: `/form/${formId}/${parentSectionId}/${parentArrayPath}`,
                label: t(parentElementTitleKey),
            };
        }
        if (parentSectionId && arrayElementId && currentSelectedSection) {
            backNavigation = {
                path: `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}/${parentArrayElementId}/${sectionId}/${arrayPath}`,
                label: t(currentSelectedSection.translationKey),
            };
        }
        if (!parentSectionId && arrayElementId && currentSelectedSection) {
            backNavigation = {
                path: `/form/${formId}/${sectionId}/${arrayPath}`,
                label: t(currentSelectedSection.translationKey),
            };
        }
        return backNavigation;
    };

    const [isDrawerVisible, toggleDrawer] = useState<boolean>(false);

    const backNavigation = setupBackNavigation();
    const closeDrawer = () => toggleDrawer(false);
    const openDrawer = () => toggleDrawer(true);
    const errorKeys = useMemo(
        () => Object.keys(backendValidationErrors.submittedErrors),
        [backendValidationErrors.submittedErrors]
    );

    return (
        <>
            {/* MyChecklist Portal Button */}
            {!isDrawerVisible && !isFormPreview && (
                <Portal parent={document.body}>
                    <Button
                        className="fixed right-0 z-100 top-1/4 -translate-x-[48px] translate-y-1/2 origin-[100%_0%] -rotate-90 border-b-0 rounded-b-none ignore-outside-click"
                        scheme="secondary"
                        onClick={() => {
                            openDrawer();
                        }}
                    >
                        {t('form-content:CheckList')}
                    </Button>
                </Portal>
            )}

            {/* Menu Sections */}
            <div className="w-fit mr-8 top-28 sticky col-start-3 col-end-5 h-auto justify-self-end self-start whitespace-nowrap">
                {(arrayElementId || parentSectionId) &&
                    sections &&
                    !isErrorFetchingSections && (
                        <div className="my-2 min-w-full print:hidden whitespace-nowrap">
                            <Button
                                scheme="tertiary"
                                onClick={() => navigate(backNavigation.path)}
                                iconSettings={{ Icon: ChevronLeft24 }}
                                type="button"
                                className="flex text-valhalla font-semibold px-0"
                                data-test="back-navigation-button"
                            >
                                {t('form-content:BackToElement', {
                                    element: backNavigation.label,
                                })}
                            </Button>
                        </div>
                    )}
                {!isFormPreview &&
                    (!sections || isErrorFetchingSections ? (
                        <div
                            className={cx(
                                `p-2 my-1 text-valhalla flex items-baseline`
                            )}
                        >
                            <p className="bg-alto w-full h-6 animate-pulse rounded-default" />
                        </div>
                    ) : (
                        <Button
                            type="button"
                            scheme="secondary"
                            className="my-2 min-w-full border-2 relative inline-block  overflow-visible"
                            onClick={() => {
                                if (!isDrawerVisible) {
                                    openDrawer();
                                } else {
                                    closeDrawer();
                                }
                            }}
                        >
                            {t('form-content:CheckList')}
                            {!shouldFetchValidation && (
                                <span className="absolute flex -top-2 -right-2 w-4 h-4">
                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-well-read opacity-85" />
                                    <span className="relative inline-flex rounded-full  w-4 h-4 bg-well-read" />
                                </span>
                            )}
                        </Button>
                    ))}
                <CustomContentMenu
                    className={cx(
                        'w-[100%]',
                        (isErrorFetchingSections || !sections) &&
                            'min-w-[230px]'
                    )}
                    primaryItems={
                        sections
                            ? sections.map((section) => ({
                                  title: t(section.translationKey),
                                  path: section.arrayPath
                                      ? `${section.sectionName}/${section.arrayPath}`
                                      : section.sectionName,
                                  parentSectionPath: section.parentSectionName,
                                  isItemActive:
                                      sectionId === section.sectionName,
                                  isChevronActive:
                                      sectionId === section.sectionName,
                              }))
                            : []
                    }
                    onClick={(e, title, path) => {
                        e.preventDefault();
                        changeSectionIndex(path);
                        setShowNavigation(false);
                    }}
                />
            </div>

            {/* Form Wrapper */}

            <div className="col-start-5 col-end-10 mt-5">
                <Outlet
                    context={{
                        validateMethod,
                        setBackendValidationErrors,
                        backendValidationErrors,
                        sections,
                        isFormPreview,
                        setHotReloadOfMenu,
                        parentElementTitleKey,
                        navigationTitle,
                        currentSelectedSection,
                    }}
                />
            </div>

            {/* My Checklist Drawer */}
            <Drawer
                isDrawerVisible={isDrawerVisible}
                usePortal={false}
                documentOverflowHidden={false}
                onCloseCallback={closeDrawer}
                className={cx(
                    'shadow-[-10px_10px_40px_rgba(114,114,114,0.15)] overflow-hidden',
                    'px-0 pb-0 pt-0 ml-auto -mb-6',
                    'top-24 h-max',
                    'min-w-[400px] max-w-[500px] w-full',
                    'md:fixed lg:sticky col-start-11',
                    !isDrawerVisible && 'w-0 min-w-[0px] min-w-[0px]'
                )}
                drawerTitle={t('form-content:CheckList')}
                headerProps={{
                    className: 'sticky top-0 z-20 bg-white pt-6 px-8',
                }}
                closeButtonProps={{
                    className: 'top-[1.7rem right-0 px-8',
                }}
            >
                <ValidationDrawerContent
                    validateMethod={validateMethod}
                    backendValidationErrors={backendValidationErrors}
                    t={t}
                    errorKeys={errorKeys}
                    isLoadingFetchingValidations={isLoadingFetchingValidations}
                    isErrorFetchingValidations={isErrorFetchingValidations}
                    setShouldFetchValidation={setShouldFetchValidation}
                />
            </Drawer>
        </>
    );
});

FormNavigation.displayName = 'FormNavigation';
FormNavigation.whyDidYouRender = true;
export default FormNavigation;
