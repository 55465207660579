export interface DateFns {
    Date: string;
    DateTime: string;
    Time: string;
    DateTimeWithoutZone: string;
}

export const DateFormats: DateFns = {
    Date: 'P',
    Time: 'p',
    DateTime: 'Pp',
    DateTimeWithoutZone: 'Pp',
};

export const DateFormatsNew: DateFns = {
    Date: 'P',
    Time: 'p',
    DateTime: 'Pp z',
    DateTimeWithoutZone: 'Pp',
};
