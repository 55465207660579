/* istanbul ignore file */
import axios, { AxiosResponse } from 'axios';
import {
    FormSelectorConfiguration,
    SelectOption,
} from '../../types/generated/formSelectorConfiguration';
import useSWRImmutable from 'swr/immutable';

export interface FormCreationPropertiesInterface {
    countries: SelectOption[];
    directives: SelectOption[];
    formTypes: SelectOption[];
    noticeTypes: SelectOption[];
    noticeSubtypes: SelectOption[];
    languages: SelectOption[];
}

const fetcher = (url: string) =>
    axios
        .get(url)
        .then(
            async (
                res: AxiosResponse<FormSelectorConfiguration>
            ): Promise<FormCreationPropertiesInterface> => {
                const { data } = res;
                return {
                    countries: data.countries ?? [],
                    directives: data.directives ?? [],
                    formTypes: data.formTypes ?? [],
                    noticeTypes: data.noticeTypes ?? [],
                    noticeSubtypes: data.noticeSubtypes ?? [],
                    languages: data.languages ?? [],
                };
            }
        );

const useFetchFormCreationProperties = (
    country?: string,
    directive?: string,
    formType?: string,
    noticeType?: string
) => {
    const { data, error } = useSWRImmutable(
        `/rep/form/selector/v2${country ? `/${country}` : '/-'}${
            directive ? `/${directive}` : '/-'
        }${formType ? `/${formType}` : '/-'}${
            noticeType ? `/${noticeType}` : '/-'
        }`,
        fetcher,
        { shouldRetryOnError: false }
    );
    return {
        data,
        isLoadingProperties: !error && !data,
        isErrorFetchingProperties: error,
    };
};
export default useFetchFormCreationProperties;
