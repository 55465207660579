import React from 'react';
import i18next from 'i18next';
import { useParams } from 'react-router-dom';
import useFetchPreview from '../../hooks/administrativeHooks/useFetchFormHtmlPreview';
import NotFoundPage from '../NotFound/NotFoundPage';
import LoadingSpinner from '@mercell/loading-spinner-react';
import { convertLanguageToCode } from '../../shared/utilities/languageToLocaleConvert';

const FormHtmlPreviewPage = () => {
    const { language } = i18next;

    const languageCode = convertLanguageToCode(language);

    const { formId } = useParams<{ formId: string }>();
    const { filestream, isErrorFetchingPreview, isLoadingPreview } =
        useFetchPreview(formId ?? '', languageCode ?? 'en_GB', 'html');
    if (isLoadingPreview)
        return (
            <div className="col-span-full h-full flex items-center justify-center">
                <LoadingSpinner />
            </div>
        );

    if (isErrorFetchingPreview)
        return (
            <NotFoundPage
                wrapperClass="col-span-full"
                errorCode={isErrorFetchingPreview.status.toString()}
            />
        );

    return (
        <div className="col-span-full">
            <div dangerouslySetInnerHTML={{ __html: filestream ?? '' }} />
        </div>
    );
};

export default FormHtmlPreviewPage;
