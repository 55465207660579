import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useStoreContext } from '../state';
import ConfirmationModal from '../components/ConfirmationModal';
import { Warning32 } from '@carbon/icons-react';
import roles from '../state/roles';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { changeUser, changeUsername, setUserRole } from '../redux/storeSlice';

interface OktaAuthWrapperProps {
    children: React.ReactElement;
}

// this wrapper checks if needed tokens are present in cookies and sets user role in the app state
// access tokens are being set with <Security/> component in the <App/> component  which is part of okta lib
const OktaAuthWrapper = ({ children }: OktaAuthWrapperProps) => {
    const { useStoreRedux1860 } = useFlags();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const { authState } = useOktaAuth();
    const {
        dispatch,
        state: { userId },
    } = useStoreContext();
    const navigate = useNavigate();

    const reduxUserId = useAppSelector((state) => state.store.userId);
    const reduxDispatch = useAppDispatch();

    useEffect(() => {
        if (authState && authState?.isAuthenticated) {
            if (useStoreRedux1860) {
                reduxDispatch(
                    changeUser(authState?.idToken?.claims.email ?? '')
                );

                reduxDispatch(
                    changeUsername(
                        authState?.idToken?.claims.name ?? 'name unknown'
                    )
                );
                reduxDispatch(setUserRole(roles.admin));
            } else {
                dispatch({
                    type: 'CHANGE_USER',
                    payload: { userId: authState?.idToken?.claims.email ?? '' },
                });

                dispatch({
                    type: 'CHANGE_USERNAME',
                    payload: {
                        userName:
                            authState?.idToken?.claims.name ?? 'name unknown',
                    },
                });

                dispatch({
                    type: 'SET_ROLE',
                    payload: { userRole: roles.admin },
                });
            }
        } else {
            setIsModalVisible(true);
        }
    }, [authState, dispatch, reduxDispatch, useStoreRedux1860]);

    if (useStoreRedux1860) {
        if (!reduxUserId) return null;
    } else if (!userId) return null;

    if (isModalVisible)
        return (
            <ConfirmationModal
                text="You are not authorized to access this page."
                confirmText="  Ok  "
                onConfirm={() =>
                    navigate('/unauthorized-access', { replace: true })
                }
                closeModal={() => setIsModalVisible(false)}
                isModalVisible={isModalVisible}
            >
                <Warning32
                    style={{
                        width: 150,
                        height: 150,
                        margin: '30px auto',
                        color: 'orange',
                    }}
                />
            </ConfirmationModal>
        );
    return children;
};

export default OktaAuthWrapper;
