import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useFetchFormDefinition from '../../../hooks/configuratorHooks/useFetchFormDefinition';
import SingleFieldConfigurator from './SingleFieldConfigurator';
import Button from '@mercell/button-react';
import { Input, InputContainer, InputRightElement } from '@mercell/input-react';
import { Launch32, Search32 } from '@carbon/icons-react';
import Breadcrumbs from '@mercell/breadcrumbs-react';
import { useTranslation } from 'react-i18next';
import { ConfigurationFieldVisibility } from '../../../types/generated/configuratorFormDefinition';
import OktaAuthWrapper from '../../../authorization/OktaAuthWrapper';
import { Drawer } from '@mercell/drawer-react';
import { saveFormDefinitionForPreview } from '../../../apiCalls/configurator/saveFormDefinitionForPreview';
import finalizeDefinition from '../../../apiCalls/configurator/finalizeDefinition';
import LoadingSpinner from '@mercell/loading-spinner-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

const FormDefinitionConfiguratorPage: FC = () => {
    const [definitionFields, setDefinitionFields] = useState<
        ConfigurationFieldVisibility[] | undefined
    >();
    const [validationErrors, setValidationErrors] = useState<
        { [key: string]: string } | undefined
    >();
    const [newDefinitionId, setNewDefinitionId] = useState<string>();
    const [isDrawerVisible, toggleDrawerVisibility] = useState<boolean>(false);

    const { dashboardChangesSprint4, useToasts1734 } = useFlags();

    const { versionName, versionId, definitionId, country } = useParams<{
        versionName: string;
        versionId: string;
        definitionId: string;
        country: string;
    }>();
    const navigate = useNavigate();
    const { formDefinition, isLoadingFormDefinition } = useFetchFormDefinition(
        definitionId ?? '',
        versionId ?? ''
    );
    const { t } = useTranslation([
        'form-configurator',
        'list.country',
        'list.legal-basis',
        'list.form-type',
        'list.notice-type',
    ]);

    const mutateField = async (field: ConfigurationFieldVisibility) => {
        if (!formDefinition) return null;
        updateFieldInFieldsArray(field);

        const data = await saveUpdatedFormDefinition();

        if (data) {
            if (data.apiError && dashboardChangesSprint4 && !useToasts1734) {
                return null;
            }

            if (Object.keys(data.errors).length) {
                setValidationErrors(data.errors);
            }

            if (data.formDefinitionId) {
                setNewDefinitionId(data.formDefinitionId);
            }
        }
    };

    const updateFieldInFieldsArray = (field: ConfigurationFieldVisibility) => {
        const fields = formDefinition?.fields?.map((fdField) => {
            if (fdField.businessTermId === field.businessTermId) {
                fdField = field;
            }
            return fdField;
        });
        if (formDefinition) formDefinition.fields = fields;
    };

    const saveUpdatedFormDefinition = async () => {
        if (!formDefinition) return null;

        const data = await saveFormDefinitionForPreview(
            versionId ?? '',
            formDefinition,
            t
        );

        return data;
    };

    const onPreview = () => {
        if (!validationErrors) {
            navigate(
                `/configurator/${versionName}/${versionId}/notices/preview/${
                    newDefinitionId ?? definitionId
                }${country ? `/${country}` : ''}`
            );
        } else {
            toggleDrawerVisibility(true);
        }
    };

    const previewWithErrors = () => {
        navigate(
            `/configurator/${versionName}/${versionId}/notices/preview/${definitionId}${
                country ? `/${country}` : ''
            }`
        );
    };

    const onChangeFilter = (e: ChangeEvent<HTMLInputElement>) => {
        const fields = formDefinition?.fields?.filter(
            (field) =>
                field.businessTermId
                    .toLocaleLowerCase()
                    .includes(e.currentTarget.value.toLocaleLowerCase()) ||
                field.businessTermName
                    .toLocaleLowerCase()
                    .includes(e.currentTarget.value.toLocaleLowerCase()) ||
                t(field.translationKey ?? '')
                    .toLocaleLowerCase()
                    .includes(e.currentTarget.value.toLocaleLowerCase())
        );
        setDefinitionFields(fields);
    };
    const onFinalize = async () => {
        if (!formDefinition) return null;

        const data = await finalizeDefinition(definitionId ?? '');

        if (data)
            navigate(
                `/configurator/${versionName}/${versionId}/notices${
                    country ? `/${country}` : ''
                }`
            );
    };

    useEffect(() => {
        if (
            !definitionFields &&
            !isLoadingFormDefinition &&
            !!formDefinition?.fields
        ) {
            setDefinitionFields(formDefinition.fields);
        }
    }, [formDefinition, definitionFields, isLoadingFormDefinition]);

    return (
        <OktaAuthWrapper>
            {!formDefinition || !formDefinition.fields ? (
                <div className="col-span-full h-full flex items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className="col-span-full justify-self-center min-w-[80%] max-w-[80%]">
                    <div className="mb-10">
                        <Breadcrumbs>
                            <Link to="/configurator">Form configurator</Link>
                            <Link
                                to={`/configurator/${versionName}/${versionId}/notices${
                                    country ? `/${country}` : ''
                                }`}
                            >
                                {versionName} {t(country ?? '')}
                            </Link>
                            <Link
                                to={window.location.pathname}
                                className="pointer-events-none"
                            >
                                {formDefinition.noticeSubtype}{' '}
                                {t(formDefinition.directiveKey ?? '')}{' '}
                                {t(formDefinition.formTypeKey ?? '')}
                            </Link>
                        </Breadcrumbs>
                    </div>
                    <h2 className="mb-10">
                        {t('form-configurator:TailorableFields')}
                    </h2>
                    <div className="flex justify-between">
                        <div className="mb-10 w-5/12">
                            <InputContainer>
                                <Input
                                    placeholder={t('form-configurator:Search')}
                                    onChange={onChangeFilter}
                                />
                                <InputRightElement>
                                    <Search32 />
                                </InputRightElement>
                            </InputContainer>
                        </div>
                        <div>
                            <Button
                                iconSettings={{ Icon: Launch32 }}
                                scheme="secondary"
                                onClick={() =>
                                    window.open(
                                        'https://mercell.crowdin.com/u/projects/45'
                                    )
                                }
                            >
                                {t('form-configurator:OpenCrowdinProject')}
                            </Button>
                        </div>
                    </div>

                    <div className="flex text-dove text-caption p-5 pl-0 justify-between border-b border-alto break-all">
                        <p className="w-3/12">
                            {t('form-configurator:BusinessTerm').toUpperCase()}
                        </p>
                        <p className="w-3/12 text-center pl-5">
                            {t('form-configurator:InitialValue').toUpperCase()}
                        </p>
                        <p className="w-1/12 text-center pl-5">
                            {t('form-configurator:Hidden').toUpperCase()}
                        </p>
                        <p className="w-1/12 text-center pl-5">
                            {t('form-configurator:Mandatory').toUpperCase()}
                        </p>
                        <p className="w-4/12 pl-5">
                            {t('form-configurator:Translation').toUpperCase()}
                        </p>
                    </div>
                    <div className="overflow-auto" style={{ height: '45vh' }}>
                        {definitionFields?.map((field) => (
                            <SingleFieldConfigurator
                                field={field}
                                mutateField={mutateField}
                                key={field.businessTermId}
                            />
                        ))}
                    </div>
                    <div className="flex justify-end my-12">
                        <Button
                            className="mr-4"
                            scheme="secondary"
                            onClick={() =>
                                navigate(
                                    `/configurator/${versionName}/${versionId}/notices${
                                        country ? `/${country}` : ''
                                    }`
                                )
                            }
                        >
                            {t('form-configurator:Back')}
                        </Button>
                        <Button
                            className="mr-4"
                            scheme="secondary"
                            onClick={onPreview}
                        >
                            {t('form-configurator:Preview')}
                        </Button>
                        <Button scheme="primary" onClick={onFinalize}>
                            {t('form-configurator:Finalize')}
                        </Button>
                    </div>
                    {validationErrors && (
                        <Drawer
                            drawerTitle={`Validation Errors (${
                                Object.keys(validationErrors).length
                            }):`}
                            isDrawerVisible={isDrawerVisible}
                            onCloseCallback={() =>
                                toggleDrawerVisibility(false)
                            }
                            ignoreOutsideClickClassName="ignore-outside-click"
                        >
                            <div className="flex flex-col justify-between">
                                <ul>
                                    {Object.entries(validationErrors).map(
                                        (key) => (
                                            <li
                                                className="p-3 mt-3 border border-alto"
                                                key={key[0]}
                                            >
                                                <span>
                                                    <b>{key[0]}</b>:{' '}
                                                    <span>
                                                        {t(key[1] ?? '')};{' '}
                                                    </span>
                                                </span>
                                            </li>
                                        )
                                    )}
                                </ul>
                                <div className="flex justify-end mt-10">
                                    <Button
                                        scheme="primary"
                                        onClick={previewWithErrors}
                                    >
                                        {t('form-configurator:Preview')}
                                    </Button>
                                </div>
                            </div>
                        </Drawer>
                    )}
                </div>
            )}
        </OktaAuthWrapper>
    );
};

export default FormDefinitionConfiguratorPage;
