import { Search32, LogoGithub32, Rocket32 } from '@carbon/icons-react';
import Breadcrumbs from '@mercell/breadcrumbs-react';
import Button from '@mercell/button-react';
import { InputContainer, Input, InputRightElement } from '@mercell/input-react';
import { Tabs, Tab } from '@mercell/tabs-react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { activateVersion } from '../../apiCalls/configurator/activateVersion';
import OktaAuthWrapper from '../../authorization/OktaAuthWrapper';
import ConfirmationModal from '../../components/ConfirmationModal';
import useFetchCodelists from '../../hooks/configuratorHooks/useFetchCodelists';
import useFetchFormDefinitions from '../../hooks/configuratorHooks/useFetchFormDefinitions';
import { ConfiguratorCodelist } from '../../types/generated/configuratorCodelist';
import { ConfiguratorFormDefinition } from '../../types/generated/configuratorFormDefinition';
import { ConfiguratorVersionActivation } from '../../types/generated/configuratorVersionActivation';
import PublicationModalContent from './PublicationModalContent';
import TabCodelists from './codelists/TabCodelists';
import TabNotices from './notices/TabNotices';
import { format } from 'date-fns';
import { exportVersion } from '../../apiCalls/configurator/exportVersion';
import { useStoreContext } from '../../state';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppSelector } from '../../redux/hooks';

const noticesTab = 'notices';
const codelistsTab = 'codelists';
let successMessage;

const PublishPage = () => {
    const { formConfiguratorLogoMef1798, useStoreRedux1860 } = useFlags();

    const [isPublicationModalVisible, setIsPublicationModalVisible] =
        useState<boolean>(false);
    const [isConfiramationModellVisible, setIsConfiramationModellVisible] =
        useState<boolean>(false);
    const [publicationDate, setPublicationDate] = useState<string>();
    const [isActivateLocally, setIsActivateLocally] = useState<boolean>(false);
    const [filteredDefinitionFields, setFilteredDefinitionFields] =
        useState<ConfiguratorFormDefinition[]>();
    const [filteredCodelists, setFilteredCodelists] =
        useState<ConfiguratorCodelist[]>();
    const { versionName, versionId, tabName, country } = useParams<{
        versionName: string;
        versionId: string;
        tabName: string;
        country: string;
    }>();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation([
        'form-configurator',
        'list.country',
        'list.legal-basis',
        'list.form-type',
        'list.notice-type',
    ]);

    const { formDefinitions, isLoadingFormDefinitions, setStateDefinitions } =
        useFetchFormDefinitions(versionId ?? '');
    const { codelists, isFetchingCodelists, setStateCodelists } =
        useFetchCodelists(versionId ?? '');

    const cannotPublish =
        !!formDefinitions?.find((fd) => fd.isFinalized === false) ||
        !!codelists?.find((cl) => cl.isFinalized === false);

    const openModal = () => setIsPublicationModalVisible(true);

    const closeModal = () => {
        setIsPublicationModalVisible(false);
    };

    const closeConfirmationModal = () => {
        setIsConfiramationModellVisible(false);
    };

    const onChangeDate = (date: Date) => {
        setPublicationDate(format(date, 'yyyy-MM-dd'));
    };

    const trriggerActivateLocally = () => {
        setIsActivateLocally(!isActivateLocally);
    };

    const {
        state: { UserRights },
    } = useStoreContext();

    const reduxUserRights = useAppSelector((state) => state.store.userRights);

    const onPublish = async () => {
        const payload: ConfiguratorVersionActivation = {
            versionId,
            country,
            isActivated: isActivateLocally,
            activationDate: publicationDate
                ? new Date(publicationDate)
                : undefined,
        };

        closeModal();
        activateVersion(payload, {
            onPendingText: t('toast-content:ToastActivateVersionPending'),
            onSuccessText: t('toast-content:ToastActivateVersionSuccess'),
        });
    };

    const onExportVersion = async () => {
        exportVersion(versionId ?? '');
    };

    const onChangeTab = () => {
        if (location.pathname.includes(noticesTab)) {
            navigate(location.pathname.replace(noticesTab, codelistsTab));
        } else {
            navigate(location.pathname.replace(codelistsTab, noticesTab));
        }
    };

    const onChangeFilter = (e: ChangeEvent<HTMLInputElement>) => {
        if (tabName === noticesTab) {
            const definitions = formDefinitions?.filter((definition) =>
                (
                    definition.noticeSubtype +
                    t(definition.directiveKey ?? '') +
                    t(definition.formTypeKey ?? '')
                )
                    .toLocaleLowerCase()
                    .includes(e.currentTarget.value.toLocaleLowerCase())
            );
            setFilteredDefinitionFields(definitions);
        }
        if (tabName === codelistsTab) {
            const newCodelists = codelists?.filter((cl) =>
                (cl.businessTermId + t(cl.name ?? ''))
                    .toLocaleLowerCase()
                    .includes(e.currentTarget.value.toLocaleLowerCase())
            );
            setFilteredCodelists(newCodelists);
        }
    };

    useEffect(() => {
        if (!isLoadingFormDefinitions && formDefinitions) {
            setFilteredDefinitionFields(formDefinitions);
            if (
                formDefinitions.length > 0 &&
                formDefinitions[0].activationDate
            ) {
                setPublicationDate(
                    format(
                        new Date(formDefinitions[0].activationDate),
                        'yyyy-MM-dd'
                    )
                );
            }
        }
        if (!isFetchingCodelists && codelists) {
            setFilteredCodelists(codelists);
            if (codelists.length > 0 && codelists[0].activationDate) {
                setPublicationDate(
                    format(new Date(codelists[0].activationDate), 'yyyy-MM-dd')
                );
            }
        }
    }, [
        isLoadingFormDefinitions,
        isFetchingCodelists,
        formDefinitions,
        codelists,
    ]);

    useEffect(
        () => () => {
            setStateDefinitions(undefined);
            setStateCodelists(undefined);
        },
        [setStateDefinitions, setStateCodelists, versionId]
    );

    if (publicationDate != null) {
        successMessage = t('form-configurator:Published_Schedule_Version');
    } else {
        successMessage = t('form-configurator:Published_Version');
    }
    return (
        <OktaAuthWrapper>
            <div className="col-span-full justify-self-center min-w-[50%] max-w-[100%]">
                <ConfirmationModal
                    isModalVisible={isPublicationModalVisible}
                    closeModal={closeModal}
                    onConfirm={onPublish}
                    confirmText={t('form-configurator:Confirm')}
                    cancelText={t('form-configurator:Cancel')}
                >
                    <PublicationModalContent
                        activateLocally={isActivateLocally}
                        currentDate={publicationDate}
                        onChangeDate={onChangeDate}
                        trriggerActivateLocally={trriggerActivateLocally}
                    />
                </ConfirmationModal>
                <ConfirmationModal
                    isModalVisible={isConfiramationModellVisible}
                    closeModal={closeConfirmationModal}
                    cancelText={t('form-configurator:Close')}
                >
                    <div>
                        <img
                            src="/images/success.svg"
                            className="w-1/3  m-auto mb-6"
                            alt={t('')}
                        />
                        <p>{successMessage}</p>
                    </div>
                </ConfirmationModal>
                <div className="mb-10">
                    <Breadcrumbs>
                        <Link to="/configurator">
                            {t('form-configurator:FormConfigurator')}
                        </Link>
                        <Link
                            to={window.location.pathname}
                            className="pointer-events-none"
                        >
                            {versionName} {t(country ?? '')}
                        </Link>
                    </Breadcrumbs>
                    <div className="text-small">
                        {formDefinitions &&
                            formDefinitions.length > 0 &&
                            formDefinitions[0].versionUniqueId &&
                            `UUID: ${formDefinitions[0].versionUniqueId}`}
                    </div>
                </div>
                <Tabs activeKey={tabName ?? ''} onTabChange={onChangeTab}>
                    <Tab
                        tabKey={noticesTab}
                        title={t('form-configurator:Notices')}
                    />

                    <Tab
                        tabKey={codelistsTab}
                        title={t('form-configurator:Codelists')}
                    />
                </Tabs>
                <div className="p-3 flex justify-between">
                    <div className="mb-10 w-5/12">
                        <InputContainer>
                            <Input
                                placeholder={t('form-configurator:Search')}
                                onChange={onChangeFilter}
                            />
                            <InputRightElement>
                                <Search32 />
                            </InputRightElement>
                        </InputContainer>
                    </div>
                    {(useStoreRedux1860
                        ? reduxUserRights?.importVersionEnabled === true
                        : UserRights?.importVersionEnabled === true) && (
                        <div>
                            <Button
                                iconSettings={{ Icon: LogoGithub32 }}
                                scheme="secondary"
                                onClick={onExportVersion}
                                disabled={cannotPublish}
                            >
                                <b>{t('form-configurator:SaveVersion')}</b>
                            </Button>
                        </div>
                    )}
                    <div>
                        <Button
                            iconSettings={{ Icon: Rocket32 }}
                            scheme="primary"
                            onClick={openModal}
                            disabled={cannotPublish}
                        >
                            <b>{t('form-configurator:PublishVersion')}</b>
                        </Button>
                    </div>
                </div>
                {tabName === noticesTab && (
                    <TabNotices
                        definitionFields={filteredDefinitionFields}
                        t={t}
                    />
                )}
                {tabName === codelistsTab && (
                    <TabCodelists codelists={filteredCodelists} t={t} />
                )}
                <div className="flex justify-end my-12">
                    <Button
                        scheme="secondary"
                        onClick={() => navigate('/configurator')}
                    >
                        {t('form-configurator:Back')}
                    </Button>
                </div>
            </div>
        </OktaAuthWrapper>
    );
};

export default PublishPage;
