import axios, { AxiosResponse } from 'axios';
import {
    FormMenuResult,
    FormStatus,
} from '../../types/generated/formMenuResult';
import {
    setFormLanguage,
    getFormLanguage,
    setFormAdditionalLanguages,
} from '../../shared/storageService/sessionService';

import useSWR from 'swr';

const fetcher = (url: string) =>
    axios.get(url).then((res: AxiosResponse<FormMenuResult>) => {
        const { mainLanguage, additionalLanguages } = res.data;
        setFormLanguage(mainLanguage);
        setFormAdditionalLanguages(additionalLanguages ?? []);
        return res.data;
    });

const useFetchSections = (
    formId?: string,
    sectionPath?: string,
    useRevalidateOnFocus1734?: boolean
) => {
    const language = getFormLanguage();
    const { data, error, mutate } = useSWR(
        !formId
            ? null
            : `form/menu/${formId}/${language || '-'}${
                  sectionPath ? `/${sectionPath}` : ''
              }`,
        fetcher,
        {
            shouldRetryOnError: false,
            revalidateIfStale: true,
            revalidateOnFocus: useRevalidateOnFocus1734,
            refreshInterval(latestData) {
                if (
                    latestData?.formStatus ===
                    FormStatus.DispatchedForTranslation
                )
                    return 15000;

                return 0;
            },
        }
    );

    return {
        AdditionalLanguages: data?.additionalLanguages,
        FormMainLanguage: data?.formMainLanguage,
        MainLanguage: data?.mainLanguage,
        sections: data?.menuItems,
        navigationTitle: data?.navigationTitle,
        parentElementTitleKey: data?.parentElementTitleKey,
        isFormPreview: data?.isFormPreview ?? false,
        isLoadingSections: formId && !error && !data,
        isErrorFetchingSections: error,
        currentFormStatus: data?.formStatus,
        setHotReloadOfMenu: mutate,
    };
};
export default useFetchSections;
