import { Country } from '@mercell/language-selector-react';

const COUNTRIES: Country[] = [
    {
        name: 'Balgariya',
        domain: 'bg',
        locale: 'bg',
        language: 'Bălgarski',
        phonePrefix: '+ 359',
    },
    {
        name: 'Danmark',
        domain: 'dk',
        locale: 'da',
        language: 'Dansk',
        phonePrefix: '+ 45',
    },
    {
        name: 'Deutschland',
        domain: 'de',
        locale: 'de',
        language: 'Deutsch',
        phonePrefix: '+ 49',
    },
    {
        name: 'Eesti',
        domain: 'ee',
        locale: 'et',
        language: 'Eesti',
        phonePrefix: '+ 372',
    },
    {
        name: 'España',
        domain: 'es',
        locale: 'es',
        language: 'Español',
        phonePrefix: '+ 34',
    },
    {
        name: 'Éire',
        domain: 'ie',
        locale: 'ga',
        language: 'Gaeilge',
        phonePrefix: '+ 353',
    },
    {
        name: 'France',
        domain: 'fr',
        locale: 'fr',
        language: 'Français',
        phonePrefix: '+ 33',
    },
    {
        name: 'Great Britain',
        domain: 'gb',
        locale: 'en',
        language: 'English',
        phonePrefix: '+ 44',
    },
    {
        name: 'Ísland',
        domain: 'is',
        locale: 'is',
        language: 'Íslenska',
        phonePrefix: '+ 354',
    },
    {
        name: 'Latvija',
        domain: 'lv',
        locale: 'lv',
        language: 'Latviešu',
        phonePrefix: '+ 371',
    },
    {
        name: 'Lietuva',
        domain: 'lt',
        locale: 'lt',
        language: 'Lietuvių',
        phonePrefix: '+ 370',
    },
    {
        name: 'Nederland',
        domain: 'nl',
        locale: 'nl',
        language: 'Nederlands',
        phonePrefix: '+ 31',
    },
    {
        name: 'Norge (Bokmål)',
        domain: 'no',
        locale: 'nb',
        language: 'Norsk Bokmål',
        phonePrefix: '+ 47',
    },
    {
        name: 'Norge (Nynorsk)',
        domain: 'no',
        locale: 'nn',
        language: 'Norsk Nynorsk',
        phonePrefix: '+ 47',
    },
    {
        name: 'Polska',
        domain: 'pl',
        locale: 'pl',
        language: 'Polski',
        phonePrefix: '+ 48',
    },
    {
        name: 'Rossiya',
        domain: 'ru',
        locale: 'ru',
        language: 'Russkij',
        phonePrefix: '+ 7',
    },
    {
        name: 'Suomi',
        domain: 'fi',
        locale: 'fi',
        language: 'Suomi',
        phonePrefix: '+ 358',
    },
    {
        name: 'Sverige',
        domain: 'se',
        locale: 'sv',
        language: 'Svenska',
        phonePrefix: '+ 46',
    },
    {
        name: 'Sverige (Suomi)',
        domain: 'svfi',
        locale: 'sv-FI',
        language: 'Finlandssvenska',
        phonePrefix: '+ 358',
    },
];

export default COUNTRIES;
