import { ReadOnlyFieldStructure } from '@mercell/form-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PreviewElementStyle } from '../shared/getStyleClasses';
import { parseDefinitionType } from '../shared/parseDefinitionType';
import { prepareContent } from '../shared/prepareContent';
import { DefinitionElementType } from '../types/generated/formDefinitionBlockItem';
import { FieldLabel } from './FieldLabel';
import { useStoreContext } from '../state';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppSelector } from '../redux/hooks';

interface ChangeValueItemDataInterface {
    Value: string;
    TranslationFileName: string;
    InitialFieldType: DefinitionElementType;
    IsMultiSelect?: boolean;
}

export const ChangeValueComponent = (props: any) => {
    let change: ChangeValueItemDataInterface = {
        Value: '',
        TranslationFileName: '',
        InitialFieldType: DefinitionElementType.Text,
    };

    change = props.input.value;

    const { t } = useTranslation(change.TranslationFileName ?? '');
    const { useDateRange1736, useStoreRedux1860 } = useFlags();
    const {
        state: { dateFormat },
    } = useStoreContext();

    const reduxDateFormat = useAppSelector((state) => state.store.dateFormat);

    const readOnlyProperties: ReadOnlyFieldStructure = {
        isSortable: true,
        isMultiSelect: change.IsMultiSelect,
        showAsLabel: false,
        translationFileName: change.TranslationFileName,
        options: [],
        type: 'readOnly',
        showWithTime: change.InitialFieldType === DefinitionElementType.Time,
    };

    const { bugfixMef1809 } = useFlags();

    const value = prepareContent(
        change.Value,
        parseDefinitionType(change.InitialFieldType),
        readOnlyProperties,
        '',
        t,
        useStoreRedux1860 ? reduxDateFormat : dateFormat,
        props.locale,
        bugfixMef1809,
        useDateRange1736
    );

    return (
        <div className={props.fieldWrapperProperties.className}>
            <FieldLabel
                name={props.input.name}
                labelProperties={{ className: 'mb-2' }}
                content={
                    <span className={props.labelProperties}>
                        {t(props.label)}
                    </span>
                }
                tooltipWrapperProperties={{}}
                showRequiredIndicator={false}
                isFormPreview
            />
            <div className={PreviewElementStyle}>{value}</div>
        </div>
    );
};
