import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AutoSaveStatus } from '../state/autoSaveStatus';
import { DateFns, DateFormatsNew } from '../shared/dateFnsFormats';
import { NoticeStatus } from '../state/noticeStatus';
import { UserRightsResult } from '../types/generated/userRightsResult';
import { FormMenuItem } from '../types/generated/formMenuResult';
import roles from '../state/roles';

interface InitialStateType {
    autoSave: AutoSaveStatus;
    userName: string | undefined;
    userId: string | undefined;
    userRole: roles | undefined;
    role: string;
    platformName: string | undefined;
    menuSections: FormMenuItem[];
    tenderName: string | undefined;
    locale?: Locale;
    userRights?: UserRightsResult;
    noticeStatus: NoticeStatus | undefined;
    dateFormat: DateFns;
}

const initialState: InitialStateType = {
    autoSave: { isSaved: false, isError: false, isLoading: false },
    userName: undefined,
    userId: undefined,
    userRole: roles.unauthorized,
    role: 'SOME ROLE',
    platformName: undefined,
    menuSections: [],
    tenderName: undefined,
    locale: undefined,
    userRights: undefined,
    noticeStatus: undefined,
    dateFormat: DateFormatsNew,
};

export const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        resetAutoSave: (state) => {
            state.autoSave = {
                isLoading: false,
                isError: false,
                isSaved: false,
            };
        },
        setAutoSaveLoading: (state) => {
            state.autoSave = {
                isLoading: true,
                isError: false,
                isSaved: false,
            };
        },
        setaAutoSaveSaved: (state) => {
            state.autoSave = {
                isLoading: false,
                isError: false,
                isSaved: true,
            };
        },
        setaAutoSaveError: (state) => {
            state.autoSave = {
                isLoading: false,
                isError: true,
                isSaved: false,
            };
        },
        changeUser: (state, action: PayloadAction<string>) => {
            state.userId = action.payload;
        },
        changeUsername: (state, action: PayloadAction<string>) => {
            state.userName = action.payload;
        },
        setUserRole: (state, action: PayloadAction<roles>) => {
            state.userRole = action.payload;
        },
        setPlatformName: (state, action: PayloadAction<string>) => {
            state.platformName = action.payload;
        },
        changeMenuSections: (state, action: PayloadAction<FormMenuItem[]>) => {
            state.menuSections = action.payload;
        },
        setTenderName: (state, action: PayloadAction<string>) => {
            state.tenderName = action.payload;
        },
        setLocale: (state, action: PayloadAction<Locale>) => {
            state.locale = action.payload;
        },
        setUserRights: (state, action: PayloadAction<UserRightsResult>) => {
            state.userRights = action.payload;
        },
        setNoticeStatus: (state, action: PayloadAction<NoticeStatus>) => {
            state.noticeStatus = action.payload;
        },
    },
});

export const {
    setAutoSaveLoading,
    setaAutoSaveSaved,
    setaAutoSaveError,
    setLocale,
    setNoticeStatus,
    setPlatformName,
    setTenderName,
    setUserRights,
    setUserRole,
    changeMenuSections,
    changeUser,
    changeUsername,
    resetAutoSave,
} = storeSlice.actions;

export default storeSlice.reducer;
