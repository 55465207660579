import React, { FC, useMemo, useState } from 'react';
import {
    ErrorObjectWithPaths,
    ValidationErrorObject,
} from '../../../types/validationError';
import { TFunction, Trans } from 'react-i18next';
import { ValidationCheckboxList } from './ValidationCheckboxList';
import { InformationFilled24 } from '@carbon/icons-react';
import { useLocation, useParams } from 'react-router-dom';
import LoadingSpinner from '@mercell/loading-spinner-react';
import Button from '@mercell/button-react';
import { KeyedMutator } from 'swr';
import cx from 'classnames';
import { ValidateResponse } from '../../../shared/validationModule/useValidate';
import { useStoreContext } from '../../../state';
import { translateForm } from '../../../apiCalls/translateForm';
import { AnimatedSaveProgress } from '../../../components/AnimatedSaveProgress';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppSelector } from '../../../redux/hooks';

interface ValidationDrawerContentInterface {
    backendValidationErrors: ValidationErrorObject;
    t: TFunction<string, unknown>;
    errorKeys: string[];
    validateMethod: KeyedMutator<ValidateResponse>;
    isLoadingFetchingValidations: boolean;
    isErrorFetchingValidations?: any;
    setShouldFetchValidation: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ValidationDrawerContent: FC<ValidationDrawerContentInterface> = ({
    backendValidationErrors,
    t,
    errorKeys,
    validateMethod,
    isLoadingFetchingValidations,
    isErrorFetchingValidations,
    setShouldFetchValidation,
}) => {
    const { useStoreRedux1860 } = useFlags();
    const {
        state: { noticeStatus, autoSave },
    } = useStoreContext();

    const reduxAutoSave = useAppSelector((state) => state.store.autoSave);
    const reduxNoticeStatus = useAppSelector(
        (state) => state.store.noticeStatus
    );
    const { pathname } = useLocation();
    const [isShowErrorForSectionEnable, toggleShowErrors] =
        useState<boolean>(false);
    const [fixedErrorList, setFixedErrorList] = useState<string[]>([]);
    const onChangeHandler = () => toggleShowErrors((current) => !current);
    const { errorObjectWithPaths, isFormValidatedWithoutErrors } =
        backendValidationErrors;
    const [isErrorTranslating, setIsErrorTranslating] = useState<any>();

    const isTechnicalLot = !errorKeys.includes('LotsSection');

    const arrayOfErrorObjectWithPaths = useMemo(() => {
        setFixedErrorList([]);
        return errorKeys.reduce(
            (acc: ErrorObjectWithPaths[], key) => [
                ...acc,
                ...errorObjectWithPaths[key],
            ],
            []
        );
    }, [errorKeys, errorObjectWithPaths]);
    let errorListToShow;
    let sectionFixedErrorList;
    let sectionErrors;
    if (isShowErrorForSectionEnable) {
        errorListToShow = arrayOfErrorObjectWithPaths.filter((errorObject) => {
            const { urlPath } = errorObject;
            return urlPath === pathname;
        });
        sectionFixedErrorList = fixedErrorList.filter(
            (error) => error.split('_')[0] === pathname
        );
        sectionErrors = arrayOfErrorObjectWithPaths.filter(
            (error) => error.urlPath === pathname
        );
    } else {
        errorListToShow = arrayOfErrorObjectWithPaths;
    }

    let allErrorsChecked;
    if (sectionErrors && sectionFixedErrorList && sectionErrors.length > 0) {
        allErrorsChecked =
            sectionErrors.length === sectionFixedErrorList.length;
    } else {
        allErrorsChecked = !!(
            arrayOfErrorObjectWithPaths.length > 0 &&
            fixedErrorList.length === arrayOfErrorObjectWithPaths.length
        );
    }
    const { formId } = useParams();

    const isFormReadyForTranslation = useStoreRedux1860
        ? reduxNoticeStatus?.value === 105
        : noticeStatus?.value === 105;
    const isFormDraft = useStoreRedux1860
        ? reduxNoticeStatus?.value === 0
        : noticeStatus?.value === 0;

    return (
        <div className="h-[calc(100vh-150px)] flex flex-col">
            <div
                className={cx(
                    isLoadingFetchingValidations
                        ? 'min-h-[100%] max-h-[100%] flex justify-center items-center'
                        : 'min-h-[85%] max-h-[85%]',
                    allErrorsChecked && 'min-h-[70%] max-h-[70%]'
                )}
            >
                {/* Loading Data */}
                {isLoadingFetchingValidations && (
                    <div className="flex flex-col justify-center items-center gap-y-12">
                        <LoadingSpinner />
                        {isLoadingFetchingValidations && (
                            <p className="text-studio font-semibold w-[70%] text-center">
                                {t('form-content:ValidatingNoticeHold')}
                            </p>
                        )}
                    </div>
                )}

                {/* Validation Call Error Awaiting General Exception Handling */}
                {((!isLoadingFetchingValidations &&
                    isErrorFetchingValidations) ||
                    isErrorTranslating) && (
                    <div className=" flex flex-col justify-center h-[100%]">
                        <div className="p-10 m-6 lg:m-8 md:m-16 2xl:m-2">
                            <img
                                src="/images/not-found.svg"
                                className="w-2/3  m-auto mb-6"
                                alt={t('error' ?? '')}
                            />
                            <p className="text-h2 font-semibold text-center pt-14">
                                {t('form-content:ExperiencedError')}
                            </p>

                            <div className="text-body text-center text-h2 font-semibold text-center pt-14">
                                <p>
                                    {isErrorFetchingValidations
                                        ? t(
                                              `form-content:${isErrorFetchingValidations.data?.message}`
                                          )
                                        : t(
                                              `form-content:${isErrorTranslating.data?.message}`
                                          )}
                                </p>
                                <p className="pt-4">
                                    {isErrorFetchingValidations
                                        ? t(
                                              `form-content:${isErrorFetchingValidations.data?.errorId}`
                                          )
                                        : t(
                                              `form-content:${isErrorTranslating.data?.errorId}`
                                          )}
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                {/* Form Validated */}
                {!isLoadingFetchingValidations &&
                    !isErrorFetchingValidations &&
                    isFormValidatedWithoutErrors && (
                        <div className=" flex flex-col justify-center h-[100%] overflow-auto">
                            <div className="p-10 m-6 lg:m-8 md:m-16 2xl:m-2">
                                <img
                                    src="/images/success.svg"
                                    className="w-2/3  m-auto mb-6"
                                    alt={t('success' ?? '')}
                                />
                                <p className="text-h2 font-semibold text-center pt-14">
                                    {isFormReadyForTranslation && !isFormDraft
                                        ? t(
                                              'form-content:NoticeReadyForTranslations'
                                          )
                                        : t('form-content:NoMoreTasks')}
                                </p>
                                <div className="pt-14">
                                    <p className="text-body text-center">
                                        {t('form-content:AllErrorsFixed')}{' '}
                                        <strong>
                                            {t(
                                                'form-content:AllErrorsFixedNote'
                                            )}
                                        </strong>
                                    </p>
                                </div>
                                <p className="text-body text-center pt-14">
                                    {isFormReadyForTranslation && !isFormDraft
                                        ? t(
                                              'form-content:NoticeCompleteGetTranslations'
                                          )
                                        : t(
                                              'form-content:AllErrorsFixedCloseTab'
                                          )}
                                </p>
                            </div>
                        </div>
                    )}

                {/* Pre-validate tooltip OR Validation Errors Checklist */}
                {!isLoadingFetchingValidations && !isErrorFetchingValidations && (
                    <div className="h-full">
                        {arrayOfErrorObjectWithPaths.length === 0 &&
                        !isFormValidatedWithoutErrors ? (
                            <div className="flex flex-col justify-center h-[100%] overflow-auto">
                                <div className="p-10  m-6 lg:m-8 md:m-16 2xl:m-2">
                                    <img
                                        src="/images/validate-update.svg"
                                        className="w-2/3  m-auto mb-6"
                                        alt={t('success' ?? '')}
                                    />
                                    <div className="text-body p-10 rounded-default text-center bg-athens">
                                        <div>
                                            {t('form-content:ValidateTooltip')}
                                        </div>
                                        <div className="pt-5">
                                            <Trans
                                                i18nKey={t(
                                                    'form-content:ValidateTooltipButton'
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <img
                                        src="/images/arrow-down.svg"
                                        className="m-auto pt-10"
                                        alt={t('success' ?? '')}
                                    />
                                </div>
                            </div>
                        ) : (
                            <ValidationCheckboxList
                                isTechnicalLot={isTechnicalLot}
                                validateMethod={validateMethod}
                                fixedErrorList={fixedErrorList}
                                setFixedErrorList={setFixedErrorList}
                                errorListToShow={errorListToShow}
                                t={t}
                                arrayOfErrorObjectWithPaths={
                                    arrayOfErrorObjectWithPaths
                                }
                                isShowErrorForSectionEnable={
                                    isShowErrorForSectionEnable
                                }
                                onChangeHandler={onChangeHandler}
                                sectionFixedErrorList={sectionFixedErrorList}
                                sectionErrors={sectionErrors}
                                isLoadingFetchingValidations={
                                    isLoadingFetchingValidations
                                }
                            />
                        )}
                    </div>
                )}
            </div>

            {/* Validate Button footer  */}
            <div
                className={cx(
                    'border-t border-alto flex flex-col justify-center items-center z-[40]',
                    isLoadingFetchingValidations
                        ? 'h-0 max-h-0 min-h-0 hidden'
                        : 'h-[15%] max-h-[15%] min-h-[15%]',
                    allErrorsChecked &&
                        'h-[30%] max-h-[30%] min-h-[30%] bg-magnolia'
                )}
            >
                {allErrorsChecked && (
                    <div className=" w-[83%] inline-flex items-center mb-4">
                        <InformationFilled24 className="mr-1 text-minsk" />
                        <p className="font-semibold">
                            {t('form-content:ValidateToUpdate')}
                        </p>
                    </div>
                )}

                {allErrorsChecked && (
                    <p className="text-center w-[70%] mb-6">
                        {t('form-content:ValidateActionsDone')}
                    </p>
                )}
                {(useStoreRedux1860
                    ? reduxAutoSave && reduxAutoSave.isLoading
                    : autoSave && autoSave.isLoading) && (
                    <Button
                        scheme="primary"
                        disabled
                        className="min-w-[80%] mb-4"
                        iconSettings={{
                            iconSide: 'left',
                            Icon: AnimatedSaveProgress,
                        }}
                    >
                        {t('form-content:SavingChanges')}
                    </Button>
                )}
                {(useStoreRedux1860
                    ? !reduxAutoSave.isLoading
                    : !autoSave.isLoading) && (
                    <Button
                        scheme="primary"
                        className="min-w-[80%] mb-4"
                        onClick={() => {
                            if (
                                isFormReadyForTranslation &&
                                !isFormDraft &&
                                isFormValidatedWithoutErrors
                            ) {
                                translateForm(
                                    formId,
                                    undefined,
                                    setIsErrorTranslating,
                                    true
                                ).then(() => {
                                    setShouldFetchValidation(true);
                                    validateMethod();
                                });
                            } else {
                                setIsErrorTranslating(false);
                                setShouldFetchValidation(true);
                                validateMethod();
                            }
                        }}
                    >
                        {isFormReadyForTranslation &&
                        !isFormDraft &&
                        isFormValidatedWithoutErrors
                            ? t('form-content:GetTranslations')
                            : t('form-content:ValidateNoticeTip')}
                    </Button>
                )}
            </div>
        </div>
    );
};
