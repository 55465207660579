import axios from 'axios';
import { FormArrayItem } from '../types/generated/formArrayItem';

export const copyLot = (
    formId: string,
    lotElement: { uniqueId: string },
    lotUniqueIds: any,
    toastText?: {
        onSuccessText?: string;
        onPendingText?: string;
    }
) =>
    axios
        .post(
            `array/${formId}/copy/lot/${lotElement?.uniqueId}`,
            lotUniqueIds,
            {
                headers: {
                    'Toast-Pending-Text': toastText?.onPendingText,
                    'Toast-Success-Text': toastText?.onSuccessText,
                },
            }
        )
        .then((res) => res.status === 200)
        .catch((error) => {
            throw error;
        });

export const deleteArrayList = (
    formId: string,
    language: string | undefined,
    sectionPath: string | undefined,
    arrayPath: string,
    arrayElement: FormArrayItem,
    toastText?: {
        onSuccessText?: string;
        onPendingText?: string;
    }
) =>
    axios
        .delete(
            `array/delete/${formId}/${language}/${sectionPath}/${arrayPath}${
                arrayElement.uniqueId ? `/${arrayElement.uniqueId}` : ''
            }`,
            {
                headers: {
                    'Toast-Pending-Text': toastText?.onPendingText,
                    'Toast-Success-Text': toastText?.onSuccessText,
                },
            }
        )
        .then((res) => res.status === 200)
        .catch((error) => {
            throw error;
        });
