import React, { FC, useEffect, useState } from 'react';
import cx from 'classnames';
import useFetchReviewTranslations from '../../hooks/formHooks/useFetchReviewTranlations';
import LoadingSpinner from '@mercell/loading-spinner-react';
import { Input } from '@mercell/input-react';
import {
    FormText,
    FormTextsResult,
} from '../../types/generated/formTextsResult';
import { TFunction } from 'react-i18next';
import { Textarea } from '@mercell/textarea-react';
import Button from '@mercell/button-react';
import { TooltipWrapper } from '../../components/TooltipWrapper';
import NotFoundPage from '../NotFound/NotFoundPage';
import FieldError from '@mercell/field-error-react';
import {
    Form,
    Field,
    FieldMetaState,
    FieldInputProps as FieldInputState,
} from 'react-final-form';
import { useSubmitReviewTranslations } from '../../hooks/formHooks/useSubmitReviewTranlations';
import AutoSave from '../../shared/utilities/AutoSave';
import ConfirmationModal from '../../components/ConfirmationModal';
import { SubmissionErrors } from 'final-form';
import { useStoreContext } from '../../state';
import { FormStatus } from '../../types/generated/formMenuResult';
import { ValidationErrorObject } from '../../types/validationError';
import { AutoSaveStatus } from '../../state/autoSaveStatus';
import { ValidateResponse } from '../../shared/validationModule/useValidate';
import { KeyedMutator } from 'swr';
import { useAppDispatch } from '../../redux/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { setNoticeStatus } from '../../redux/storeSlice';

interface FieldInputProps {
    readOnly?: boolean;
    item: FormText;
    t: TFunction<string, unknown>;
    lockFields: boolean;
    required?: boolean;
    input: FieldInputState<any, HTMLElement>;
    meta: FieldMetaState<any>;
}
const FormTranslationReviewPage: FC<{
    formId?: string;
    t: TFunction<string, unknown>;
    isFormPreview: boolean;
    firstLanguage: string;
    secondLanguage: string;
    validateMethod: KeyedMutator<ValidateResponse>;
    backendValidationErrors: ValidationErrorObject;
    isLoadingFetchingValidations: boolean;
}> = ({
    formId,
    t,
    isFormPreview,
    firstLanguage,
    secondLanguage,
    validateMethod,
    backendValidationErrors,
    isLoadingFetchingValidations,
}) => {
    const { useStoreRedux1860 } = useFlags();
    const [lockFields, setLockFields] = useState<boolean>(true);
    const [translationsApproved, setTranslationsApproved] = useState<
        boolean | undefined
    >(undefined);
    const [isLoadingTranslationsApproval, setIsLoadingTranslationsApproval] =
        useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isValidated, setIsValidated] = useState<boolean | undefined>(
        undefined
    );

    const { dispatch } = useStoreContext();
    const reduxDispatch = useAppDispatch();
    const { isFormValidatedWithoutErrors } = backendValidationErrors;
    const { translations, isLoadingTranslations } = useFetchReviewTranslations(
        formId,
        firstLanguage,
        secondLanguage
    );
    const { onSubmit } = useSubmitReviewTranslations({
        formId,
        setTranslationsApproved,
        reviewedLanguage: secondLanguage,
        setIsLoadingTranslationsApproval,
        useStoreRedux1860,
    });

    useEffect(() => {
        if (!isLoadingFetchingValidations && isValidated) {
            const noticeStatusDraftsPayload = {
                value: 0,
                status: t(`list.audit-log-status:${FormStatus[0]}`),
            };
            const noticeStatusReadyForPublicationPayload = {
                value: 20,
                status: t(`list.audit-log-status:${FormStatus[20]}`),
            };

            if (isFormValidatedWithoutErrors) {
                if (useStoreRedux1860) {
                    reduxDispatch(
                        setNoticeStatus(noticeStatusReadyForPublicationPayload)
                    );
                } else {
                    dispatch({
                        type: 'SET_NOTICE_STATUS',
                        payload: {
                            noticeStatus:
                                noticeStatusReadyForPublicationPayload,
                        },
                    });
                }
                setIsValidated(true);
            } else {
                if (useStoreRedux1860) {
                    reduxDispatch(setNoticeStatus(noticeStatusDraftsPayload));
                } else {
                    dispatch({
                        type: 'SET_NOTICE_STATUS',
                        payload: {
                            noticeStatus: noticeStatusDraftsPayload,
                        },
                    });
                }
                dispatch({
                    type: 'SET_NOTICE_STATUS',
                    payload: {
                        noticeStatus: noticeStatusDraftsPayload,
                    },
                });
                setIsValidated(false);
            }
        }
        if (
            !isLoadingTranslationsApproval &&
            translationsApproved &&
            !isValidated
        ) {
            const noticeStatusTranslationApprovedPayload = {
                value: 110,
                status: t(`list.audit-log-status:TranslationApproved`),
            };
            if (useStoreRedux1860) {
                reduxDispatch(
                    setNoticeStatus(noticeStatusTranslationApprovedPayload)
                );
            } else {
                dispatch({
                    type: 'SET_NOTICE_STATUS',
                    payload: {
                        noticeStatus: noticeStatusTranslationApprovedPayload,
                    },
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isFormValidatedWithoutErrors,
        isLoadingFetchingValidations,
        translationsApproved,
    ]);

    if (
        isLoadingTranslations ||
        !translations ||
        isLoadingFetchingValidations ||
        isLoadingTranslationsApproval
    ) {
        return (
            <div className="col-span-full h-full flex flex-col items-center justify-center gap-y-12">
                <LoadingSpinner />
                {isLoadingFetchingValidations && (
                    <p className="text-studio font-semibold w-[30%] text-center">
                        {t('form-content:ValidatingNoticeHold')}
                    </p>
                )}
            </div>
        );
    }

    const { texts } = translations;
    if (!texts || texts.length < 1) {
        return <NotFoundPage />;
    }

    const onAutoSave = (values: any) => {
        onSubmit(values, true);
    };

    const findErrorElement = (obj: any, path = ''): string | undefined => {
        if (obj === null || obj === undefined) {
            return;
        }

        if (typeof obj === 'object') {
            for (const key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    const newPath = path === '' ? key : `${path}.${key}`;
                    const result = findErrorElement(obj[key], newPath);
                    if (result !== undefined) {
                        return result;
                    }
                }
            }
        }

        return path.replace(/\.\d+/g, (match) => `[${match.substr(1)}]`);
    };

    const scrollToErrorField = (errors: FormTextsResult) => {
        let result = findErrorElement(errors);
        result = result?.slice(0, result.lastIndexOf('['));
        const errorElement = document.querySelector(
            `[name="${result}"]`
        ) as HTMLElement;

        let elementPosition;
        if (errorElement) {
            elementPosition =
                errorElement.getBoundingClientRect().top + window.scrollY - 150;
        }

        if (errorElement && !lockFields) {
            window.scrollTo({
                top: elementPosition,
                behavior: 'smooth',
            });
            errorElement.focus();
        }
    };

    const renderTextFields = (formTexts: FormText[]) => {
        const field1Elements = formTexts.map((text, index) => {
            const isMainLanguageValue =
                texts[index].lingualTexts[0].language === firstLanguage;

            const field1Name = isMainLanguageValue
                ? `texts[${index}].lingualTexts[0].value`
                : `texts[${index}].lingualTexts[1].value`;

            return (
                <Field
                    name={field1Name}
                    render={({ input, meta }) => (
                        <FieldInput
                            input={input}
                            meta={meta}
                            readOnly
                            item={text}
                            t={t}
                            lockFields={lockFields}
                        />
                    )}
                />
            );
        });
        const field2Elements = formTexts.map((text, index) => {
            const isSecondLanguage =
                texts[index].lingualTexts[0].language === secondLanguage;

            const field2Name = isSecondLanguage
                ? `texts[${index}].lingualTexts[0].value`
                : `texts[${index}].lingualTexts[1].value`;
            const field2Language = `texts[${index}].lingualTexts[1].language`;
            const field2MaxLength = text.maxLength;
            return (
                <>
                    <Field
                        name={field2Language}
                        component="input"
                        type="hidden"
                        initialValue={
                            text.lingualTexts[1]?.language ?? secondLanguage
                        }
                    />
                    <Field
                        name={field2Name}
                        validate={(value) =>
                            // eslint-disable-next-line no-nested-ternary
                            value
                                ? field2MaxLength &&
                                  value.length > field2MaxLength
                                    ? t('form-content:ERROR_MAXIMUM_LENGTH', {
                                          1: field2MaxLength,
                                      })
                                    : undefined
                                : [t('form-content:ERROR_VALUE_IS_REQUIRED')]
                        }
                        render={({ input, meta }) => (
                            <FieldInput
                                input={input}
                                meta={meta}
                                item={text}
                                t={t}
                                lockFields={lockFields}
                                required
                            />
                        )}
                    />
                </>
            );
        });

        return { field1Elements, field2Elements };
    };

    if (isValidated !== undefined) {
        return (
            <div className="col-span-full h-full flex justify-center items-center">
                <div className=" flex flex-col justify-center items-center">
                    <div className="p-10 m-6 2xl:m-2">
                        <img
                            src={
                                isValidated
                                    ? '/images/success.svg'
                                    : '/images/not-found.svg'
                            }
                            className="w-2/3 max-w-[400px] m-auto mb-6"
                            alt={t('success' ?? '')}
                        />
                        <p className="text-h2 font-semibold text-center pt-8">
                            {isValidated
                                ? t('form-content:ValidationSuccessful')
                                : t('form-content:ValidationFailed')}
                        </p>
                        {isValidated && (
                            <div className="pt-4">
                                <p className="text-body text-center">
                                    {t('form-content:CloseTabContinue')}
                                </p>
                            </div>
                        )}
                        {!isValidated && (
                            <Button
                                scheme="secondary"
                                className="mx-auto mt-6 mb-6"
                                onClick={() => window.location.reload()}
                            >
                                {t('form-content:NoticeEditor')}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    if (translationsApproved !== undefined) {
        return (
            <div className="col-span-full h-full flex justify-center items-center">
                <div className=" flex flex-col justify-center items-center">
                    <div className="p-10 m-6 2xl:m-2">
                        <img
                            src={
                                translationsApproved
                                    ? '/images/success.svg'
                                    : '/images/not-found.svg'
                            }
                            className="w-2/3  m-auto mb-6"
                            alt={t('success' ?? '')}
                        />
                        <p className="text-h2 font-semibold text-center pt-8">
                            {translationsApproved
                                ? t('form-content:TranslationsApproved')
                                : t('form-content:TranslationsFailed')}
                        </p>
                        <div className="pt-4">
                            <p className="text-body text-center">
                                {translationsApproved
                                    ? t('form-content:TranslationsApprovedTip')
                                    : t('form-content:TranslationsFailedTip')}
                            </p>
                        </div>
                        {translationsApproved && (
                            <Button
                                scheme="secondary"
                                className="mx-auto mt-6 mb-6"
                                onClick={() => {
                                    validateMethod();
                                    setIsValidated(true);
                                }}
                            >
                                {t('form-content:ValidateNotice')}
                            </Button>
                        )}
                        {!translationsApproved && (
                            <Button
                                scheme="secondary"
                                className="mx-auto mt-6 mb-6"
                                onClick={() =>
                                    setTranslationsApproved(undefined)
                                }
                            >
                                {t('form-content:ReviewTranslations')}
                            </Button>
                        )}
                        <button
                            type="button"
                            className="text-body fixed bottom-4 right-4"
                            onClick={async () => {
                                window.location.reload();
                            }}
                        >
                            {t('form-content:ReturnNoticeEditor')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    const { field1Elements, field2Elements } = renderTextFields(texts);
    return (
        <Form
            initialValues={{ texts }}
            onSubmit={(data: any) => onSubmit(data, false)}
            render={({ handleSubmit, form, errors }) => (
                <>
                    <AutoSave
                        onAutoSave={onAutoSave}
                        autoSaveThrottleDelay={1000}
                    />
                    <form
                        className="flex col-span-full rounded-default justify-center pb-[140px]"
                        id="translationSubmitForm"
                        onSubmit={handleSubmit}
                    >
                        <div className="overflow-auto rounded-default flex flex-col min-w-[70%] mt-8 mx-6">
                            <h1>{t('form-content:ReviewTranslations')}</h1>
                            <div className="grid grid-cols-2 mt-14 gap-x-5">
                                <div className="col-span-1  rounded-default text-16">
                                    <h2 className="border-l-8 border-alto pl-6">
                                        {t('form-content:Original')}
                                    </h2>
                                    {field1Elements}
                                </div>
                                <div className="col-span-1  rounded-default text-16">
                                    <h2 className="border-l-8 border-alto pl-6">
                                        {`${t(
                                            `list.language:${secondLanguage}`
                                        )} ${t('form-content:Translations')}`}
                                    </h2>
                                    {field2Elements}
                                </div>
                            </div>
                        </div>
                        <div className=" fixed z-40 bottom-0 px-6 bg-white w-full min-h-[114px] shadow-[-10px_10px_40px_rgba(114,114,114,0.15)] flex items-center">
                            <div className="w-fit h-full ml-auto flex items-center gap-x-5">
                                <Button
                                    disabled={isFormPreview}
                                    scheme="primary"
                                    form="translationSubmitForm"
                                    onClick={async () => {
                                        if (
                                            errors &&
                                            Object.keys(errors).length > 0
                                        ) {
                                            setLockFields(false);
                                            scrollToErrorField(
                                                errors as FormTextsResult
                                            );
                                        } else {
                                            setIsModalVisible(true);
                                        }
                                    }}
                                >
                                    {t('form-content:ApproveTranslations')}
                                </Button>
                                <Button
                                    disabled={isFormPreview}
                                    scheme="secondary"
                                    onClick={() => setLockFields(false)}
                                    data-test="create-form-button"
                                >
                                    {t('form-content:EditTranslations')}
                                </Button>
                            </div>
                        </div>
                        {isModalVisible && (
                            <ConfirmationModal
                                cancelText={t('form-content:LabelCancel')}
                                confirmText={t('form-content:LabelConfirm')}
                                title={t(
                                    'form-content:ConfirmTranslationApprovalTitle'
                                )}
                                onConfirm={async () => {
                                    form.submit();
                                }}
                                closeModal={() => setIsModalVisible(false)}
                                isModalVisible={isModalVisible}
                            >
                                <p className="text-left">
                                    {t(
                                        'form-content:ConfirmTranslationApprovalTip'
                                    )}
                                </p>
                                <p className="text-left mt-6">
                                    {t(
                                        'form-content:ConfirmTranslationApproval'
                                    )}
                                </p>
                            </ConfirmationModal>
                        )}
                    </form>
                </>
            )}
        />
    );
};

const PreviewElementStyle =
    'font-bold text-body bg-concrete p-3 rounded-default whitespace-pre-wrap';

export const FieldInput: FC<FieldInputProps> = ({
    readOnly,
    item,
    t,
    lockFields,
    required = false,
    input,
    meta,
}) => (
    <div className="p-4 pl-10 pt-10 border-l-2 border-alto">
        <label
            htmlFor={item.path}
            className="text-caption mb-1 flex items-center flex-wrap"
        >
            <span className="mr-auto">
                {t(item.label)}
                {item.tooltip && <TooltipWrapper content={t(item.tooltip)} />}
            </span>
            <div className="mr-1 text-dove text-caption font-normal w-min-fit">
                <p className="break-all">{item.businessTerm}</p>
            </div>
        </label>
        {item.textArea ? (
            <div>
                <Textarea
                    {...input}
                    readOnly={readOnly || (!readOnly && lockFields)}
                    className={cx(
                        'min-h-[100px] resize-none',
                        ((!readOnly && lockFields) || readOnly) &&
                            `${PreviewElementStyle} focus:border-0 border-0`
                    )}
                />
                {required && (
                    <FieldError className="absolute mt-1">
                        {meta.error}
                    </FieldError>
                )}
            </div>
        ) : (
            <div>
                <Input
                    {...input}
                    readOnly={readOnly || (!readOnly && lockFields)}
                    className={cx(
                        ((!readOnly && lockFields) || readOnly) &&
                            `${PreviewElementStyle} pointer-events-none border-0`
                    )}
                />

                {required && (
                    <FieldError className="absolute mt-1">
                        {meta.error}
                    </FieldError>
                )}
            </div>
        )}
    </div>
);
FormTranslationReviewPage.displayName = 'FormTranslationReviewPage';
FormTranslationReviewPage.whyDidYouRender = true;
export default FormTranslationReviewPage;
