import axios, { AxiosResponse } from 'axios';
import { TFunction } from 'react-i18next';
import { TenderListItem } from '../types/tenderList';
import { FormsMenuItem } from '../types/generated/formsMenuItem';

export const fetchTenders = (
    t: TFunction<string, unknown>,
    platformName?: string,
    onlyPublished?: boolean,
    inputValue?: string,
    useFetchTendersDynamic?: boolean
) => {
    const dynamicUrl = inputValue
        ? `testapp/names-dynamic/${platformName}/${onlyPublished}/${inputValue}`
        : `testapp/names-dynamic/${platformName}/${onlyPublished}`;
    const url = useFetchTendersDynamic
        ? dynamicUrl
        : `testapp/names/${platformName}/${onlyPublished}`;

    return axios
        .get(url)
        .then((res: AxiosResponse<FormsMenuItem[]>) => {
            const { data } = res;
            return data.reduce(
                (
                    prevItem: Record<string, TenderListItem>,
                    item: FormsMenuItem
                ) => {
                    const { tenderId } = item;
                    if (tenderId) {
                        if (prevItem[tenderId]) {
                            prevItem[tenderId].forms.push({
                                name: `${t(
                                    `list.form-type:${item.formType}` ?? ''
                                )} ${
                                    item.isChangeNotice === true ? 'Change' : ''
                                } [${item.noticeSubtype}]`,
                                value: item.id ?? '',
                                manyLots: item.manyLots,
                                mainLanguage: item.mainLanguage,
                            });
                            return {
                                ...prevItem,
                            };
                        }
                        return {
                            ...prevItem,
                            [tenderId]: {
                                name: `${tenderId}`,
                                forms: [
                                    {
                                        name: `${t(
                                            `list.form-type:${item.formType}` ??
                                                ''
                                        )} ${
                                            item.isChangeNotice === true
                                                ? 'Change'
                                                : ''
                                        } [${item.noticeSubtype}]`,
                                        value: item.id ?? '',
                                        manyLots: item.manyLots,
                                        mainLanguage: item.mainLanguage,
                                    },
                                ],
                            },
                        };
                    }
                    return prevItem;
                },
                {}
            );
        })
        .catch((error) => {
            throw error;
        });
};
