import { checkFieldExists } from '../pages/Form/CheckFieldExists';
import {
    ComparisonValueType,
    ConditionItem,
} from '../types/generated/formDefinitionItem';

export const getVisibilityValue = (
    condition: ConditionItem,
    value: any,
    fieldName?: string
): boolean => {
    switch (condition?.valueType) {
        case ComparisonValueType.Visible:
            if (fieldName) {
                if (condition?.compareType === '===') {
                    const isFieldRendered = checkFieldExists(fieldName);
                    return !!(
                        (condition.valueToCompare === 'true' &&
                            isFieldRendered) ||
                        (condition.valueToCompare === 'false' &&
                            !isFieldRendered)
                    );
                }
            }
            return false;
        case ComparisonValueType.String:
            if (condition?.compareType === '===') {
                if (condition?.valueToCompare === '' && value === null) {
                    return true;
                }
                return (value as string) === condition?.valueToCompare;
            }
            if (condition?.compareType === '!==') {
                return value !== condition?.valueToCompare;
            }
            if (condition?.compareType === 'includes') {
                return (value as string[]).includes(condition?.valueToCompare);
                // } else if (condition?.compareType === 'fullArray') {
                //   return xor(value [],condition?.valueToCompareArray.map(value => value)).length === 0;
            }
            return false;

        case ComparisonValueType.Number:
            if (condition?.additionalCompareType === 'arrayLength') {
                if (condition?.compareType === '===') {
                    return value.length === Number(condition?.valueToCompare);
                }
                if (condition?.compareType === '!==') {
                    return value.length !== Number(condition?.valueToCompare);
                }
                if (condition?.compareType === '>=') {
                    return value.length >= Number(condition?.valueToCompare);
                }
                if (condition?.compareType === '<=') {
                    return value.length <= Number(condition?.valueToCompare);
                }
                if (condition?.compareType === '>') {
                    return value.length > Number(condition?.valueToCompare);
                }
                if (condition?.compareType === '<') {
                    return value.length < Number(condition?.valueToCompare);
                }
            }
            if (condition?.compareType === '===') {
                return (value as number) === Number(condition?.valueToCompare);
            }
            if (condition?.compareType === '!==') {
                return (value as number) !== Number(condition?.valueToCompare);
            }
            if (condition?.compareType === '>=') {
                return (value as number) >= Number(condition?.valueToCompare);
            }
            if (condition?.compareType === '<=') {
                return (value as number) <= Number(condition?.valueToCompare);
            }
            if (condition?.compareType === '>') {
                return (value as number) > Number(condition?.valueToCompare);
            }
            if (condition?.compareType === '<') {
                return (value as number) < Number(condition?.valueToCompare);
            }
            if (condition?.compareType === 'includes') {
                return (value as number[]).includes(
                    Number(condition?.valueToCompare)
                );
                // } else if (condition?.compareType === 'fullArray') {
                //   return xor(value as number[],condition?.valueToCompareArray.map(value => Number(value))).length === 0;
            }
            return false;

        case ComparisonValueType.Boolean: {
            const valueToParse =
                (value ?? 'false') === '' ? 'false' : value ?? 'false';

            if (condition?.compareType === '===') {
                return (
                    JSON.parse(valueToParse) ===
                    (condition?.valueToCompare.toLowerCase() === 'true')
                );
            }
            if (condition?.compareType === '!==') {
                return (
                    JSON.parse(valueToParse) !==
                    (condition?.valueToCompare.toLowerCase() === 'true')
                );
            }
            if (condition?.compareType === 'includes') {
                return (value as boolean[]).includes(
                    condition?.valueToCompare.toLowerCase() === 'true'
                );
                // } else if (condition?.compareType === 'fullArray') {
                //   return xor(value as boolean[],condition?.valueToCompareArray.map(value => Boolean(value))).length === 0;
            }
            return false;
        }
        // //might be a problem when comparing also times when there is a time difference and midnight happens to be inbetween those times
        // case ComparisonValueType.datetime:
        //     if (condition?.compareType === '===') {
        //         return dayjs(value ).isSame(
        //             dayjs(condition?.valueToCompare)
        //         );
        //     } else if (condition?.compareType === '!==') {
        //         return !dayjs(value ).isSame(
        //             dayjs(condition?.valueToCompare)
        //         );
        //     } else if (condition?.compareType === '>=') {
        //         return !dayjs(value ).isAfter(
        //             dayjs(condition?.valueToCompare) ||
        //                 dayjs(value ).isSame(
        //                     dayjs(condition?.valueToCompare)
        //                 )
        //         );
        //     } else if (condition?.compareType === '<=') {
        //         return !dayjs(value ).isBefore(
        //             dayjs(condition?.valueToCompare) ||
        //                 dayjs(value ).isSame(
        //                     dayjs(condition?.valueToCompare)
        //                 )
        //         );
        //     } else if (condition?.compareType === '>') {
        //         return !dayjs(value ).isAfter(
        //             dayjs(condition?.valueToCompare)
        //         );
        //     } else if (condition?.compareType === '<') {
        //         return !dayjs(value ).isBefore(
        //             dayjs(condition?.valueToCompare)
        //         );
        //     } else {
        //         return false;
        //     }
        default:
            return false;
    }
};
// const date = dayjs('2011-01-20');
