import React from 'react';
import {
    Copy24,
    LicenseDraft24,
    Link24,
    OverflowMenuVertical16,
    TextLinkAnalysis24,
} from '@carbon/icons-react';
import Button from '@mercell/button-react';
import { DropdownMenu, DropdownMenuItem } from '@mercell/dropdown-menu-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FormStatus } from '../../types/generated/formMenuResult';

interface TableDropDownProps {
    cell: any;
    t: any;
    handleDropDownShow: (e: any, cell: any) => void;
    copyToClipboard: (text: string) => void;
    openModal: (formID: string) => void;
    openModalBackToDispatched: (formID: string) => void;
    viewStatusLog: (
        formId: string,
        tenderId: string,
        tenderingSystem: string
    ) => void;
    viewPublicationLog: (formId: string) => void;
}

const TableDropDown = (props: TableDropDownProps) => {
    const { dashboardChangesSprint4, showPublicationLogOnDashboard } = useFlags();
    const {
        cell,
        t,
        handleDropDownShow,
        copyToClipboard,
        openModal,
        openModalBackToDispatched,
    } = props;
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    return (
        <>
            <Button
                scheme="icon"
                className="rt-tr-group focus:ring-offset-0 text-minsk focus:bg-transparent"
                ref={buttonRef}
                style={{ boxShadow: 'none' }}
                iconSettings={{
                    Icon: OverflowMenuVertical16,
                }}
                onClick={(e) => handleDropDownShow(e, cell)}
                data-test="array-button"
            />
            {cell.row.original.showDropDown && (
                <DropdownMenu
                    id="dashboard-table-dropdown-menu"
                    anchorRef={buttonRef}
                    closeOnClick
                    onClose={(e) => handleDropDownShow(e, cell)}
                    popperOptions={{ placement: 'left-start' }}
                    className="p-4 !min-w-[300px]"
                >
                    <div className="flex items-center justify-center px-2">
                        <LicenseDraft24 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            data-test="array-item-edit-button"
                            onClick={() =>
                                dashboardChangesSprint4
                                    ? openModal(cell.row.original.formId)
                                    : undefined
                            }
                            disabled={
                                dashboardChangesSprint4 &&
                                !(
                                    cell.row.original.status ===
                                        FormStatus.ReadyForPublication ||
                                    cell.row.original.status ===
                                        FormStatus.Error ||
                                    cell.row.original.status ===
                                        FormStatus.ReadyForTranslation ||
                                    cell.row.original.status ===
                                        FormStatus.Deleted
                                )
                            }
                        >
                            {t('dashboard:ReturnStatusToDraft')}
                        </DropdownMenuItem>
                    </div>
                    <div className="flex items-center justify-center px-2">
                        <LicenseDraft24 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            data-test="array-item-edit-button"
                            onClick={() =>
                                openModalBackToDispatched(
                                    cell.row.original.formId
                                )
                            }
                            disabled={
                                !(
                                    cell.row.original.status ===
                                        FormStatus.Error ||
                                    cell.row.original.status ===
                                        FormStatus.ReadyForTranslation
                                )
                            }
                        >
                            {t(
                                'dashboard:ReturnStatusToDispatchedForPublication'
                            )}
                        </DropdownMenuItem>
                    </div>
                    <div className="flex items-center justify-center px-2">
                        <TextLinkAnalysis24 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            data-test="array-item-edit-button"
                            onClick={() =>
                                dashboardChangesSprint4
                                    ? props.viewStatusLog(
                                          cell.row.original.formId,
                                          cell.row.original.tenderId,
                                          props?.cell.row.original
                                              .tenderingSystem
                                      )
                                    : undefined
                            }
                        >
                            {t('form-content:StatusLog')}
                        </DropdownMenuItem>
                    </div>
                    {showPublicationLogOnDashboard && (
                        <div className="flex items-center justify-center px-2">
                            <TextLinkAnalysis24 className="text-minsk" />
                            <DropdownMenuItem
                                className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                                data-test="array-item-edit-button"
                                onClick={() =>
                                    props.viewPublicationLog(
                                        cell.row.original.formId
                                    )
                                }
                            >
                                {t('form-content:PublicationLog')}
                            </DropdownMenuItem>
                        </div>
                    )}
                    <div className="flex items-center justify-center px-2">
                        <Link24 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            data-test="array-item-edit-button"
                        >
                            {t('form-content:LinkToPortal')}
                        </DropdownMenuItem>
                    </div>
                    <div className="flex items-center justify-center px-2">
                        <Copy24 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            onClick={
                                dashboardChangesSprint4
                                    ? () =>
                                          copyToClipboard(
                                              cell.row.original.formId
                                          )
                                    : undefined
                            }
                            data-test="array-item-edit-button"
                        >
                            {t('dashboard:CopyFormID')}
                        </DropdownMenuItem>
                    </div>
                    <div className="flex items-center justify-center px-2">
                        <Copy24 className="text-minsk" />
                        <DropdownMenuItem
                            className="hover:bg-transparent hover:text-minsk focus:ring-offset-0  active:bg-transparent"
                            disabled={!cell.row.original.portalNoticeId}
                            onClick={
                                dashboardChangesSprint4
                                    ? () =>
                                          copyToClipboard(
                                              cell.row.original.portalNoticeId
                                          )
                                    : undefined
                            }
                            data-test="array-item-edit-button"
                        >
                            {t('dashboard:CopyPortalNoticeId')}
                        </DropdownMenuItem>
                    </div>
                </DropdownMenu>
            )}
        </>
    );
};

export default TableDropDown;
