import { AxiosRequestConfig } from 'axios';
import {
    getAdminToken,
    getOktaToken,
    getRepToken,
} from '../shared/storageService/cookieService';

export const tokenHelper = (request: AxiosRequestConfig) => {
    const oktaRoutesCondition =
        request.url?.includes('testapp/') ||
        request.url?.includes('translation/') ||
        request.url?.includes('auth/authorize-with-octa') ||
        request.url?.includes('auth/user-rights') ||
        request.url?.includes('dashboard') ||
        request.url?.includes('configurator/');

    const adminRoutesCondition =
        (request.url?.includes('rep/form/') && request.method === 'delete') ||
        (request.url?.includes('rep/form/selector') &&
            request.method === 'get') ||
        request.url?.includes('rep/form/url/') ||
        request.url?.includes('rep/form/create/') ||
        request.url?.includes('rep/form/publish/') ||
        request.url?.includes('rep/form/copy/') ||
        request.url?.includes('rep/form/auditlog/') ||
        request.url?.includes('rep/form/lock/') ||
        request.url?.includes('rep/form/previewdocument/');

    const repRoutesCondition =
        request.url?.includes('form/lists/') ||
        (request.url?.includes('rep/form/') &&
            !request.url?.includes('url/') &&
            !request.url?.includes('texts/') &&
            !request.url?.includes('update/') &&
            !request.url?.includes('render/') &&
            !request.url?.includes('save/') &&
            !request.url?.includes('confirm/') &&
            !request.url?.includes('menu/') &&
            !request.url?.includes('selector/') &&
            !request.url?.includes('auditlog/') &&
            !request.url?.includes('previewdocument/') &&
            !request.url?.includes('compare') &&
            request.method === 'get') ||
        request.url?.includes('rep/form/update/') ||
        request.url?.includes('form/render/') ||
        request.url?.includes('form/readonly/') ||
        request.url?.includes('form/save/') ||
        request.url?.includes('form/add/') ||
        request.url?.includes('form/confirm/') ||
        request.url?.includes('form/menu/') ||
        request.url?.includes('form/texts/') ||
        request.url?.includes('array/') ||
        request.url?.includes('help/');

    if (!request.headers.SkipTokenHelper) {
        if (oktaRoutesCondition) {
            request.headers.Authorization = `Bearer ${getOktaToken()}`;
        }

        if (adminRoutesCondition) {
            request.headers.Authorization = `Bearer ${getAdminToken()}`;
        }
        if (repRoutesCondition) {
            request.headers.Authorization = `Bearer ${getRepToken()}`;
        }
    }
    return request;
};
