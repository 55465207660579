import { getRulesSetup } from '../getRulesSetup';
import * as yup from 'yup';
import {
    FormOptionItem,
    UIDesignElementType,
    FormDefinitionItem,
} from '../../types/generated/formDefinitionItem';
import { FieldStructure, RadioFieldStructure } from '@mercell/form-react';
import { TFunction } from 'react-i18next';

export const mapRadioField = (
    formDefinition: FormDefinitionItem,
    genericFieldStructure: FieldStructure,
    t: TFunction<string, unknown>,
    useRadioClearSelect?: boolean
): RadioFieldStructure => {
    let options: FormOptionItem[] = [];

    if (formDefinition.options) {
        options = formDefinition.options.map((option: FormOptionItem) => {
            const formOption: FormOptionItem = {
                label: t(`${option.label}`),
                value: option.value,
                parentId: option.parentId,
                addDefaultTag: option.addDefaultTag,
            };
            return formOption;
        });

        if (formDefinition.isSortable ?? true) {
            options.sort((a, b) => a.label.localeCompare(b.label));
        }
    }

    const optionsList = () =>
        formDefinition.options?.map((option: FormOptionItem) => ({
            label: `${t(`${option.label}`)}${
                option.addDefaultTag ? ` (${t('form-content:default')})` : ''
            }`,
            value: option.value,
            // Todo: if later needed: optionProperties: { className: 'bg-main-40' }, should check wether the different browsers support the content here
        })) ?? [];

    if (genericFieldStructure.associatedValidationBasedOnOtherFieldValues) {
        if (
            formDefinition.conditionalValidation?.rules &&
            genericFieldStructure.associatedValidationBasedOnOtherFieldValues
        ) {
            const conditionalValidations = getRulesSetup(
                yup.string().ensure(),
                formDefinition.conditionalValidation.rules,
                t
            );
            genericFieldStructure.associatedValidationBasedOnOtherFieldValues.rules =
                conditionalValidations;
        }
    }

    return {
        ...genericFieldStructure,
        type: 'radio',
        fieldsetProperties: {
            className:
                formDefinition.designElementType ===
                UIDesignElementType.WithheldPublicationIndicator
                    ? 'text-caption'
                    : formDefinition.fieldsetStyle,
        },
        options,
        optionsList,
        skipClearSelection: useRadioClearSelect
            ? formDefinition.skipClearSelection
            : true,
        clearSelectionLabel: t('form-content:ClearSelection'),
        disabled: formDefinition.preview,
        validations: formDefinition.rules
            ? getRulesSetup(yup.string().ensure(), formDefinition.rules, t)
            : undefined,
    };
};

export const mapRadioReadOnly = (
    translationFileName: string,
    content: any,
    options: any,
    translatedContent: string
) => {
    if (
        !translationFileName &&
        options &&
        Array.isArray(options) &&
        options.length
    ) {
        return options.find((option) => option.value === content.toString())
            ?.label;
    }
    if (translationFileName) {
        return translatedContent;
    }

    return content;
};
