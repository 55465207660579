import Button from '@mercell/button-react';
import { useTranslation } from 'react-i18next';
import { Add32, Search24 } from '@carbon/icons-react';
import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import useFetchArray from '../../hooks/formHooks/useFetchArray';
import {
    Link,
    useNavigate,
    useOutletContext,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import ArrayElementAccordion from './ArrayElementAccordion';
import { Pagination } from '@mercell/pagination-react';
import { FormArrayItem } from '../../types/generated/formArrayItem';
import FormError from '../../components/FormError';
import { getFormLanguage } from '../../shared/storageService/sessionService';
import { getStyleClassesForDesignType } from '../../shared/getStyleClasses';
import { UIDesignElementType } from '../../types/generated/formDefinitionItem';
import { fetchNewFormPath } from '../../apiCalls/fetchNewFormPath';
import { Input, InputContainer, InputLeftElement } from '@mercell/input-react';
import NotFoundPage from '../NotFound/NotFoundPage';
import ConfirmationModal from '../../components/ConfirmationModal';
import { useStoreContext } from '../../state';
import LoadingSpinner from '@mercell/loading-spinner-react';
import { OutletFormContextInterface } from '../../types/outletInterface';
import Breadcrumbs from '@mercell/breadcrumbs-react';
import { ValidationErrorObject } from '../../types/validationError';
import FieldError from '@mercell/field-error-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { nl2br } from '../../shared/utilities/nl2br';
import { Card, CardText } from '@mercell/card-react';
import Checkbox from '@mercell/checkbox-react';
import { copyLot, deleteArrayList } from '../../apiCalls/arrayList';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setTenderName } from '../../redux/storeSlice';

const PageSize = 5;

const ArrayList: FC = memo(() => {
    const {
        state: { tenderName: tenderNameState },
        dispatch,
    } = useStoreContext();
    const reduxTenderName = useAppSelector((state) => state.store.tenderName);
    const reduxDispatch = useAppDispatch();

    const {
        useRenderSectionErrorsSubArrayLevel,
        copyLots,
        useStoreRedux1860,
        useNewBaseUrl1870,
    } = useFlags();
    const {
        isFormPreview,
        currentSelectedSection = {
            elementTitleKey: '',
            labelAppend: '',
            translationKey: '',
            hasSubmenu: false,
            labelRemove: '',
            introductionKey: '',
        },
        navigationTitle,
        parentElementTitleKey,
        backendValidationErrors,
    } = useOutletContext<OutletFormContextInterface>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isCopyLotVisible, setIsCopyLotVisible] = useState<boolean>(false);
    const [arrayElement, setArrayElement] = useState<FormArrayItem>();
    const [lotElement, setLotElement] = useState<FormArrayItem>();
    const [lotUniqueIds, setLotUniqueIds] = useState<string[]>([]);
    const [lotFilter, setLotFilter] = useState<string>('');
    const {
        formId,
        sectionId,
        parentSectionId,
        parentArrayPath,
        parentArrayElementId,
        arrayElementId,
        arrayPath = '',
    } = useParams<{
        formId: string;
        sectionId: string;
        parentSectionId?: string;
        parentArrayPath?: string;
        arrayElementId?: string;
        parentArrayElementId?: string;
        arrayPath?: string;
    }>();
    const { hasSubmenu = false } = currentSelectedSection;
    const isExpandable = !hasSubmenu;
    const sectionPath = !parentArrayElementId
        ? sectionId
        : `${parentArrayElementId}.${sectionId}`;
    const [searchParams] = useSearchParams();
    const [deleteError, setDeletionError] = useState<string>();
    const pageNumber = Number(searchParams.get('pageNo') || 1);
    const search = searchParams.get('search') || '';
    const {
        formData,
        isErrorFetchingArrayElements,
        isLoadingArray,
        tenderName,
        isAddingDisabled,
        setState,
    } = useFetchArray(formId, sectionPath, arrayPath);
    const [filter, setFilter] = useState<string>(search ?? '');
    const { t } = useTranslation(['list.error', 'form-content', 'rules']);
    const { errorObjectWithPaths } = backendValidationErrors;
    const [sectionErrors, setSectionErrors] = useState<string[]>([]);
    const language = getFormLanguage();
    const navigate = useNavigate();
    let arrayElementTitle;
    if (arrayElementId && arrayElementId !== 'add') {
        arrayElementTitle = `${t('form-content:Edit')} ${t(
            currentSelectedSection.elementTitleKey ?? ''
        ).toLowerCase()}`;
    } else if (arrayElementId === 'add') {
        arrayElementTitle = t(currentSelectedSection?.labelAppend ?? '');
    }
    const onDelete = (element: FormArrayItem) => {
        setArrayElement(element);
        setIsModalVisible(true);
    };

    const onConfirmDelete = async () => {
        if (arrayElement && formId && sectionId) {
            await deleteArrayList(
                formId,
                language,
                sectionPath,
                arrayPath,
                arrayElement,
                {
                    onPendingText: t('toast-content:ToastLotDeletePending'),
                    onSuccessText: t('toast-content:ToastLotDeleteSuccess'),
                }
            ).catch((e: any) => setDeletionError(e.status.toString()));
            setState(undefined, true);
        }

        setIsModalVisible(false);
    };
    const getName = (element: FormArrayItem, transtlation: any) =>
        element.name && element.name !== ''
            ? element.name
            : transtlation('form-content:Untitled');
    const filteredArray =
        formData?.array?.filter(
            (element) =>
                (filter ?? '') === '' ||
                getName(element, t).toLowerCase().includes(filter.toLowerCase())
        ) || [];

    const formDataArrayLength = filteredArray.length || 0;

    const filteredLotArray =
        filteredArray
            .filter((element) => element.uniqueId !== lotElement?.uniqueId)
            .filter(
                (element) =>
                    (lotFilter ?? '') === '' ||
                    getName(element, t)
                        .toLowerCase()
                        .includes(lotFilter.toLowerCase())
            ) || [];

    const filteredLotArrayLength = filteredLotArray.length || 0;

    useEffect(() => {
        if (
            formDataArrayLength &&
            pageNumber > Math.ceil(formDataArrayLength / PageSize)
        ) {
            navigate(
                `${
                    !parentSectionId
                        ? `/form/${formId}`
                        : `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}/${parentArrayElementId}`
                }/${sectionId}/${arrayPath}?pageNo=${pageNumber - 1}${
                    filter ? `&search=${filter}` : ''
                }`
            );
        }
    }, [
        formDataArrayLength,
        pageNumber,
        navigate,
        formId,
        sectionId,
        filter,
        parentSectionId,
        parentArrayPath,
        parentArrayElementId,
        arrayPath,
    ]);

    useEffect(() => {
        if (useStoreRedux1860) {
            if (tenderName && reduxTenderName !== tenderName) {
                reduxDispatch(setTenderName(tenderName ?? ''));
            }
        } else if (tenderName && tenderNameState !== tenderName) {
            dispatch({
                type: 'SET_TENDER_NAME',
                payload: { tenderName: tenderName ?? '' },
            });
        }
    }, [
        dispatch,
        tenderNameState,
        tenderName,
        useStoreRedux1860,
        reduxTenderName,
        reduxDispatch,
    ]);

    useEffect(() => {
        if (!search) {
            setFilter('');
        }
    }, [search, sectionId, parentSectionId]);

    useEffect(() => {
        // Errors from fetchArray formData (not used anymore)
        // let sectionInlineErrors = [];
        // if (formData) {
        //     const jsonErrors =
        //         formData.errors !== undefined
        //             ? JSON.parse(formData.errors)
        //             : formData.errors;
        //     const fieldErrors = jsonErrors && jsonErrors.fieldErrors;
        //     const sectionJsonErrors =
        //         fieldErrors && fieldErrors[sectionId ?? ''][arrayPath];

        //     if (
        //         !Array.isArray(sectionJsonErrors) &&
        //         !sectionJsonErrors?._id &&
        //         sectionJsonErrors?._errors
        //     ) {
        //         sectionInlineErrors = sectionJsonErrors._errors.map(
        //             (error: any) => t(`form-content:${error.translationKey}`)
        //         );
        //     }
        // }

        // section backend-end errors
        const sectionObject = useRenderSectionErrorsSubArrayLevel
            ? errorObjectWithPaths[
                  (parentSectionId ?? sectionId) as keyof ValidationErrorObject
              ]
            : errorObjectWithPaths[sectionId as keyof ValidationErrorObject];
        const sectionBackendErrors =
            sectionObject?.find(
                (item) => item.fieldPath === `${sectionId}.${arrayPath}`
            )?.fieldErrors ?? [];
        // const allSectionErrors = [
        //     // ...sectionInlineErrors,
        //     ...sectionBackendErrors,
        // ];
        const allSectionErrors = [...sectionBackendErrors];
        const uniqueSectionErrors = Array.from(new Set(allSectionErrors));
        setSectionErrors(uniqueSectionErrors);
    }, [
        errorObjectWithPaths,
        arrayPath,
        sectionId,
        t,
        parentSectionId,
        useRenderSectionErrorsSubArrayLevel,
    ]);

    if (!formId) return null;
    if (isLoadingArray) {
        return (
            <div className="col-span-full h-full flex items-center justify-center">
                <LoadingSpinner />
            </div>
        );
    }
    if (isErrorFetchingArrayElements)
        return (
            <NotFoundPage
                errorCode={isErrorFetchingArrayElements.status.toString()}
            />
        );
    if (!formData) {
        return <div>No organizations in DB</div>;
    }

    const subSection = getStyleClassesForDesignType(
        UIDesignElementType.Subsection
    );

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) =>
        setFilter(event.target.value);

    const redirectToNewElement = async () => {
        let newElement;
        try {
            newElement = await fetchNewFormPath(
                formId,
                sectionPath ? `${sectionPath}.${arrayPath}` : ''
            );
        } catch (e) {
            alert(e);
        }

        if (newElement) {
            if (!parentSectionId) {
                if (hasSubmenu)
                    navigate(
                        `/form/${formId}/sub/${sectionId}/${arrayPath}/${
                            newElement.sectionPath
                        }${
                            pageNumber.toString()
                                ? `?pageRef=${pageNumber.toString()}`
                                : ''
                        }`
                    );
                else {
                    navigate(
                        `/form/${formId}/${sectionId}/${arrayPath}/${
                            newElement.uniqueId
                        }${
                            pageNumber.toString()
                                ? `?pageRef=${pageNumber.toString()}`
                                : ''
                        }`
                    );
                }
            } else
                navigate(
                    `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}/${parentArrayElementId}/${sectionId}/${arrayPath}/${
                        newElement.uniqueId
                    }${
                        pageNumber.toString()
                            ? `?pageRef=${pageNumber.toString()}`
                            : ''
                    }`
                );
        }
    };

    const getEditUrl = (element: FormArrayItem) => {
        const urlParams = pageNumber.toString()
            ? `?pageRef=${pageNumber.toString()}${
                  filter ? `&search=${filter}` : ''
              }`
            : `${filter ? `?search=${filter}` : ''}`;
        if (!hasSubmenu) {
            if (!parentSectionId) {
                return `/form/${formId}/${sectionId}/${arrayPath}/${element.uniqueId}${urlParams}`;
            }
            return `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}/${parentArrayElementId}/${sectionId}/${arrayPath}/${element.uniqueId}${urlParams}`;
        }

        return `/form/${formId}/sub/${sectionId}/${arrayPath}/${element.definitionPath}${urlParams}`;
    };

    let baseUrl: string;
    if (useNewBaseUrl1870) {
        baseUrl = !parentSectionId
            ? `/form/${formId}`
            : `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}${
                  parentArrayElementId ? `/${parentArrayElementId}` : ''
              }`;
    } else {
        baseUrl = !parentSectionId
            ? `/form/${formId}`
            : `/form/${formId}/sub/${parentSectionId}/${parentArrayPath}`;
    }

    const onSelectAll = (isChecked: boolean) => {
        const allUniqueIds = filteredLotArray.map((ele) => ele.uniqueId);
        if (isChecked) {
            setLotUniqueIds([...(allUniqueIds ?? [])]);
        } else {
            setLotUniqueIds([]);
        }
    };

    const onLotCheked = (id: string, isChecked: boolean) => {
        let uniqueIds = lotUniqueIds;
        if (isChecked) {
            uniqueIds?.push(id);
        } else {
            uniqueIds = lotUniqueIds?.filter((ele) => ele !== id);
        }
        setLotUniqueIds([...(uniqueIds ?? [])]);
    };

    const handleLotSearch = (event: ChangeEvent<HTMLInputElement>) =>
        setLotFilter(event.target.value);

    const onCopyLot = async () => {
        if (formId && lotElement && lotElement.uniqueId && lotUniqueIds) {
            await copyLot(formId, lotElement, lotUniqueIds, {
                onPendingText: t('toast-content:ToastCopyLotPending'),
                onSuccessText: t('toast-content:ToastCopyLotSuccess'),
            });
            setState(undefined, true);
        }
        setIsCopyLotVisible(false);
    };

    return (
        <div>
            {parentSectionId ? (
                <>
                    <Breadcrumbs className="ml-4 mt-1 mb-4">
                        {parentElementTitleKey ? (
                            <Link
                                to={`/form/${formId}/${parentSectionId}/${parentArrayPath}`}
                            >
                                {t(parentElementTitleKey ?? '')}
                            </Link>
                        ) : null}
                        <Link
                            to={`/form/${formId}/sub/${parentSectionId}/${parentArrayPath}/${parentArrayElementId}/${sectionId}/${arrayPath}`}
                        >
                            {t(currentSelectedSection?.translationKey ?? '')}
                        </Link>
                        {arrayElementTitle ? (
                            <Link
                                to={window.location.pathname}
                                className="pointer-events-none"
                            >
                                {arrayElementTitle}
                            </Link>
                        ) : null}
                    </Breadcrumbs>
                    <div className="flex items-center text-h2 ml-4 mb-4 mt-1 pb-3 border-alto border-b-2">
                        {navigationTitle ?? t('form-content:NewItem')}
                    </div>
                </>
            ) : null}
            {isModalVisible && arrayElement && (
                <ConfirmationModal
                    cancelText={t('form-content:LabelCancel')}
                    confirmText={t(
                        currentSelectedSection?.labelRemove ??
                            'form-content:LabelDelete'
                    )}
                    onConfirm={onConfirmDelete}
                    closeModal={() => setIsModalVisible(false)}
                    isModalVisible={isModalVisible}
                    title={t('form-content:ConfirmDelete')}
                >
                    <p className="text-center">
                        {t('form-content:ArrayItemDeleteConfirmation', {
                            elementName: getName(arrayElement, t),
                        })}
                    </p>
                </ConfirmationModal>
            )}
            {deleteError && (
                <FormError
                    error={t(`list.error:${deleteError}`)}
                    key={`err-${deleteError}`}
                    className="mb-4"
                />
            )}
            <div className="flex items-center text-h1 pl-4">
                {t(currentSelectedSection?.translationKey ?? arrayPath)}
            </div>
            <p className="pt-8 text-body mb-1 pl-4" id="Introduction">
                {t(currentSelectedSection?.introductionKey ?? '')}
            </p>
            {sectionErrors &&
                sectionErrors.map((sectionError: string) => (
                    <FieldError key={sectionError} className="pl-4">
                        {nl2br(sectionError)}
                    </FieldError>
                ))}
            <div className={subSection.sectionStyle}>
                <div className={subSection.labelStyle}>
                    {t(currentSelectedSection?.translationKey ?? arrayPath)}
                </div>
                <div className={subSection.divStyle}>
                    <div className="grid md:grid-cols-2 mt-6 ml-14">
                        <InputContainer>
                            <InputLeftElement>
                                <Search24 className="" />
                            </InputLeftElement>
                            <Input
                                placeholder={t('form-content:Search')}
                                onChange={handleSearch}
                                value={filter}
                            />
                        </InputContainer>
                        {(!isFormPreview ? !isAddingDisabled : false) && (
                            <Button
                                className="mt-6 md:mt-0 md:justify-self-end"
                                onClick={() => {
                                    // if (hasSubmenu) {
                                    redirectToNewElement();
                                    // } else navigate(getAddUrl());
                                }}
                                scheme="secondary"
                            >
                                <Add32 />
                                {t(
                                    currentSelectedSection?.labelAppend ??
                                        'form-content:LabelAppend'
                                )}
                            </Button>
                        )}
                    </div>
                    {formData &&
                        formData.array &&
                        formData.array.length > 0 &&
                        filter !== '' && (
                            <div className="ml-14 mt-4">
                                {t('form-content:Results')}{' '}
                                {formDataArrayLength}
                            </div>
                        )}
                    {filteredArray
                        .slice(
                            (pageNumber - 1) * PageSize,
                            pageNumber * PageSize
                        )
                        .map((element, index) => {
                            const errorIndex =
                                (pageNumber - 1) * PageSize <= index
                                    ? index
                                    : (pageNumber - 1) * PageSize + index;

                            return (
                                <div key={`acc-${element.uniqueId}`}>
                                    <ArrayElementAccordion
                                        key={element.uniqueId}
                                        className="mt-6 ml-14"
                                        arrayElement={element}
                                        arrayPath={arrayPath}
                                        onDelete={() => onDelete(element)}
                                        onCopy={() => {
                                            setLotElement(element);
                                            setIsCopyLotVisible(true);
                                        }}
                                        goToUrl={getEditUrl(element)}
                                        isFormPreview={
                                            isFormPreview || element.isPreview
                                        }
                                        isExpandable={isExpandable}
                                        isCopyEnabled={
                                            sectionId === 'LotsSection' &&
                                            copyLots
                                        }
                                    />
                                </div>
                            );
                        })}
                    {filteredArray && filteredArray.length > 0 && (
                        <div className="flex justify-center">
                            <Pagination
                                currentPage={pageNumber}
                                onPageChange={(number) => {
                                    navigate(
                                        `${baseUrl}/${sectionId}/${arrayPath}?pageNo=${number}${
                                            filter ? `&search=${filter}` : ''
                                        }`
                                    );
                                }}
                                pageSize={PageSize}
                                totalCount={filteredArray.length}
                                className="mt-6 ml-14"
                            />
                        </div>
                    )}
                    {isCopyLotVisible && copyLots && (
                        <ConfirmationModal
                            cancelText={t('form-content:LabelCancel')}
                            confirmText={t('form-content:LabelCopy')}
                            onConfirm={onCopyLot}
                            closeModal={() => {
                                setIsCopyLotVisible(false);
                                setLotUniqueIds([]);
                            }}
                            isModalVisible={isCopyLotVisible}
                            title={`${t('form-content:CopyLotDataTo')}...`}
                        >
                            <p className="text-left">
                                {t('form-content:CopyLotInfoTo', {
                                    element: lotElement?.name,
                                })}
                                :
                            </p>
                            <InputContainer className="mt-4 pr-2">
                                <InputLeftElement>
                                    <Search24 className="" />
                                </InputLeftElement>
                                <Input
                                    className="pl-10"
                                    placeholder={t('form-content:Search')}
                                    onChange={handleLotSearch}
                                    value={lotFilter}
                                />
                            </InputContainer>
                            <div className="mt-4 pr-2">
                                <Card className="flex p-3 hover:bg-[#F2F2F2]">
                                    <Checkbox
                                        id="0"
                                        onChange={(event) =>
                                            onSelectAll(event.target.checked)
                                        }
                                        checked={
                                            lotUniqueIds.length > 0 &&
                                            filteredLotArrayLength ===
                                                lotUniqueIds?.length
                                        }
                                    />
                                    <CardText className="ml-2">
                                        {t('form-content:SelectAll')}
                                    </CardText>
                                </Card>
                            </div>

                            <div className="h-64 overflow-auto my-2">
                                {filteredLotArray.map((ele) => (
                                    <div
                                        key={`copy_${ele.uniqueId}`}
                                        className="mb-2 pr-2"
                                    >
                                        <Card className="flex p-3 font-semibold hover:bg-[#F2F2F2]">
                                            <Checkbox
                                                id={ele.uniqueId}
                                                onChange={(event) =>
                                                    onLotCheked(
                                                        ele.uniqueId,
                                                        event.target.checked
                                                    )
                                                }
                                                checked={lotUniqueIds?.includes(
                                                    ele.uniqueId
                                                )}
                                            />
                                            <CardText className="ml-2">
                                                {ele.name}
                                            </CardText>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        </ConfirmationModal>
                    )}
                </div>
            </div>
        </div>
    );
});

ArrayList.displayName = 'ArrayList';
ArrayList.whyDidYouRender = true;
export default ArrayList;
