import './i18n';
import Home from './pages/Home/Home';
import React, { FC, Suspense, memo } from 'react';
import { Routes, Route } from 'react-router-dom';
import FormPage from './pages/Form/FormPage';
import CreateFormPage from './pages/CreateFormPage/CreateFormPage';
import NotFoundPage from './pages/NotFound/NotFoundPage';
import TempFullDataStructurePage from './pages/TempFullDataStructurePage';
import Login from './pages/Login';
import OtpComponent from './pages/OtpComponent';
import ProtectedRepRoute from './components/ProtectedRepRoute';
import EditJson from './pages/EditJson';
import FormConfiguratorMainPage from './pages/FormConfigurator/FormConfiguratorMainPage';
import { Security, LoginCallback } from '@okta/okta-react';
import FormDefinitionConfiguratorPage from './pages/FormConfigurator/notices/FormDefinitionConfiguratorPage';
import PreviewPage from './pages/FormConfigurator/PreviewPage';
import cx from 'classnames';
import CodelistConfiguratorPage from './pages/FormConfigurator/codelists/CodelistConfiguratorPage';
import PublishPage from './pages/FormConfigurator/PublishPage';
import ValidationPageFull from './pages/ValidationPage/ValidationPageFull';
import FormFullPreviewPage from './pages/FormFullPreviewPage/FormFullPreviewPage';
import AuditLogPage from './pages/AuditLog/AuditLogPage';
import FormHtmlPreviewPage from './pages/FormFullPreviewPage/FormHtmlPreviewPage';
import { formOptions } from './types/enums/formOptions';
import SecureOktaRoutes from './authorization/SecureOktaRoutes';
import DownloadXmlPage from './pages/DownloadXmlPage/DownloadXmlPage';
import LoadingSpinner from '@mercell/loading-spinner-react';
import FormContainer from './pages/Form/FormContainer';
import ArrayList from './pages/Array/ArrayList';
import ArrayElementDetail from './pages/Array/ArrayElementDetail';
import FormNavigation from './pages/Form/FormNavigation';
import TranslationsReviewPage from './pages/Translations/TranslationsReviewPage';
import FeatureFlagsListPage from './pages/Admin/FeatureFlags/FeatureFlagsListPage';
import Dashboard from './pages/Dashboard';
import PublicationTaskPage from './pages/PublicationTaskPage/PublicationTaskPage';
import FamilyTreePage from './pages/FamilyTree/FamilyTreePage';
import OktaAuth from '@okta/okta-auth-js';

const formContainerPaths = [
    '/form/:formId',
    '/form/:formId/:sectionId',
    '/form/:formId/sub/:parentSectionId/:parentArrayPath/:parentArrayElementId',
    '/form/:formId/sub/:parentSectionId/:parentArrayPath/:parentArrayElementId/:sectionId',
];
const formArrayListPaths = [
    '/form/:formId/:sectionId/:arrayPath',
    '/form/:formId/sub/:parentSectionId/:parentArrayPath/:parentArrayElementId/:sectionId/:arrayPath',
];
const formArrayElementDetailsPaths = [
    '/form/:formId/:sectionId/:arrayPath/:arrayElementId',
    '/form/:formId/sub/:parentSectionId/:parentArrayPath/:parentArrayElementId/:sectionId/:arrayPath/:arrayElementId',
];

interface RouterProps {
    oktaAuth: OktaAuth;
    restoreOriginalUri: (_oktaAuth: OktaAuth, originalUri: string) => void;
}

export const Router: FC<RouterProps> = ({ oktaAuth, restoreOriginalUri }) => (
    <main
        className={cx(
            'row-start-2 col-start-2 col-end-3 grid grid-cols-4 gap-4',
            'pb-6 px-6',
            'sm:grid-cols-8 sm:gap-6',
            'xl:grid-cols-12',
            'h-full',
            window.location.href.includes('form') && '!px-0'
        )}
    >
        <Suspense
            fallback={
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <LoadingSpinner />
                </div>
            }
        >
            <Security
                oktaAuth={oktaAuth}
                restoreOriginalUri={restoreOriginalUri}
            >
                <Routes>
                    <Route
                        path="/otp/:otp/:option/:locale"
                        element={<OtpComponent />}
                    />
                    <Route
                        element={
                            <SecureOktaRoutes mutateUserRights={undefined} />
                        }
                    >
                        <Route element={<Home />} path="/" />
                        <Route
                            path="/configurator"
                            element={<FormConfiguratorMainPage />}
                        />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route
                            path="/configurator/:versionName/:versionId/notices/definitionid/:definitionId"
                            element={<FormDefinitionConfiguratorPage />}
                        />
                        <Route
                            path="/configurator/:versionName/:versionId/notices/definitionid/:definitionId/:country"
                            element={<FormDefinitionConfiguratorPage />}
                        />
                        <Route
                            path="/configurator/:versionName/:versionId/codelists/codelistid/:codelistId"
                            element={<CodelistConfiguratorPage />}
                        />
                        <Route
                            path="/configurator/:versionName/:versionId/codelists/codelistid/:codelistId/:country"
                            element={<CodelistConfiguratorPage />}
                        />
                        <Route
                            path="/configurator/:versionName/:versionId/:tabName"
                            element={<PublishPage />}
                        />
                        <Route
                            path="/configurator/:versionName/:versionId/:tabName/:country"
                            element={<PublishPage />}
                        />
                        <Route
                            path="/configurator/:versionName/:versionId/notices/preview/:definitionId"
                            element={<PreviewPage />}
                        />
                        <Route
                            path="/configurator/:versionName/:versionId/notices/preview/:definitionId/:country"
                            element={<PreviewPage />}
                        />
                        <Route
                            path="/create-form"
                            element={<CreateFormPage />}
                        />
                        <Route
                            path="/form/auditlog/:formId"
                            element={<AuditLogPage />}
                        />
                        <Route
                            path="/form/publicationtask/:formId"
                            element={<PublicationTaskPage />}
                        />
                        <Route
                            path="/form/family/:formId"
                            element={<FamilyTreePage />}
                        />
                        <Route
                            path="/form/previewhtml/:formId"
                            element={<FormHtmlPreviewPage />}
                        />
                        <Route
                            element={<TempFullDataStructurePage />}
                            path="/fulldatastructure"
                        />
                        <Route
                            element={<TempFullDataStructurePage />}
                            path="/fulldatastructure/:subtypeId"
                        />
                        <Route
                            path="/translations/review"
                            element={<TranslationsReviewPage />}
                        />
                        <Route
                            path="/featureflags/list"
                            element={<FeatureFlagsListPage />}
                        />
                    </Route>
                    <Route element={<ProtectedRepRoute />}>
                        <Route
                            element={<ValidationPageFull />}
                            path={`/form/${formOptions.validationSchematron}/:formId`}
                        />
                        <Route
                            element={<ValidationPageFull />}
                            path={`/form/${formOptions.validationQuick}/:formId`}
                        />
                        <Route
                            element={<ValidationPageFull />}
                            path={`/form/${formOptions.validationFull}/:formId`}
                        />
                        <Route
                            element={<DownloadXmlPage />}
                            path={`/form/${formOptions.downloadxml}/:formId`}
                        />
                        <Route
                            element={<EditJson />}
                            path={`/form/${formOptions.json}/:formId`}
                        />
                        <Route
                            element={<FormFullPreviewPage />}
                            path={`/form/${formOptions.print}/:formId`}
                        />
                        <Route element={<FormPage />}>
                            <Route element={<FormNavigation />}>
                                {formContainerPaths.map((p) => (
                                    <Route
                                        key={p}
                                        element={<FormContainer />}
                                        path={p}
                                    />
                                ))}
                                {formArrayListPaths.map((p) => (
                                    <Route
                                        key={p}
                                        element={<ArrayList />}
                                        path={p}
                                    />
                                ))}
                                {formArrayElementDetailsPaths.map((p) => (
                                    <Route
                                        key={p}
                                        element={<ArrayElementDetail />}
                                        path={p}
                                    />
                                ))}
                            </Route>
                        </Route>
                    </Route>
                    <Route element={<LoginCallback />} path="/login/callback" />
                    <Route path="/login" element={<Login />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </Security>
        </Suspense>
    </main>
);
