import { Dispatch, SetStateAction } from 'react';
import { Mutator } from 'final-form';
import { searchForRenderedFields } from './searchForRenderedFields';
import { trimErrorObjectByFormSchema } from './trimErrorObjectByFormSchema';
import { ValidationErrorObject } from '../../types/validationError';
import { removeDuplicateErrors } from '../removeDuplicateErrors';

type MutatorArgs = [
    setBackendValidationErrors: Dispatch<SetStateAction<ValidationErrorObject>>,
    submittedErrors: Record<string, unknown>,
    parentSectionId: string,
    sectionId: string,
    errorObjectWithPaths: ValidationErrorObject['errorObjectWithPaths'],
    arrayElementId: string,
    parentArrayElementId: string
];

export const backendErrorSubmitMutator: Mutator = (
    args: MutatorArgs,
    state
) => {
    const [
        setBackendValidationErrors,
        submittedErrors,
        parentSectionId,
        sectionId,
        errorObjectWithPaths,
        arrayElementId,
        parentArrayElementId,
    ] = args;

    if (submittedErrors && sectionId && errorObjectWithPaths) {
        const renderedFieldsWithErrors = searchForRenderedFields(
            errorObjectWithPaths[parentSectionId || sectionId]?.map(
                (errorObjects) => errorObjects.fieldPath
            )
        );
        setBackendValidationErrors((currentState) => ({
            ...currentState,
            renderedFieldsWithErrors,
        }));
        const errors = trimErrorObjectByFormSchema(
            submittedErrors,
            errorObjectWithPaths,
            parentSectionId,
            sectionId,
            parentArrayElementId,
            arrayElementId
        );

        const validationErrors = state.formState.errors;
        const submitErrors = errors
            ? removeDuplicateErrors(errors, validationErrors)
            : {};

        if (errors) {
            state.formState = {
                ...state.formState,
                submitFailed: true,
                submitErrors,
            };
        } else {
            state.formState = {
                ...state.formState,
                submitFailed: false,
                submitErrors: {},
            };
        }
    }
};
