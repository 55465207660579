import './i18n';
import Auth0 from './auth0';
import React, { FC } from 'react';
import { GridWrapper } from '@mercell/global-menu-react';
import { OktaAuth } from '@okta/okta-auth-js';
import cx from 'classnames';
import { Header } from './Header';
import { Router } from './Router';

interface AppProps {
    oktaAuth: OktaAuth;
    restoreOriginalUri: (_oktaAuth: OktaAuth, originalUri: string) => void;
}

const App: FC<AppProps> = ({ oktaAuth, restoreOriginalUri }) => (
    <Auth0>
        <GridWrapper
            className={cx(
                window.location !== window.parent.location ? 'pl-3' : '',
                'min-h-screen'
            )}
        >
            <Header />
            <Router
                oktaAuth={oktaAuth}
                restoreOriginalUri={restoreOriginalUri}
            />
        </GridWrapper>
    </Auth0>
);

App.whyDidYouRender = true;

export default App;
