import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from '@mercell/button-react';
import { Input, InputContainer, InputRightElement } from '@mercell/input-react';
import { Launch32, Search32 } from '@carbon/icons-react';
import Breadcrumbs from '@mercell/breadcrumbs-react';
import { useTranslation } from 'react-i18next';
import OktaAuthWrapper from '../../authorization/OktaAuthWrapper';
import { Drawer } from '@mercell/drawer-react';
import LoadingSpinner from '@mercell/loading-spinner-react';
import useFetchTranslations from '../../hooks/translationsHooks/useFetchTranslations';

const TranslationsReviewPage: FC = () => {
    const { translations, isLoading } = useFetchTranslations();

    const { t } = useTranslation(['form-content', 'form-configurator']);

    const [searchString, setSearchString] = useState<string>();

    return (
        <OktaAuthWrapper>
            {!translations || isLoading ? (
                <div className="col-span-full h-full flex items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className="col-span-full xl:justify-self-center xl:min-w-[1200px] max-w-[1200px] whitespace-normal">
                    <h2 className="mb-10">
                        {t('form-content:TranslationsReview')}
                    </h2>
                    <div className="flex justify-between">
                        <div className="mb-10">
                            <InputContainer>
                                <Input
                                    placeholder={t('form-configurator:Search')}
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setSearchString(
                                            e.currentTarget.value.toLocaleLowerCase()
                                        )
                                    }
                                />
                            </InputContainer>
                        </div>
                    </div>

                    <div className="flex text-dove text-caption p-5 justify-between border-b border-alto ">
                        <p className="w-1/12">
                            {t('form-configurator:Level').toUpperCase()}
                        </p>
                        <p className="w-2/12">
                            {t('form-configurator:Field').toUpperCase()}
                        </p>
                        <p className="w-3/12">
                            {t('form-configurator:Label').toUpperCase()}
                        </p>
                        <p className="w-6/12">
                            {t('form-configurator:Tooltip').toUpperCase()}
                        </p>
                    </div>
                    <div className="overflow-auto" style={{ height: '45vh' }}>
                        {translations
                            ?.filter((translation) => {
                                if (
                                    searchString?.length === 0 ||
                                    (translation?.fieldName ?? '')
                                        .toLocaleLowerCase()
                                        .includes(searchString ?? '') ||
                                    (translation?.businessTerm ?? '')
                                        .toLocaleLowerCase()
                                        .includes(searchString ?? '') ||
                                    (translation?.labelKey ?? '')
                                        .toLocaleLowerCase()
                                        .includes(searchString ?? '') ||
                                    (translation?.tooltipKey ?? '')
                                        .toLocaleLowerCase()
                                        .includes(searchString ?? '') ||
                                    (t(translation?.labelKey ?? '') ?? '')
                                        .toLocaleLowerCase()
                                        .includes(searchString ?? '') ||
                                    (t(translation?.tooltipKey ?? '') ?? '')
                                        .toLocaleLowerCase()
                                        .includes(searchString ?? '')
                                ) {
                                    return true;
                                }
                                return false;
                            })
                            ?.map((translation) => (
                                <div className="p-5 flex">
                                    <div className="flex justify-between w-full break-words">
                                        <div className="w-1/12">
                                            <p title={`${translation?.path}`}>
                                                {'* '.repeat(translation.level)}
                                            </p>
                                        </div>
                                        <div className="w-2/12 mr-2">
                                            <p>{translation?.fieldName}</p>
                                            <p>{translation?.businessTerm}</p>
                                        </div>
                                        <div className="w-3/12 mr-2">
                                            <p>{translation?.labelKey}</p>
                                            <p>
                                                {t(translation?.labelKey ?? '')}
                                            </p>
                                        </div>
                                        <div className="w-6/12">
                                            <p>{translation?.tooltipKey}</p>
                                            <p>
                                                {t(
                                                    translation?.tooltipKey ??
                                                        ''
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </OktaAuthWrapper>
    );
};

export default TranslationsReviewPage;
