import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import SingleNotice from './SingleNotice';
import { ConfiguratorFormDefinition } from '../../../types/generated/configuratorFormDefinition';
import saveAllowedDefinition from '../../../apiCalls/configurator/saveAllowedDefinitions';
import { TFunction } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

const TabNotices: FC<{
    definitionFields?: ConfiguratorFormDefinition[];
    t: TFunction;
}> = ({ definitionFields, t }) => {
    const { versionId, country } = useParams<{
        versionId: string;
        country: string;
    }>();

    const onChangeAllowed = async (
        value: boolean,
        definitionId: string,
        setError: React.Dispatch<React.SetStateAction<string | undefined>>
    ) => {
        const payload: ConfiguratorFormDefinition = {
            id: definitionId,
            isAllowed: value,
            isFinalized: false,
            isBaseDefinition: false,
            versionId: undefined,
            versionUniqueId: undefined,
            directiveKey: undefined,
            formTypeKey: undefined,
            noticeTypeKey: undefined,
            noticeSubtype: undefined,
            fields: undefined,
            activationDate: undefined,
            updatedBy: undefined,
            updatedOn: undefined,
        };

        const data = await saveAllowedDefinition(
            versionId ?? '',
            payload,
            setError
        );

        return data;
    };
    const { formconfiguratorChangesSprint4 } = useFlags();

    if (!definitionFields) return <div>No definitions fields</div>;
    return formconfiguratorChangesSprint4 ? (
        <div>
            <div className="flex text-dove text-caption p-5 pl-0 justify-between border-b border-alto break-words">
                <p className="w-1/12 flex justify-center">
                    {t('form-configurator:Allowed').toUpperCase()}
                </p>
                <p className="w-7/12 pl-5">
                    {t('form-configurator:Notices').toUpperCase()}
                </p>
                <p className="w-4/12 flex justify-center">
                    {t('form-configurator:Status').toUpperCase()}
                </p>
                <p className="w-6/12 flex justify-center">
                    {t('form-configurator:LastChanged').toUpperCase()}
                </p>
                <p className="w-6/12 flex justify-center">
                    {t('form-configurator:User').toUpperCase()}
                </p>
            </div>
            <div className="overflow-auto" style={{ height: '40vh' }}>
                {definitionFields
                    ?.sort(
                        (a, b) =>
                            Number(a.noticeSubtype) - Number(b.noticeSubtype)
                    )
                    .map((formDefinition) => (
                        <SingleNotice
                            onChangeAllowed={onChangeAllowed}
                            country={country}
                            key={formDefinition.id}
                            formDefinition={formDefinition}
                            t={t}
                        />
                    ))}
            </div>
        </div>
    ) : (
        <div>
            <div className="flex text-dove text-caption p-5 pl-0 justify-between border-b border-alto break-words">
                <p className="w-1/12 flex justify-center">
                    {t('form-configurator:Allowed').toUpperCase()}
                </p>
                <p className="w-7/12 pl-5">
                    {t('form-configurator:Notices').toUpperCase()}
                </p>
                <p className="w-4/12 flex justify-center">
                    {t('form-configurator:Status').toUpperCase()}
                </p>
            </div>
            <div className="overflow-auto" style={{ height: '40vh' }}>
                {definitionFields
                    ?.sort(
                        (a, b) =>
                            Number(a.noticeSubtype) - Number(b.noticeSubtype)
                    )
                    .map((formDefinition) => (
                        <SingleNotice
                            onChangeAllowed={onChangeAllowed}
                            country={country}
                            key={formDefinition.id}
                            formDefinition={formDefinition}
                            t={t}
                        />
                    ))}
            </div>
        </div>
    );
};

export default TabNotices;
