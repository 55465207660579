import React from 'react';

const NoAccessPage = () => (
    <div className="flex mx-16 flex-col-reverse sm:flex-row items-center justify-between sm:py-10 col-span-full">
        <div>
            <h1 className="mb-2">No access</h1>
            <p>You are not granted to access the MeForms test application.</p>
            <p>Normally, MeForms is expected to be used through some REP.</p>
            <p>
                Please contact the MeForms team if you think you should have
                direct access to the test application UI.
            </p>
        </div>
        <img
            src="/images/not-found.svg"
            className="w-1/2 mb-10 sm:mb-0"
            alt="No Access"
        />
    </div>
);

export default NoAccessPage;
