import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mercell/button-react';
import { SelectOption } from '../../types/generated/formSelectorConfiguration';
import { useStoreContext } from '../../state';
import { useCreateForm } from '../../hooks/administrativeHooks/useCreateForm';
import useFetchFormCreationProperties from '../../hooks/administrativeHooks/useFetchFormCreationProperties';
import { Dropdown } from '@mercell/dropdown-react';
import { Checkbox } from '@mercell/checkbox-react';
import { Input } from '@mercell/input-react';
import { getOtpUrlFromApi } from '../../authorization/getAuthFromApi';
import { formOptions } from '../../types/enums/formOptions';
import useFetchTenders from '../../hooks/administrativeHooks/useFetchTenders';
import useFetchPlatformNames from '../../hooks/administrativeHooks/useFetchPlatformNames';
import Toggle from '@mercell/toggle-react';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../redux/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';

const CreateForm: FC = () => {
    const { useStoreRedux1860 } = useFlags();
    const { t } = useTranslation([
        'form-content',
        'list.country',
        'list.legal-basis',
        'list.form-type',
        'list.notice-type',
        'list.notice-subtype',
        'list.language',
        'toast-content',
    ]);
    const [toggled, setToggled] = React.useState<boolean>(false);
    const [platformName, setPlatformName] = React.useState<string>();
    const [tenderId, setTenderId] = React.useState<SelectOption>();
    const [formIdSelected, setFormIdSelected] = React.useState<SelectOption>();
    const [country, setCountry] = useState<SelectOption>();
    const [directive, setDirective] = useState<SelectOption>();
    const [formType, setformType] = useState<SelectOption>();
    const [noticeType, setnoticeType] = useState<SelectOption>();
    const [noticeSubtype, setnoticeSubtype] = useState<SelectOption>();
    const [language, setLanguage] = useState<SelectOption>();
    const [manyLotsEnabled, setManyLotsEnabled] = useState<boolean>(true);
    const [changeNoticeEnabled, setChangeNoticeEnabled] =
        useState<boolean>(false);
    const [formData, setFormData] = useState<string>();
    const {
        state: { userId },
    } = useStoreContext();

    const reduxUserId = useAppSelector((state) => state.store.userId);

    const { platformNames } = useFetchPlatformNames();
    const { tenders } = useFetchTenders(t, platformName, true);
    const { data } = useFetchFormCreationProperties(
        country?.value,
        directive?.value,
        formType?.value,
        noticeType?.value
    );

    const { onSubmit } = useCreateForm({
        platformName,
        tenderId: tenderId?.value,
        country: (country as SelectOption)?.value,
        noticeSubtype: (noticeSubtype as SelectOption)?.value,
        language: (language as SelectOption)?.value,
        manyLotsEnabled,
        formId: (formIdSelected as SelectOption)?.value,
        changeNoticeEnabled,
        formData,
        onSuccessCallback: async (formId?: string) => {
            if (formId) {
                const url = await getOtpUrlFromApi(formId, 2, formOptions.form);
                window.open(url ?? '/login', '_blank')?.focus();
            }
        },
        onFailCallback: async (param?: string) => {
            if (param) {
                const errorMessage = t('form-content:UnExpectedFormSubtype', {
                    element: param,
                });
                toast.error(errorMessage);
            }
        },
        toastText: {
            onPendingText: t('toast-content:ToastCreatingFormPending'),
            onSuccessText: t('toast-content:ToastCreatingFormSuccess'),
        },
        t,
    });

    const onToggle = () => {
        setToggled(!toggled);
        setTenderId(undefined);
        setCountry(undefined);
        setDirective(undefined);
        setformType(undefined);
        setnoticeType(undefined);
        setnoticeSubtype(undefined);
        setLanguage(undefined);
        setFormData(undefined);
    };

    useEffect(() => {
        if (platformNames && !platformName) {
            setPlatformName(platformNames[0]);
        }
    }, [platformName, platformNames]);

    const headerValue = `${t('form-content:CreateFormForUser')} ${
        useStoreRedux1860 ? reduxUserId : userId
    }`;

    return (
        <div className="col-span-full px-6 justify-self-center xl:min-w-[800px] max-w-[800px] whitespace-normal">
            <h1>{headerValue}</h1>
            <form onSubmit={onSubmit}>
                <div className="mt-10 mb-10 -ml-2">
                    <Toggle
                        onLabel={t('form-content:CreateForm')}
                        offLabel={t('form-content:ChooseParent')}
                        checked={toggled}
                        onChange={onToggle}
                    />
                </div>
                <div className="mt-10 mb-10">
                    {platformNames && (
                        <div>
                            <label htmlFor="platformName">
                                {t('form-content:PlatformName')}
                            </label>
                            <Dropdown
                                id="platformName"
                                name="platformName"
                                options={platformNames?.map((pn: string) => ({
                                    value: pn,
                                    label: pn,
                                }))}
                                value={{
                                    value: platformNames[0],
                                    label: platformNames[0],
                                }}
                                onChange={(newValue: unknown) => {
                                    const selectedValue =
                                        newValue as SelectOption;
                                    setPlatformName(selectedValue.value);
                                    setCountry(undefined);
                                    setDirective(undefined);
                                    setformType(undefined);
                                    setnoticeType(undefined);
                                    setnoticeSubtype(undefined);
                                    setLanguage(undefined);
                                    setFormData(undefined);
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="mt-10 mb-10">
                    {tenders && toggled ? (
                        <div>
                            <label htmlFor="tenderId">
                                {t('form-content:TenderId')}
                            </label>
                            <Dropdown
                                id="tenderId"
                                name="tenderId"
                                value={tenderId ?? ''}
                                isClearable
                                onChange={(newValue: unknown) => {
                                    const selectedValue =
                                        newValue as SelectOption;
                                    setTenderId(selectedValue);
                                    setFormIdSelected(undefined);
                                    setCountry(undefined);
                                    setDirective(undefined);
                                    setformType(undefined);
                                    setnoticeType(undefined);
                                    setnoticeSubtype(undefined);
                                    setLanguage(undefined);
                                    setFormData(undefined);
                                }}
                                options={Object.keys(tenders).map((key) => ({
                                    value: key,
                                    label: tenders[key].name,
                                }))}
                                placeholder={t('form-content:ChooseTender')}
                            />
                            {Object.keys(tenders).length > 0 &&
                                tenderId?.value && (
                                    <div className="mt-10 mb-10">
                                        <label htmlFor="formId">
                                            {t('form-content:FormId')}
                                        </label>
                                        <Dropdown
                                            id="formId"
                                            name="formId"
                                            value={formIdSelected ?? ''}
                                            isClearable
                                            onChange={(newValue: unknown) => {
                                                const selectedValue =
                                                    newValue as SelectOption;
                                                setFormIdSelected(
                                                    selectedValue
                                                );

                                                const form = tenders[
                                                    tenderId.value ?? 0
                                                ].forms.find(
                                                    (a) =>
                                                        a.value ===
                                                        selectedValue.value
                                                );

                                                const formLanguage =
                                                    data?.languages.find(
                                                        (a) =>
                                                            a.value ===
                                                                form?.mainLanguage ??
                                                            ''
                                                    );

                                                setManyLotsEnabled(
                                                    form?.manyLots ?? false
                                                );

                                                setLanguage({
                                                    value: formLanguage?.value,
                                                    label: t(
                                                        formLanguage?.label ??
                                                            ''
                                                    ),
                                                });

                                                setCountry(undefined);
                                                setDirective(undefined);
                                                setformType(undefined);
                                                setnoticeType(undefined);
                                                setnoticeSubtype(undefined);
                                                setFormData(undefined);
                                            }}
                                            options={tenders[
                                                tenderId.value
                                            ].forms?.map((form) => ({
                                                value: form.value,
                                                label: form.name,
                                            }))}
                                            placeholder={t(
                                                'form-content:ChooseForm'
                                            )}
                                        />
                                    </div>
                                )}
                        </div>
                    ) : (
                        <Input
                            id="tenderId"
                            name="TenderId"
                            placeholder={t('form-content:TenderIdPlaceholder')}
                            onChange={(e) =>
                                setTenderId({
                                    value: e.target.value,
                                    label: e.target.value,
                                })
                            }
                        />
                    )}
                </div>
                <div className="mb-10">
                    <Checkbox
                        id="lots-enabled-create-form"
                        checked={manyLotsEnabled}
                        onChange={() => setManyLotsEnabled(!manyLotsEnabled)}
                    >
                        Split into lots
                    </Checkbox>
                </div>
                <div className="mb-10">
                    <label htmlFor="country">{t('form-content:Country')}</label>
                    <Dropdown
                        id="country"
                        name="country"
                        value={country ?? ''}
                        isClearable
                        onChange={(newValue: unknown) => {
                            const selectedValue = newValue as SelectOption;
                            setCountry(selectedValue);
                            setDirective(undefined);
                            setformType(undefined);
                            setnoticeType(undefined);
                            setnoticeSubtype(undefined);
                            setFormData(undefined);
                        }}
                        options={data?.countries.map((item) => ({
                            value: item.value,
                            label: t(item.label ?? ''),
                        }))}
                    />
                </div>

                <div className="mb-10">
                    <label htmlFor="directive">
                        {t('form-content:LegalBasis')}
                    </label>
                    <Dropdown
                        id="directive"
                        name="directive"
                        value={directive ?? ''}
                        isClearable
                        onChange={(newValue: unknown) => {
                            const selectedValue = newValue as SelectOption;
                            setDirective(selectedValue);
                            setformType(undefined);
                            setnoticeType(undefined);
                            setnoticeSubtype(undefined);
                            setFormData(undefined);
                        }}
                        options={data?.directives.map((item) => ({
                            value: item.value,
                            label: t(item.label ?? ''),
                        }))}
                    />
                </div>

                <div className="mb-10">
                    <label htmlFor="formType">
                        {t('form-content:FormTypeCode')}
                    </label>
                    <Dropdown
                        id="formType"
                        name="formType"
                        value={formType ?? ''}
                        isClearable
                        onChange={(newValue: unknown) => {
                            const selectedValue = newValue as SelectOption;
                            setformType(selectedValue);
                            setnoticeType(undefined);
                            setnoticeSubtype(undefined);
                            setFormData(undefined);
                        }}
                        options={data?.formTypes.map((item) => ({
                            value: item.value,
                            label: t(item.label ?? ''),
                        }))}
                    />
                </div>

                <div className="mb-10">
                    <label htmlFor="noticeType">
                        {t('form-content:NoticeTypeCode')}
                    </label>
                    <Dropdown
                        id="noticeType"
                        name="noticeType"
                        value={noticeType ?? ''}
                        isClearable
                        onChange={(newValue: unknown) => {
                            const selectedValue = newValue as SelectOption;
                            setnoticeType(selectedValue);
                            setnoticeSubtype(undefined);
                            setFormData(undefined);
                        }}
                        options={data?.noticeTypes.map((item) => ({
                            value: item.value,
                            label: t(item.label ?? ''),
                        }))}
                    />
                </div>

                <div className="mb-10">
                    <label htmlFor="noticeSubtype">
                        {t('form-content:NoticeSubtype')}
                    </label>
                    <Dropdown
                        id="noticeSubtype"
                        name="noticeSubtype"
                        value={noticeSubtype ?? ''}
                        isClearable
                        onChange={(newValue: unknown) => {
                            const selectedValue = newValue as SelectOption;
                            setnoticeSubtype(selectedValue);
                            setFormData(undefined);
                        }}
                        options={data?.noticeSubtypes.map((item) => ({
                            value: item.value,
                            label: t(item.label ?? ''),
                        }))}
                    />
                </div>

                <div className="mb-10">
                    <Checkbox
                        id="change-notice-enabled"
                        checked={changeNoticeEnabled}
                        onChange={() =>
                            setChangeNoticeEnabled(!changeNoticeEnabled)
                        }
                    >
                        Create change notice
                    </Checkbox>
                </div>

                <div className="mb-10">
                    <label htmlFor="language">
                        {t('form-content:SubmissionLanguageCode')}
                    </label>
                    <Dropdown
                        id="language"
                        name="language"
                        value={language ?? ''}
                        isClearable
                        onChange={(newValue: unknown) => {
                            const selectedValue = newValue as SelectOption;
                            setLanguage(selectedValue);
                            setFormData(undefined);
                        }}
                        options={data?.languages.map((item) => ({
                            value: item.value,
                            label: t(item.label ?? ''),
                        }))}
                    />
                </div>

                {language && (
                    <div className="mb-10">
                        <textarea
                            id="formData"
                            name="formData"
                            placeholder={t('form-content:JsonContent')}
                            className="h-36"
                            onChange={(e) => setFormData(e.target.value)}
                        />
                    </div>
                )}
                <Button
                    scheme="primary"
                    className="ml-auto"
                    type="submit"
                    data-test="create-form-button"
                >
                    Submit
                </Button>
            </form>
        </div>
    );
};
export default CreateForm;
