import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';
import { SelectOption } from '../../types/generated/selectOption';

const fetcher = (url: string) =>
    axios.get(url).then(async (res: AxiosResponse) => {
        const { data } = res;
        return data;
    });

const useFetchPortalStatuses = () => {
    const { data, error } = useSWRImmutable(
        '/dashboard/portal-statuses',
        fetcher,
        {
            shouldRetryOnError: false,
        }
    );

    return {
        portalStatuses: data?.statuses,
        isLoadingPlatformNames: !error && !data,
        isErrorFetchingPlatformNames: error,
    };
};

export default useFetchPortalStatuses;
