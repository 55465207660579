/* istanbul ignore file */
import axios, { AxiosResponse } from 'axios';
import { FormRenderResult } from '../../types/generated/formRenderResult';
import useSWRImmutable from 'swr/immutable';
import { FormDefinitionItem } from '../../types/generated/formDefinitionItem';
import { getFormLanguage } from '../../shared/storageService/sessionService';
import { useSWRConfig } from 'swr';
import { useEffect } from 'react';

export interface FormDataStateInterface {
    codelists: string[];
    formDefinition: FormDefinitionItem[];
    values: Record<string, any>;
    errors: { fieldErrors: Record<string, any>; formErrors: string[] };
    isCompleted: boolean;
    tenderName?: string;
}

const fetcher = (url: string) =>
    axios
        .get(url)
        .then(
            async (
                res: AxiosResponse<FormRenderResult>
            ): Promise<FormDataStateInterface> => {
                const { data } = res;
                const defaultValues = JSON.parse(data.values ?? '');

                return {
                    codelists: data.codeLists ?? [],
                    formDefinition: data.definition ?? [],
                    values: defaultValues,
                    errors: JSON.parse(data.errors ?? '{}'),
                    isCompleted: data.isCompleted,
                    tenderName: data.tenderName,
                };
            }
        );

const useFetchForm = (formId?: string, sectionId?: string) => {
    const language = getFormLanguage();
    const { cache } = useSWRConfig();
    const url =
        !formId || !language || !sectionId
            ? null
            : `form/render/${formId}/${language}${
                  sectionId ? `/${sectionId}` : ''
              }`;
    const { data, error, mutate } = useSWRImmutable(url, fetcher, {
        shouldRetryOnError: false,
    });
    useEffect(
        () => () => {
            cache.delete(url);
        },
        [cache, url]
    );
    return {
        mutateFetchedForm: mutate,
        formData: data,
        isLoadingForm: formId && !error && !data,
        isErrorFetchingForm: error,
        tenderName: data?.tenderName,
    };
};
export default useFetchForm;
