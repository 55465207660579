import { FieldStructure, TextareaFieldStructure } from '@mercell/form-react';
import { FormDefinitionItem } from '../../types/generated/formDefinitionItem';
import { getRulesSetup } from '../getRulesSetup';
import * as yup from 'yup';
import { TFunction } from 'react-i18next';

export const mapTextareaField = (
    formDefinition: FormDefinitionItem,
    genericFieldStructure: FieldStructure,
    t: TFunction<string, unknown>
): TextareaFieldStructure => {
    if (genericFieldStructure.associatedValidationBasedOnOtherFieldValues) {
        if (
            formDefinition.conditionalValidation?.rules &&
            genericFieldStructure.associatedValidationBasedOnOtherFieldValues
        ) {
            const conditionalValidations = getRulesSetup(
                yup.string().nullable(),
                formDefinition.conditionalValidation.rules,
                t
            );
            genericFieldStructure.associatedValidationBasedOnOtherFieldValues.rules =
                conditionalValidations;
        }
    }
    return {
        ...genericFieldStructure,
        type: 'textarea',
        validations: formDefinition.rules
            ? getRulesSetup(yup.string().nullable(), formDefinition.rules, t)
            : undefined,
    };
};
