import { ReactNode } from 'react';
import i18next from 'i18next';
import { FieldOrigin, ReadOnlyFieldStructure } from '@mercell/form-react';
import IPathItem from '../types/pathItem';
import { TFunction } from 'react-i18next';
import { mapSelectReadOnly } from './fields/select';
import { mapCPVSelectorReadOnly } from './fields/cpvSelector';
import { mapDatepickerReadOnly } from './fields/datepicker';
import { mapInputWithDropdownReadOnly } from './fields/inputWithDropdown';
import { mapNumberReadOnly } from './fields/number';
import { mapRadioReadOnly } from './fields/radio';
import { DateFns } from './dateFnsFormats';

export const prepareContent = (
    content: any,
    fieldOrigin: FieldOrigin,
    readOnlyProperties: ReadOnlyFieldStructure,
    name: string,
    t: TFunction<string, unknown>,
    dateFormat: DateFns,
    locale?: Locale,
    bugfixMef1809?: any,
    useDateRange1736?: boolean
): ReactNode => {
    const { language } = i18next;

    const {
        isSortable,
        isMultiSelect,
        showAsLabel,
        translationFileName,
        options,
        isTranslatable,
        showWithTime,
    } = readOnlyProperties;

    if (content === undefined || (bugfixMef1809 && content === null)) {
        return null;
    }

    const translatedContent = translationFileName
        ? t(`${translationFileName}:${content}`)
        : content;

    switch (fieldOrigin) {
        case 'readOnly': {
            return t(content);
        }
        case 'text':
        case 'textarea':
            return isTranslatable ? t(content) : content;
        case 'nuts-selector':
        case 'checkbox':
        case 'radio':
            return mapRadioReadOnly(
                translationFileName,
                content,
                options,
                translatedContent
            );
        case 'select':
            return mapSelectReadOnly(
                isMultiSelect,
                isSortable,
                translationFileName,
                content,
                options,
                translatedContent,
                t
            );
        case 'input-dropdown':
            return mapInputWithDropdownReadOnly(
                content,
                translationFileName,
                t
            );
        case 'number':
            return mapNumberReadOnly(content, language);
        case 'date-time-picker':
            return mapDatepickerReadOnly(
                content,
                showWithTime,
                dateFormat,
                locale,
                useDateRange1736
            );
        case 'cpv-selector':
            return mapCPVSelectorReadOnly(content, t);
        case 'array':
            if (showAsLabel) {
                const translatablePathItems = content.filter(
                    (item: IPathItem) => item.ChangeIsTranslatable
                );
                const pathStringArray: string[] = [];

                translatablePathItems.forEach((item: IPathItem) => {
                    if (item.ChangePathLabel !== '') {
                        pathStringArray.push(
                            t(item.ChangePathLabel ?? item.ChangePathValue)
                        );
                    }
                });

                return pathStringArray.join(' - ');
            }
            return content;

        default:
            return content;
    }
};
