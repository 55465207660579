import React, { FC, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ValidationErrorObject } from '../../types/validationError';
import { useValidate } from '../../shared/validationModule/useValidate';
import cx from 'classnames';
import useFetchSections from '../../hooks/formHooks/useFetchSections';
import { useStoreContext } from '../../state';
import FormTranslationReviewPage from './FormTranslationReviewPage';
import { FormStatus } from '../../types/generated/formMenuResult';
import LoadingSpinner from '@mercell/loading-spinner-react';
import useFetchSessionInfo from '../../hooks/formHooks/useFetchSessionInfo';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import useSetupContext from '../../shared/setupUserContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setNoticeStatus } from '../../redux/storeSlice';

const FormPage: FC = () => {
    const {
        useRevalidateOnFocus1734,
        useRenderSectionErrorsSubArrayLevel,
        notUseHardcodedSectionsSprint5,
        useStoreRedux1860,
    } = useFlags();
    const { formId, parentSectionId, sectionId, parentArrayElementId } =
        useParams<{
            formId: string;
            parentSectionId: string;
            sectionId: string;
            parentArrayElementId?: string;
        }>();
    const [backendValidationErrors, setBackendValidationErrors] =
        useState<ValidationErrorObject>({
            submittedErrors: {},
            renderedFieldsWithErrors: {},
            errorObjectWithPaths: {},
            isFormValidatedWithoutErrors: false,
        });
    const {
        state: { noticeStatus },
        dispatch,
    } = useStoreContext();
    const reduxNoticeStatus = useAppSelector(
        (state) => state.store.noticeStatus
    );
    const reduxDispatch = useAppDispatch();

    const [refetchStatus, setRefetchStatus] = useState<boolean>(false);

    const [shouldFetchValidation, setShouldFetchValidation] =
        useState<boolean>(false);

    const { t } = useTranslation([
        'form-content',
        'toast-content',
        'list.audit-log-status',
        'list.language',
        'list.exclusion-ground',
    ]);

    const {
        sections,
        isFormPreview,
        setHotReloadOfMenu,
        parentElementTitleKey,
        navigationTitle,
        isErrorFetchingSections,
        currentFormStatus,
        FormMainLanguage,
        AdditionalLanguages,
    } = useFetchSections(
        formId,
        parentArrayElementId,
        useRevalidateOnFocus1734
    );

    const {
        validate: validateMethod,
        isLoadingFetchingValidations,
        isErrorFetchingValidations,
    } = useValidate(
        setBackendValidationErrors,
        formId || '',
        parentSectionId || '',
        sectionId || '',
        t,
        shouldFetchValidation,
        setHotReloadOfMenu,
        setRefetchStatus,
        useRenderSectionErrorsSubArrayLevel,
        notUseHardcodedSectionsSprint5
    );
    const ldClient = useLDClient();
    const { sessionInfo } = useFetchSessionInfo();
    const { multiContext, isValid } = useSetupContext(sessionInfo);

    useEffect(() => {
        if (isValid && multiContext) {
            ldClient
                ?.waitForInitialization()
                .then(() => {
                    ldClient?.identify(multiContext);
                })
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.warn(err);
                });
        }
    }, [isValid, multiContext, ldClient]);

    const isFormReceivedTranslation =
        currentFormStatus === FormStatus.TranslationReceived;

    const isFormDispatchedForTranslation =
        currentFormStatus === FormStatus.DispatchedForTranslation;

    useEffect(() => {
        if (useStoreRedux1860) {
            if (
                currentFormStatus !== undefined &&
                reduxNoticeStatus?.status !== FormStatus[currentFormStatus]
            ) {
                const formStatus = t(
                    `list.audit-log-status:${FormStatus[currentFormStatus]}`
                );
                reduxDispatch(
                    setNoticeStatus({
                        value: currentFormStatus,
                        status: formStatus,
                    })
                );
            }
        } else if (
            currentFormStatus !== undefined &&
            noticeStatus?.status !== FormStatus[currentFormStatus]
        ) {
            const formStatus = t(
                `list.audit-log-status:${FormStatus[currentFormStatus]}`
            );
            dispatch({
                type: 'SET_NOTICE_STATUS',
                payload: {
                    noticeStatus: {
                        value: currentFormStatus,
                        status: formStatus,
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFormStatus, refetchStatus]);

    if (!formId) return <div>No form chosen...</div>;

    if (isFormDispatchedForTranslation) {
        return (
            <div className="col-span-full h-full flex flex-col items-center justify-center gap-y-12">
                <LoadingSpinner />
                <p className="text-studio font-semibold w-[30%] text-center">
                    {t('form-content:FetchTranslationPleaseWait')}
                </p>
            </div>
        );
    }

    if (isFormReceivedTranslation) {
        return (
            <FormTranslationReviewPage
                formId={formId}
                isLoadingFetchingValidations={isLoadingFetchingValidations}
                backendValidationErrors={backendValidationErrors}
                validateMethod={validateMethod}
                t={t}
                isFormPreview={isFormPreview}
                firstLanguage={FormMainLanguage ?? 'ENG'}
                secondLanguage={(AdditionalLanguages ?? ['ENG'])[0]}
            />
        );
    }

    return (
        <div
            className={cx(
                'col-span-full grid grid-cols-[repeat(11,1fr)] grid-rows-[1fr]'
            )}
        >
            <Outlet
                context={{
                    isErrorFetchingSections,
                    currentFormStatus,
                    setBackendValidationErrors,
                    backendValidationErrors,
                    sections,
                    isFormPreview,
                    setHotReloadOfMenu,
                    parentElementTitleKey,
                    navigationTitle,
                    validateMethod,
                    isLoadingFetchingValidations,
                    isErrorFetchingValidations,
                    setShouldFetchValidation,
                    shouldFetchValidation,
                }}
            />
        </div>
    );
};

FormPage.displayName = 'FormPage';
FormPage.whyDidYouRender = true;
export default FormPage;
