import {
    FieldStructure,
    InputDropdownFieldStructure,
} from '@mercell/form-react';
import {
    FormDefinitionItem,
    FormOptionItem,
} from '../../types/generated/formDefinitionItem';
import { getRulesSetup } from '../getRulesSetup';
import * as yup from 'yup';
import { TFunction } from 'react-i18next';

export const mapInputWithDropdownField = (
    formDefinition: FormDefinitionItem,
    genericFieldStructure: FieldStructure,
    t: TFunction<string, unknown>
): InputDropdownFieldStructure => {
    const ValueField = formDefinition?.fields?.filter(
        (x) => x.name === 'Value'
    )[0];

    const OptionsField = formDefinition?.fields?.filter(
        (x) => x.name === 'Options'
    )[0];

    let options: FormOptionItem[] = [];

    if (OptionsField?.options) {
        options = OptionsField?.options.map((option: FormOptionItem) => {
            const formOption: FormOptionItem = {
                label: t(`${option.label}`),
                value: option.value,
                parentId: option.parentId,
                addDefaultTag: option.addDefaultTag,
            };
            return formOption;
        });

        if (formDefinition.isSortable ?? true) {
            options.sort((a, b) => a.label.localeCompare(b.label));
        }
    }

    if (
        ValueField?.conditionalValidation?.rules ||
        OptionsField?.conditionalValidation?.rules
    ) {
        const conditionalValidations = yup.object().shape({
            inputValue: ValueField?.rules
                ? getRulesSetup(yup.string().nullable(), ValueField?.rules, t)
                : yup.string().nullable(),
            selectedOptions: OptionsField?.rules
                ? getRulesSetup(yup.string().nullable(), OptionsField?.rules, t)
                : yup.string().nullable(),
        });
        if (genericFieldStructure.associatedValidationBasedOnOtherFieldValues) {
            genericFieldStructure.associatedValidationBasedOnOtherFieldValues.rules =
                conditionalValidations;
        }
    }

    return {
        ...genericFieldStructure,
        type: 'input-dropdown',
        dropdownSide: 'left',
        dropdownProps: {
            placeholder: t('form-content:Select'),
            isClearable: true,
            innerPropsOfValueTag: { 'data-test': 'unit' },
        },
        dropdownMaxWidth: '50%',
        optionsList: () =>
            options.map((option) => ({
                label: option.label,
                value: option.value,
            })),
        validations:
            ValueField?.rules || OptionsField?.rules
                ? yup.object().shape({
                      inputValue: ValueField?.rules
                          ? getRulesSetup(
                                yup.string().nullable(),
                                ValueField?.rules,
                                t
                            )
                          : yup.string().nullable(),
                      selectedOptions: OptionsField?.rules
                          ? getRulesSetup(
                                yup.string().nullable(),
                                OptionsField?.rules,
                                t
                            )
                          : yup.string().nullable(),
                  })
                : undefined,
    };
};

export const mapInputWithDropdownReadOnly = (
    content: any,
    translationFileName: string,
    t: TFunction<string, unknown>
) =>
    `${content.inputValue} ${
        translationFileName
            ? t(`${translationFileName}:${content.selectedOptions}`)
            : content.selectedOptions
    }`;
