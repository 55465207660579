import React, { FC, useEffect, useState } from 'react';
import { getAdminToken } from '../shared/storageService/cookieService';
import { useStoreContext } from '../state';
import { getAdminTokenFromApi } from './getAuthFromApi';
import LoadingSpinner from '@mercell/loading-spinner-react';
import { useAppSelector } from '../redux/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface AdminAuthWrapperProps {
    children: React.ReactElement;
}
// this wrapper should wrap component that requires okta authorization
const AdminAuthWrapper: FC<AdminAuthWrapperProps> = ({ children }) => {
    const [hasToken, setHasToken] = useState<boolean>(false);
    const { useStoreRedux1860 } = useFlags();
    const {
        state: { userId, role, UserRights },
    } = useStoreContext();

    const reduxUserId = useAppSelector((state) => state.store.userId);
    const reduxRole = useAppSelector((state) => state.store.role);
    const reduxUserRights = useAppSelector((state) => state.store.userRights);

    useEffect(() => {
        (async () => {
            await getAdminTokenFromApi({
                name: useStoreRedux1860
                    ? reduxUserRights?.allowedPlatforms?.[0]
                    : UserRights?.allowedPlatforms?.[0],
                userId: useStoreRedux1860 ? reduxUserId : userId,
                role: useStoreRedux1860 ? reduxRole : role,
                formUserRights: 2,
            });
            if (getAdminToken()) setHasToken(true);
        })();
    }, [
        userId,
        role,
        useStoreRedux1860,
        reduxUserRights?.allowedPlatforms,
        reduxUserId,
        reduxRole,
        UserRights?.allowedPlatforms,
    ]);
    if (!hasToken)
        return (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <LoadingSpinner />
            </div>
        );
    return children;
};

export default AdminAuthWrapper;
