import Cookies from 'js-cookie';
import React, { FC, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import {
    getCurrentFormId,
    setCurrentFormId,
} from '../shared/storageService/sessionService';
import { useStoreContext } from '../state';
import roles from '../state/roles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setUserRole } from '../redux/storeSlice';

const ProtectedRepRoute: FC = () => {
    const location = useLocation();
    const { useStoreRedux1860 } = useFlags();
    const {
        state: { userRole },
        dispatch,
    } = useStoreContext();

    const reduxDispatch = useAppDispatch();

    const reduxUserRole = useAppSelector((state) => state.store.userRole);

    useEffect(() => {
        if (!getCurrentFormId()) {
            const urlParams: string[] = location.pathname.split('/');
            const cookies = Cookies.get();

            urlParams.forEach((param) => {
                if (cookies[param]) {
                    setCurrentFormId(param);
                }
            });
        }
        if (getCurrentFormId()) {
            if (useStoreRedux1860) {
                reduxDispatch(setUserRole(roles.rep));
            } else {
                dispatch({
                    type: 'SET_ROLE',
                    payload: { userRole: roles.rep },
                });
            }
        }
    }, [dispatch, location.pathname, reduxDispatch, useStoreRedux1860]);

    if (useStoreRedux1860) {
        if (reduxUserRole === roles.unauthorized) return null;
        if (reduxUserRole !== roles.rep)
            return <Navigate to="/unauthorized" replace />;
    } else {
        if (userRole === roles.unauthorized) return null;
        if (userRole !== roles.rep)
            return <Navigate to="/unauthorized" replace />;
    }
    return <Outlet />;
};

export default ProtectedRepRoute;
