import { WarningSquare32 } from '@carbon/icons-react';
import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import useFetchValidation from '../../hooks/administrativeHooks/useFetchValidation';
import NotFoundPage from '../NotFound/NotFoundPage';
import LoadingSpinner from '@mercell/loading-spinner-react';
import { useTranslation } from 'react-i18next';
import { formOptions } from '../../types/enums/formOptions';

const ValidationPageFull = () => {
    const location = useLocation();

    const useSlowSchematron =
        location.pathname.includes(formOptions.validationFull) ||
        location.pathname.includes(formOptions.validationSchematron);
    const onlySchematron = location.pathname.includes(
        formOptions.validationSchematron
    );

    const { formId } = useParams<{
        formId: string;
    }>();
    const { validation, isErrorFetchingValidation, isLoadingValidation } =
        useFetchValidation(formId ?? '', useSlowSchematron, onlySchematron);
    const { t } = useTranslation(['rules', 'form-content']);

    if (isLoadingValidation)
        return (
            <div className="col-span-full h-full flex items-center justify-center">
                <LoadingSpinner />
            </div>
        );

    if (isErrorFetchingValidation)
        return (
            <NotFoundPage
                wrapperClass="col-span-full"
                errorCode={isErrorFetchingValidation.status.toString()}
            />
        );

    return (
        <div className="col-span-full mx-6">
            {validation?.length === 0 ? (
                <div className="mb-1 flex">
                    <p className="text-well-read">
                        <b>{t('NoErrorsToFix')}</b>
                    </p>
                </div>
            ) : (
                validation?.map((v, i) => (
                    <div key={v.error + i.toString()} className="mb-5">
                        <div className="flex justify-center mb-5">
                            <WarningSquare32 />
                        </div>
                        {Object.entries(v).map(([key, value]) => (
                            <div key={key + value} className="mb-1 flex">
                                <p className="text-important-accent underline">
                                    {key.toLocaleUpperCase()}
                                </p>{' '}
                                <p className="text-well-read">
                                    :{' '}
                                    {key === 'error' ? (
                                        <b>{t(`${value}`)}</b>
                                    ) : (
                                        value
                                    )}
                                </p>
                            </div>
                        ))}
                    </div>
                ))
            )}
        </div>
    );
};

export default ValidationPageFull;
