/* istanbul ignore file */
import axios, { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { FormTextsResult } from '../../types/generated/formTextsResult';
import { AutoSaveStatus } from '../../state/autoSaveStatus';
import { delay } from '../../shared/utilities/delay';
import { useStoreContext } from '../../state';
import { useAppDispatch } from '../../redux/hooks';
import {
    setAutoSaveLoading,
    setaAutoSaveError,
    setaAutoSaveSaved,
} from '../../redux/storeSlice';

interface SubmitReviewTranslationsProps {
    formId?: string;
    setTranslationsApproved: Dispatch<SetStateAction<boolean | undefined>>;
    reviewedLanguage: string;
    setIsLoadingTranslationsApproval: Dispatch<SetStateAction<boolean>>;
    useStoreRedux1860?: boolean;
}
const axiosPost = (
    url: string,
    formData?: FormTextsResult
): Promise<any | undefined> =>
    axios.post(url, formData).then((res: AxiosResponse): number => res.status);

interface SubmitReturn {
    onSubmit: (
        data: FormTextsResult,
        isSaving: boolean
    ) => Promise<string | undefined>;
}

export const useSubmitReviewTranslations = (
    props: SubmitReviewTranslationsProps
): SubmitReturn => {
    const {
        formId,
        reviewedLanguage,
        setIsLoadingTranslationsApproval,
        setTranslationsApproved,
        useStoreRedux1860,
    } = props;
    const reduxDispatch = useAppDispatch();
    const { dispatch } = useStoreContext();
    const onSubmit = useCallback(
        async (data: FormTextsResult, isSaving = true): Promise<any> => {
            let result;

            if (isSaving) {
                if (useStoreRedux1860) {
                    reduxDispatch(setAutoSaveLoading());
                } else {
                    dispatch({
                        type: 'SET_AUTO_SAVE',
                        payload: {
                            autoSave: {
                                isLoading: true,
                                isError: false,
                                isSaved: false,
                            },
                        },
                    });
                }
            }
            try {
                if (!isSaving) {
                    setIsLoadingTranslationsApproval(true);
                }
                result = await axiosPost(
                    `form/texts/save/${
                        formId ?? ''
                    }/${reviewedLanguage}/${!isSaving}`,
                    data
                );

                if (!isSaving) {
                    setIsLoadingTranslationsApproval(false);
                }

                if (isSaving) {
                    await delay(2000);
                    if (useStoreRedux1860) {
                        reduxDispatch(setaAutoSaveSaved());
                    } else {
                        dispatch({
                            type: 'SET_AUTO_SAVE',
                            payload: {
                                autoSave: {
                                    isLoading: false,
                                    isError: false,
                                    isSaved: true,
                                },
                            },
                        });
                    }
                }
            } catch {
                if (!isSaving) {
                    setIsLoadingTranslationsApproval(false);
                    setTranslationsApproved(false);
                } else if (isSaving) {
                    if (useStoreRedux1860) {
                        reduxDispatch(setaAutoSaveError());
                    } else {
                        dispatch({
                            type: 'SET_AUTO_SAVE',
                            payload: {
                                autoSave: {
                                    isLoading: false,
                                    isError: true,
                                    isSaved: false,
                                },
                            },
                        });
                    }
                }
            }

            if (setTranslationsApproved && result === 200 && !isSaving)
                setTranslationsApproved(true);

            return undefined;
        },
        [
            setTranslationsApproved,
            useStoreRedux1860,
            reduxDispatch,
            dispatch,
            formId,
            reviewedLanguage,
            setIsLoadingTranslationsApproval,
        ]
    );
    return { onSubmit };
};
