import React, { FC, useCallback, useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';
import i18next from 'i18next';

import { useStoreContext } from '../../state';
import {
    getAdminTokenFromApi,
    getOtpUrlFromApi,
} from '../../authorization/getAuthFromApi';
import { deleteFormFromDB } from '../../apiCalls/deleteForm';
import { Button } from '@mercell/button-react';
import {
    DataFormat16,
    Edit16,
    TrashCan16,
    Warning32,
    Checkmark32,
    Catalog32,
    Save32,
    Copy16,
    DataShare32,
    CloudDownload32,
    Upload16,
    Html16,
    Pdf16,
    Locked16,
    Unlocked16,
    TreeView32,
} from '@carbon/icons-react';
import { Dropdown } from '@mercell/dropdown-react';
import { SelectOption } from '../../types/generated/selectOption';
import ConfirmationModal from '../../components/ConfirmationModal';
import { saveFormStatus } from '../../apiCalls/saveFormStatus';
import { upgradeForm } from '../../apiCalls/upgradeForm';
import { DropdownOption } from '../../types/generated/dropdownOption';
import { formOptions } from '../../types/enums/formOptions';
import { compareForms } from '../../apiCalls/compareForms';
import { publishForm } from '../../apiCalls/publishForm';
import UserCustomization from './UserCustomization';
import cx from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FormListItem, TenderListItem } from '../../types/tenderList';
import { debounce } from 'lodash';
import { translateForm } from '../../apiCalls/translateForm';
import { lockUnlockForm } from '../../apiCalls/lockUnlockForm';
import { KeyedMutator } from 'swr';
import { FormStatusResult } from '../../types/generated/formStatusResult';
import { fetchTenders } from '../../apiCalls/fetchTenders';
import { convertLanguageToCode } from '../../shared/utilities/languageToLocaleConvert';
import { previewPdf } from '../../apiCalls/previewPdf';
import { useAppSelector } from '../../redux/hooks';

interface SelectFormProps {
    t: TFunction;
    onChangeTender: (newValue: TenderListItem | null) => void;
    onChangeStatus: (newValue: SelectOption | null) => void;
    onChangeForm: (newValue: FormListItem | null) => void;
    platformName?: string;
    formUserRights: number;
    tender: TenderListItem | null;
    status: SelectOption | null;
    form: FormListItem | null;
    platformNames: string[];
    onChangePlatformName: (e: any) => void;
    onChangeFormUserRights: (e: any) => void;
    currentStatus?: string;
    statuses?: DropdownOption[];
    mutateFormStatuses?: KeyedMutator<FormStatusResult>;
}

const SelectForm: FC<SelectFormProps> = ({
    t,
    onChangeTender,
    onChangeStatus,
    onChangeForm,
    platformName,
    formUserRights,
    tender,
    status,
    form,
    platformNames,
    onChangePlatformName,
    onChangeFormUserRights,
    currentStatus,
    statuses,
    mutateFormStatuses,
}) => {
    const { useFetchTendersDynamic = true, useStoreRedux1860 } = useFlags();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const [isPublishModalVisible, setIsPublishModalVisible] =
        useState<boolean>(false);
    const [, setIsCopyModalVisible] = useState<boolean>(false);
    const [selectKey, setSelectKey] = useState<any>(undefined);
    const [tenderOptions, setTenderOptions] = useState<any>();
    const { language } = i18next;

    const {
        state: { userId, role },
    } = useStoreContext();

    const reduxUserId = useAppSelector((state) => state.store.userId);
    const reduxRole = useAppSelector((state) => state.store.role);

    useEffect(() => {
        const fetchData = async () => {
            const data: any = await fetchTenders(
                t,
                platformName,
                false,
                undefined,
                useFetchTendersDynamic
            );
            const options = Object.keys(data).map((tenderListItem) => ({
                ...data[tenderListItem],
                value: tenderListItem,
                label: data[tenderListItem].name,
            }));
            return options;
        };
        fetchData().then((res) => setTenderOptions(res));
    }, [platformName, t, useFetchTendersDynamic]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadOptionsDebounced = useCallback(
        debounce((inputValue: string, callback: (options: any) => void) => {
            fetchTenders(
                t,
                platformName,
                false,
                inputValue,
                useFetchTendersDynamic
            ).then((data) => {
                const options = Object.keys(data).map((tenderListItem) => ({
                    ...data[tenderListItem],
                    value: tenderListItem,
                    label: data[tenderListItem].name,
                }));
                callback(options);
            });
        }, 2500),
        [t, platformName, useFetchTendersDynamic]
    );

    const generateAdminToken = (forCopy = false) =>
        getAdminTokenFromApi({
            name: platformName,
            userId: useStoreRedux1860 ? reduxUserId : userId,
            formId: forCopy ? undefined : form?.value,
            tenderId: tender?.value,
            formUserRights: forCopy ? 2 : formUserRights,
            role: useStoreRedux1860 ? reduxRole : role,
        });

    const deleteForm = async () => {
        await generateAdminToken();
        if (form?.value) {
            await deleteFormFromDB(form.value, {
                onPendingText: t('toast-content:ToastDeleteFormPending'),
                onSuccessText: t('toast-content:ToastDeleteFormSuccess'),
            }).then(() => {
                const formToDelete = tender?.forms.find(
                    (deletedForm) => deletedForm.value === form.value
                );
                if (formToDelete && tender) {
                    const indexToRemove = tender?.forms.indexOf(formToDelete);
                    if (indexToRemove !== -1) {
                        tender?.forms.splice(indexToRemove, 1);
                        onChangeForm(null);
                    }
                }
                if (tender && tender.forms.length < 1) {
                    onChangeTender(null);
                    setSelectKey(formToDelete?.value);
                }
            });
        }
    };

    const onSaveFormStatus = async () => {
        if (form?.value && status?.value?.toString()) {
            saveFormStatus(form.value, status.value.toString(), {
                onPendingText: t('toast-content:ToastSaving'),
                onSuccessText: t('toast-content:ToastSaveNoticeStatusSuccess'),
            }).then(() => {
                if (mutateFormStatuses) {
                    mutateFormStatuses();
                }
            });
        }
    };

    const onFormUpgrade = async () => {
        if (form?.value) {
            upgradeForm(form.value, {
                onPendingText: t('toast-content:ToastSaving'),
                onSuccessText: t('toast-content:ToastFormUpgradeSuccess'),
            }).then(() => {
                // empty
            });
        }
    };

    const onGoToForm = async (option: formOptions) => {
        await generateAdminToken();
        if (form?.value) {
            const otpUrl = await getOtpUrlFromApi(
                form.value,
                formUserRights,
                option
            );

            // if using https://dev.mercellforms.mercell.com/ for backend uncomment this and bellow replace "otpUrl" with "url"
            // const url = otpUrl.replace(
            //     'https://dev.mercellforms.mercell.com/',
            //     'http://localhost:3000/'
            // );

            window.open(otpUrl ?? '/login', '_blank')?.focus();
        }
    };

    const onAuditLog = async () => {
        await generateAdminToken();
        window.open(
            form?.value ? `form/auditlog/${form.value}` : '/login',
            '_blank'
        );
    };

    const onPublicationStatuses = async () => {
        await generateAdminToken();
        window.open(
            form?.value ? `form/publicationtask/${form.value}` : '/login',
            '_blank'
        );
    };

    const onDocumentFamily = async () => {
        await generateAdminToken();
        window.open(
            form?.value ? `form/family/${form.value}` : '/login',
            '_blank'
        );
    };

    const onPreviewHtml = async () => {
        await generateAdminToken();
        window.open(
            form?.value ? `form/previewhtml/${form.value}` : '/login',
            '_blank'
        );
    };

    const onPreviewPdf = async () => {
        await generateAdminToken();

        const languageCode = convertLanguageToCode(language);
        previewPdf(form?.value, languageCode, {
            onPendingText: t('toast-content:ToastDownloading'),
            onSuccessText: t('toast-content:ToastDownloadingSuccess'),
        });
    };

    const onDeleteForm = async () => {
        await generateAdminToken();
        setIsModalVisible(true);
    };

    const onConfirmDelete = () => {
        deleteForm();
        setIsModalVisible(false);
    };

    const onLockForm = async () => {
        await getAdminTokenFromApi({
            name: platformName,
            userId: useStoreRedux1860 ? reduxUserId : userId,
            formId: form?.value,
            tenderId: tender?.value,
            formUserRights: 2,
            role: useStoreRedux1860 ? reduxRole : role,
        });
        lockUnlockForm(form?.value, 'true', {
            onPendingText: t('toast-content:ToastLockFormPending'),
            onSuccessText: t('toast-content:ToastLockFormSuccess'),
        });
    };

    const onUnlockForm = async () => {
        await getAdminTokenFromApi({
            name: platformName,
            userId: useStoreRedux1860 ? reduxUserId : userId,
            formId: form?.value,
            tenderId: tender?.value,
            formUserRights: 2,
            role: useStoreRedux1860 ? reduxRole : role,
        });
        lockUnlockForm(form?.value, 'false', {
            onPendingText: t('toast-content:ToastUnlockFormPending'),
            onSuccessText: t('toast-content:ToastUnlockFormSuccess'),
        });
    };

    // const onCopyForm = async () => {
    //     await generateAdminToken(true);
    //     setIsCopyModalVisible(true);
    // };

    const onPublishForm = async () => {
        await getAdminTokenFromApi({
            name: platformName,
            userId: useStoreRedux1860 ? reduxUserId : userId,
            formId: form?.value,
            tenderId: tender?.value,
            formUserRights: 2,
            role: useStoreRedux1860 ? reduxRole : role,
        });
        setIsPublishModalVisible(true);
    };

    const onTranslateForm = async () => {
        await getAdminTokenFromApi({
            name: platformName,
            userId: useStoreRedux1860 ? reduxUserId : userId,
            formId: form?.value,
            tenderId: tender?.value,
            formUserRights: 2,
            role: useStoreRedux1860 ? reduxRole : role,
        });
        translateForm(
            form?.value,
            {
                onPendingText: t('toast-content:ToastTranslateFormPending'),
                onSuccessText: t('toast-content:ToastTranslateFormSuccess'),
            },
            undefined,
            false
        );
    };

    const onConfirmPublish = async () => {
        if (form?.value) {
            setIsPublishModalVisible(false);
            publishForm(form.value, {
                onSuccessText: t('toast-content:ToastPublishFormSuccess'),
                onPendingText: t('toast-content:ToastPublishFormPending'),
            });
        }
    };

    const onCompare = async () => {
        if (form?.value) {
            compareForms(form.value);
        }
    };

    return (
        <>
            <h1>{t('title')}</h1>
            <UserCustomization
                t={t}
                platformNames={platformNames ?? []}
                onChangePlatformName={onChangePlatformName}
                onChangeFormUserRights={onChangeFormUserRights}
                formUserRights={formUserRights}
            />
            {isModalVisible && (
                <ConfirmationModal
                    cancelText={t('form-content:LabelCancel')}
                    confirmText={t('form-content:LabelDelete')}
                    onConfirm={onConfirmDelete}
                    closeModal={() => setIsModalVisible(false)}
                    isModalVisible={isModalVisible}
                    title={t('form-content:ConfirmDelete')}
                >
                    <p className="text-center">
                        {t('form-content:DeleteConfirmation')}
                    </p>
                </ConfirmationModal>
            )}
            {isPublishModalVisible && (
                <ConfirmationModal
                    text={t('form-content:PublishConfirmation')}
                    cancelText={t('form-content:LabelCancel')}
                    confirmText={t('form-content:PublishForm')}
                    onConfirm={onConfirmPublish}
                    closeModal={() => setIsPublishModalVisible(false)}
                    isModalVisible={isPublishModalVisible}
                >
                    <Warning32
                        style={{
                            width: 150,
                            height: 150,
                            margin: '30px auto',
                            color: 'orange',
                        }}
                    />
                </ConfirmationModal>
            )}
            <div className="grid grid-cols-2 w-full mt-10 ">
                <div className="col-span-2">
                    <label htmlFor="tenderId">
                        {t('form-content:LabelTender')}
                    </label>
                    <Dropdown
                        key={selectKey}
                        id="tenderId"
                        className="mt-4"
                        name="tenderId"
                        isClearable
                        value={tender ?? null}
                        styles={{
                            loadingMessage: (styles) => {
                                styles.color = 'white';
                                return styles;
                            },
                            noOptionsMessage: (styles) => {
                                styles.color = 'white';
                                return styles;
                            },
                            menuList: (styles, state) => {
                                if (state.options.length < 1) {
                                    styles.background = 'var(--minsk)';
                                    styles.color = 'white';
                                    styles.borderRadius = '0.5rem';
                                }
                                return styles;
                            },
                            loadingIndicator: (styles) => {
                                styles.color = 'var(--minsk)';
                                return styles;
                            },
                        }}
                        isAsync
                        defaultOptions={tenderOptions}
                        cacheOptions
                        onChange={(tenderListItem) => {
                            onChangeTender(tenderListItem as TenderListItem);
                        }}
                        loadOptions={loadOptionsDebounced}
                        placeholder={t('form-content:ChooseTender')}
                    />

                    <div />
                </div>

                <div
                    className={cx(
                        'mt-10 col-span-2',
                        !form?.value ? 'lg:col-span-2' : 'lg:col-span-1'
                    )}
                >
                    {tender && tender.forms.length > 0 && (
                        <>
                            <label htmlFor="formId">
                                {t('form-content:LabelForm')}
                            </label>
                            <Dropdown
                                id="formId"
                                name="formId"
                                className="mt-4"
                                value={form}
                                isClearable
                                onChange={(tenderForm) => {
                                    onChangeForm(
                                        tenderForm as FormListItem | null
                                    );
                                }}
                                options={tender.forms.map((tenderForm) => ({
                                    ...tenderForm,
                                    value: tenderForm.value,
                                    label: tenderForm.name,
                                }))}
                                placeholder={t('form-content:ChooseForm')}
                            />
                        </>
                    )}
                </div>

                {form?.value && tender && (
                    <>
                        <div className="flex items-center mt-20 lg:ml-4 col-span-2 lg:col-span-1 gap-x-2  whitespace-nowrap">
                            <Button
                                iconSettings={{ Icon: DataFormat16 }}
                                scheme="primary"
                                onClick={() => onGoToForm(formOptions.form)}
                                title={t('OpenForm')}
                                data-test="open-form-button"
                            >
                                {t('OpenForm')}
                            </Button>
                            <Button
                                iconSettings={{ Icon: Edit16 }}
                                scheme="primary"
                                onClick={() => onGoToForm(formOptions.json)}
                                title={t('EditFormJson')}
                                data-test="edit-form-button"
                            >
                                {t('EditFormJson')}
                            </Button>

                            <Button
                                iconSettings={{ Icon: TrashCan16 }}
                                scheme="secondary"
                                onClick={onDeleteForm}
                                title={t('LabelDelete')}
                                data-test="delete-form-button"
                            >
                                {t('LabelDelete')}
                            </Button>
                        </div>
                        <div className="flex flex-wrap mt-5 lg:mt-10 col-span-2 gap-5">
                            <Button
                                iconSettings={{ Icon: CloudDownload32 }}
                                scheme="primary"
                                onClick={() =>
                                    onGoToForm(formOptions.downloadxml)
                                }
                                title={t('DownloadXml')}
                                data-test="downloadxml-form-button"
                            >
                                {t('DownloadXml')}
                            </Button>
                            <Button
                                iconSettings={{ Icon: Checkmark32 }}
                                scheme="primary"
                                onClick={() =>
                                    onGoToForm(formOptions.validationSchematron)
                                }
                                title={t('ValidateFormSchematron')}
                                data-test="validate-form-schematron-button"
                            >
                                {t('ValidateFormSchematron')}
                            </Button>
                            <Button
                                iconSettings={{ Icon: Checkmark32 }}
                                scheme="primary"
                                onClick={() =>
                                    onGoToForm(formOptions.validationQuick)
                                }
                                title={t('ValidateFormQuick')}
                                data-test="validate-form-quick-button"
                            >
                                {t('ValidateFormQuick')}
                            </Button>
                            <Button
                                iconSettings={{ Icon: Checkmark32 }}
                                scheme="primary"
                                onClick={() =>
                                    onGoToForm(formOptions.validationFull)
                                }
                                title={t('ValidateFormFull')}
                                data-test="validate-form-full-button"
                            >
                                {t('ValidateFormFull')}
                            </Button>
                        </div>
                        <div className="flex flex-wrap mt-5 lg:mt-10 col-span-2 gap-5">
                            <Button
                                iconSettings={{ Icon: Catalog32 }}
                                scheme="primary"
                                onClick={() => onAuditLog()}
                                data-test="audit-log-button"
                            >
                                {t('AuditLog')}
                            </Button>
                            <Button
                                iconSettings={{ Icon: Html16 }}
                                scheme="primary"
                                onClick={() => onPreviewHtml()}
                                data-test="preview-html-button"
                            >
                                {t('PreviewHtml')}
                            </Button>
                            <Button
                                iconSettings={{ Icon: Pdf16 }}
                                scheme="primary"
                                onClick={() => onPreviewPdf()}
                                data-test="preview-pdf-button"
                            >
                                {t('PreviewPdf')}
                            </Button>
                            {currentStatus === 'ReadyForPublication' && (
                                <Button
                                    iconSettings={{
                                        Icon: Upload16,
                                    }}
                                    scheme="primary"
                                    onClick={() => onPublishForm()}
                                    title={t('PublishForm')}
                                    data-test="publish-form-button"
                                >
                                    {t('PublishForm')}
                                </Button>
                            )}
                            {currentStatus === 'ReadyForTranslation' && (
                                <Button
                                    iconSettings={{
                                        Icon: Upload16,
                                    }}
                                    scheme="primary"
                                    onClick={() => onTranslateForm()}
                                    title={t('TranslateForm')}
                                    data-test="translate-form-button"
                                >
                                    {t('TranslateForm')}
                                </Button>
                            )}
                            {form?.label?.includes('Change') && (
                                <Button
                                    iconSettings={{ Icon: DataShare32 }}
                                    scheme="primary"
                                    onClick={onCompare}
                                    title={t('Compare')}
                                    data-test="compare-forms-button"
                                >
                                    {t('Compare')}
                                </Button>
                            )}
                        </div>
                        <div className="flex flex-wrap mt-5 lg:mt-8 col-span-2 gap-5">
                            <Button
                                iconSettings={{ Icon: Locked16 }}
                                scheme="tertiary"
                                onClick={() => onLockForm()}
                                title={t('LockForm')}
                            >
                                {t('LockForm')}
                            </Button>
                            <Button
                                iconSettings={{ Icon: Unlocked16 }}
                                scheme="tertiary"
                                onClick={() => onUnlockForm()}
                                title={t('UnlockForm')}
                            >
                                {t('UnlockForm')}
                            </Button>
                        </div>
                        <div className="flex flex-wrap mt-5 lg:mt-8 col-span-2 gap-5">
                            <Button
                                iconSettings={{ Icon: Catalog32 }}
                                scheme="tertiary"
                                onClick={() => onPublicationStatuses()}
                                data-test="publication-statuses-button"
                            >
                                {t('PublicationData')}
                            </Button>
                            <Button
                                iconSettings={{ Icon: TreeView32 }}
                                scheme="tertiary"
                                onClick={() => onDocumentFamily()}
                                data-test="document-family-button"
                            >
                                {t('DocumentFamily')}
                            </Button>
                        </div>
                        {/* <div className="flex flex-wrap mt-5 lg:mt-8 col-span-2 gap-5">
                            <Button
                                iconSettings={{ Icon: Copy16 }}
                                scheme="tertiary"
                                onClick={() => onCopyForm()}
                                title={t('LabelCopy')}
                            >
                                {t('LabelCopy')} (Obsolete)
                            </Button>
                            <Button
                                iconSettings={{ Icon: DataFormat16 }}
                                scheme="tertiary"
                                onClick={() => onGoToForm(formOptions.print)}
                                title={t('PrintForm')}
                                data-test="print-form-button"
                            >
                                {t('PrintForm')} (Obsolete)
                            </Button>
                        </div> */}
                    </>
                )}
            </div>
            {statuses && tender?.value && form?.value && (
                <>
                    <div className="flex w-full mt-4 items-center col-span-2 gap-5 whitespace-nowrap">
                        <div className="w-4/6">
                            <label htmlFor="status">
                                {t(`LabelFormStatus`)}
                            </label>
                            <Dropdown
                                id="status"
                                name="status"
                                className="mt-4"
                                value={status}
                                menuPlacement="top"
                                onChange={(newValue) => {
                                    onChangeStatus(
                                        newValue as SelectOption | null
                                    );
                                }}
                                options={statuses.map(
                                    (option: DropdownOption) => ({
                                        value: option.value,
                                        label: t(
                                            `list.audit-log-status:${option.name}`
                                        ),
                                    })
                                )}
                                placeholder="Select status"
                            />
                        </div>

                        <Button
                            iconSettings={{ Icon: Save32 }}
                            scheme="primary"
                            className="mt-10 w-fit"
                            onClick={() => onSaveFormStatus()}
                            title={t('LabelSaveStatus')}
                            data-test="status-button"
                        >
                            {t('LabelSaveStatus')}
                        </Button>
                    </div>
                    <div className="flex w-full mt-4 items-center col-span-2 gap-5 whitespace-nowrap">
                        <div className="w-4/6">
                            <Button
                                iconSettings={{ Icon: Save32 }}
                                scheme="secondary"
                                className="mt-10 w-fit"
                                onClick={() => onFormUpgrade()}
                                title={t('LabelUpgradeForm')}
                                data-test="form-upgrade-button"
                            >
                                {t('LabelUpgradeForm')}
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default SelectForm;
