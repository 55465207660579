import React, {
    FC,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from 'react';
import { FormSpy, FormSpyProps, FormSpyRenderProps } from 'react-final-form';
import diff from 'object-diff';
import throttle from 'lodash.throttle';
import stringify from 'fast-safe-stringify';

interface AutoSaveInterface {
    onAutoSave: (values: Record<string, any>) => void;
    autoSaveThrottleDelay?: number;
}

export const AutoSave: FC<FormSpyRenderProps['values'] & AutoSaveInterface> =
    memo(
        ({
            onAutoSave,
            values,
            setFieldData,
            autoSaveThrottleDelay = 5000,
        }) => {
            const saveRef = useRef<any>(onAutoSave);
            const valuesRef = useRef<any>(values);
            const saveCallback = useCallback(async () => {
                await saveRef.current(valuesRef.current);
            }, []);
            const throttledSave = useMemo(
                () =>
                    throttle(saveCallback, autoSaveThrottleDelay, {
                        leading: false,
                    }),
                [saveCallback, autoSaveThrottleDelay]
            );

            useEffect(() => {
                const diffObject = diff(valuesRef.current, values);
                if (Object.keys(diffObject)?.length) {
                    throttledSave();
                }
            }, [throttledSave, values, saveCallback]);
            useEffect(() => {
                saveRef.current = onAutoSave;
                valuesRef.current = values;
            }, [setFieldData, onAutoSave, values]);
            return null;
        },
        (prevProps, nextProps) => {
            try {
                return stringify(prevProps) === stringify(nextProps);
            } catch (e) {
                console.warn(`AutoSave: ${e}`);
                return false;
            }
        }
    );

AutoSave.displayName = 'AutoSave';

export default (props: FormSpyProps & AutoSaveInterface) => {
    const { onAutoSave, autoSaveThrottleDelay } = props;
    return (
        <FormSpy
            subscription={{
                values: true,
                dirty: true,
            }}
            render={(renderProps) => (
                <AutoSave
                    values={renderProps.values}
                    onAutoSave={onAutoSave}
                    autoSaveThrottleDelay={autoSaveThrottleDelay}
                />
            )}
        />
    );
};
