import { Dispatch, SetStateAction, useEffect } from 'react';
import { ValidationErrorObject } from '../../types/validationError';
import { TFunction } from 'react-i18next';
import { addErrorObjectWithPathsRecursive } from './addErrorObjectWithPathsRecursive';
import { searchForRenderedFields } from './searchForRenderedFields';
import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';
import { KeyedMutator } from 'swr';
import { FormMenuResult } from '../../types/generated/formMenuResult';

export interface ValidateResponse {
    fieldErrors: Record<string, unknown>;
    formErrors: Record<string, unknown>;
}

const fetcher = ({
    url,
    setHotReloadOfMenu,
    setRefetchStatus,
}: {
    url: string;
    setHotReloadOfMenu: KeyedMutator<FormMenuResult>;
    setRefetchStatus: React.Dispatch<React.SetStateAction<boolean>>;
}) =>
    axios
        .get(url, { headers: { SkipToast: true } })
        .then(
            async (
                res: AxiosResponse<ValidateResponse>
            ): Promise<ValidateResponse> => {
                const { data } = res;
                setHotReloadOfMenu();
                setRefetchStatus((refetchStatus: boolean) => !refetchStatus);
                return data;
            }
        );

export const useValidate = (
    setBackendValidationErrors: Dispatch<SetStateAction<ValidationErrorObject>>,
    formId: string,
    parentSectionId: string,
    sectionId: string,
    t: TFunction<string, unknown>,
    shouldFetchValidation: boolean,
    setHotReloadOfMenu: KeyedMutator<FormMenuResult>,
    setRefetchStatus: React.Dispatch<React.SetStateAction<boolean>>,
    useRenderSectionErrorsSubArrayLevel?: boolean,
    notUseHardcodedSectionsSprint5?: boolean
) => {
    const {
        data: submittedErrors,
        error,
        isValidating,
        mutate: validate,
    } = useSWRImmutable(
        shouldFetchValidation
            ? {
                  url: `rep/form/validate-frontend/${formId}`,
                  setHotReloadOfMenu,
                  setRefetchStatus,
              }
            : null,
        shouldFetchValidation ? fetcher : null,
        {
            shouldRetryOnError: true,
        }
    );

    useEffect(() => {
        if (submittedErrors && !submittedErrors.fieldErrors) {
            setBackendValidationErrors({
                submittedErrors: {},
                errorObjectWithPaths: {},
                renderedFieldsWithErrors: {},
                isFormValidatedWithoutErrors: true,
            });
        }
        if (submittedErrors && submittedErrors.fieldErrors) {
            const mainSectionKeys = Object.keys(submittedErrors?.fieldErrors);
            const errorObjectWithPaths: ValidationErrorObject['errorObjectWithPaths'] =
                mainSectionKeys.reduce(
                    (restObject, key) => ({ ...restObject, [key]: [] }),
                    {}
                );
            addErrorObjectWithPathsRecursive(
                useRenderSectionErrorsSubArrayLevel,
                notUseHardcodedSectionsSprint5,
                submittedErrors.fieldErrors,
                errorObjectWithPaths,
                mainSectionKeys,
                t,
                formId
            );
            const renderedFieldsWithErrors = searchForRenderedFields(
                errorObjectWithPaths[parentSectionId || sectionId]?.map(
                    (errorObjects) => errorObjects.fieldPath
                )
            );
            setBackendValidationErrors({
                submittedErrors: submittedErrors.fieldErrors,
                renderedFieldsWithErrors,
                errorObjectWithPaths,
                isFormValidatedWithoutErrors: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submittedErrors]);

    return {
        data: submittedErrors,
        isErrorFetchingValidations: error,
        isLoadingFetchingValidations: isValidating,
        validate,
    };
};
