import Button from '@mercell/button-react';
import {
    Modal,
    ModalTitle,
    ModalButtonsSection,
    ModalContent,
} from '@mercell/modal-react';
import React, { FC } from 'react';

interface ConfirmationModalProps {
    children: React.ReactNode;
    confirmText?: string;
    cancelText?: string;
    title?: string;
    text?: string;
    isModalVisible: boolean;
    closeModal: () => void;
    onConfirm?: () => void;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
    confirmText,
    cancelText,
    title,
    text,
    isModalVisible,
    closeModal,
    children,
    onConfirm,
}) => (
    <Modal
        isModalVisible={isModalVisible}
        onCloseCallback={closeModal}
        style={{ overflow: 'visible' }}
    >
        <ModalTitle>{title}</ModalTitle>
        <ModalContent>
            <div>{children}</div>
            <p className="text-center">{text}</p>
        </ModalContent>
        <ModalButtonsSection className="flex justify-end">
            {cancelText && (
                <Button
                    scheme="secondary"
                    className="mr-2 min-w-20"
                    style={{ minWidth: 100 }}
                    onClick={closeModal}
                >
                    {cancelText}
                </Button>
            )}
            {confirmText && (
                <Button
                    scheme="primary"
                    style={{ minWidth: 100 }}
                    onClick={onConfirm}
                >
                    {confirmText}
                </Button>
            )}
        </ModalButtonsSection>
    </Modal>
);

export default ConfirmationModal;
