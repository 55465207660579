import { ValidationErrors } from 'final-form';

export function removeDuplicateErrors(
    errors: Record<string, unknown>,
    validationErrors: ValidationErrors
): Record<string, unknown> {
    const submitErrors: Record<string, unknown> = {};
    const regexPattern = /\/(.*)\//;

    for (const key in errors) {
        // eslint-disable-next-line no-prototype-builtins
        if (validationErrors?.hasOwnProperty(key)) {
            const errorsValue: any = errors[key];
            let validationErrorsValue = validationErrors[key];

            const regexMatch = regexPattern.test(validationErrorsValue);
            validationErrorsValue = regexMatch
                ? removeRegexSlashes(validationErrorsValue)
                : validationErrorsValue;

            if (
                Array.isArray(errorsValue) &&
                errorsValue.includes(validationErrorsValue)
            ) {
                const filteredArray = errorsValue.filter(
                    (value: any) => value !== validationErrorsValue
                );

                if (filteredArray.length === 1) {
                    // eslint-disable-next-line prefer-destructuring
                    submitErrors[key] = filteredArray[0];
                } else {
                    submitErrors[key] = filteredArray;
                }
            } else if (
                typeof errorsValue === 'object' &&
                typeof validationErrorsValue === 'object'
            ) {
                submitErrors[key] = removeDuplicateErrors(
                    errorsValue,
                    validationErrorsValue
                ); // Recursive call for nested objects
            } else {
                submitErrors[key] = errorsValue;
            }
        } else {
            submitErrors[key] = errors[key];
        }
    }

    return submitErrors;
}

function removeRegexSlashes(str: string) {
    return str.replace(/\/(.*)\//, '$1');
}
