import React from 'react';
import { AxiosError } from 'axios';
import {
    removeAdminToken,
    removeOktaTokens,
} from '../shared/storageService/cookieService';
import OktaAuth from '@okta/okta-auth-js';
import { TFunction } from 'react-i18next';
import { toast } from 'react-toastify';
import { Warning32 } from '@carbon/icons-react';
import { i18n } from 'i18next';

const findTranslationKey = (
    key: string,
    i18nObject: i18n,
    t: TFunction<string, unknown>
) => {
    const namespaces = i18nObject.options.ns as string[];

    let translationNameSpace;
    if (namespaces && namespaces?.length > 0) {
        const foundKey = namespaces.some((namespace) => {
            const translation = t(`${namespace}:${key}`);

            if (translation !== key) {
                translationNameSpace = namespace;
                return true;
            }
            return false;
        });
        if (foundKey) {
            return t(`${translationNameSpace}:${key}`);
        }
    }
};
export const errorHandler = (
    error: AxiosError,
    oktaAuth: OktaAuth,
    restoreOriginalUri: (
        _oktaAuth: OktaAuth,
        originalUri: string
    ) => Promise<void>,
    t: TFunction<string, unknown>,
    i18nObject: i18n,
    useToasts1734?: boolean
) => {
    const oktaRoutesCondition =
        error.config.url?.includes('testapp/names/') ||
        error.config.url?.includes('testapp/names-dynamic/') ||
        error.config.url?.includes('testapp/selector/') ||
        error.config.url?.includes('form/selector/') ||
        error.config.url?.includes('rep/form/') ||
        error.config.url?.includes('configurator/');

    const adminRoutesCondition =
        (error.config.url?.includes('rep/form/') &&
            error.config.method === 'delete') ||
        (error.config.url?.includes('rep/form/selector/') &&
            error.config.method === 'get') ||
        error.config.url?.includes('rep/form/url/') ||
        error.config.url?.includes('rep/form/create/') ||
        error.config.url?.includes('rep/form/copy/');

    if (error.response?.status === 401 || error.response?.status === 403) {
        if (oktaRoutesCondition) {
            removeOktaTokens();
            restoreOriginalUri(oktaAuth, window.location.pathname); // this method redirects to okta login if token is absent or expired
        }
        if (adminRoutesCondition) {
            removeAdminToken();
        }
    } else if (!error.config.headers.SkipToast && useToasts1734) {
        const { response } = error;

        let errorMessage;
        let errorMessageWithoutErrorId;
        if (response?.status && response?.status > 500) {
            errorMessage = t('toast-content:ToastInternalServerError');
        } else if (response?.data.message) {
            const translation = findTranslationKey(
                response.data.message,
                i18nObject,
                t
            );

            errorMessageWithoutErrorId = translation ? t(translation) : '';
            errorMessage =
                `${translation ? t(translation) : ''} \n` +
                `${response.data.errorId ? response.data.errorId : ''}`;
        } else if (typeof response?.data === 'string' && response.data !== '') {
            errorMessage = t(response.data);
        } else if (response?.data.title) {
            errorMessage = t(response.data.title);
        } else if (response?.data.detail) {
            errorMessage = t(response.data.detail);
        } else {
            errorMessage = t('toast-content:ToastUnexpectedError');
        }

        if (toast.isActive(error.config.url ?? '')) {
            toast.update(error.config.url ?? '', {
                render: errorMessage,
                type: 'error',
                isLoading: false,
                delay: 1000,
                autoClose: 3000,
                icon: <Warning32 />,
            });
        } else {
            toast.error(errorMessage.toString(), {
                toastId: errorMessageWithoutErrorId ?? errorMessage,
            });
        }
    }
    return error;
};
